import { FilterProps } from "models/filter";
import { PaginationApiProps } from "models/pager";
import { RolesDataResponse } from "models/roles";
import { CurrentUserProps } from "models/user";
import { ActiveUsersData, ActiveUsersDataResponse } from "pages/admin/active-users-list/activeUsersModel";
import { DeleteActiveUserFormProps } from "pages/admin/active-users-list/delete-active-users/deleteActiveUserModel";
import { AddUserData } from "pages/admin/add-users/addUserModel";
import { PendingUsersDataResponse } from "pages/admin/pending-users-list/pendingUsersModel";
import { axiosInstance } from "utilis/axiosConfig"
import { mapFilters, mapPaginationData } from "utilis/helpers";


export const getCurrentUser = async (): Promise<CurrentUserProps | undefined> => {
    try {
        const response = await axiosInstance.get<CurrentUserProps>('/v1/user/me');
        return response.data;
    } catch(error) {
        throw error;
    }
}

export const getUser = async (id: string): Promise<CurrentUserProps | undefined> => {
    try {
        const response = await axiosInstance.get<CurrentUserProps>(`/v1/user/${id}`);
        return response.data;
    } catch(error) {
        throw error;
    }
}

export const getUserRoles = async (): Promise<RolesDataResponse> => {
    try {
        const response = await axiosInstance.get<RolesDataResponse>('/v1/role');
        return response.data;
    } catch(error) {
        throw error;
    }
}


export const addUser = async (data: AddUserData): Promise<void | undefined> => {
    try {
        const response = await axiosInstance.post<void>('/v1/invite/user', data);
        return response.data;
    } catch(error) {
        throw error
    }
}

export const getActiveUsers = async (filter: FilterProps | undefined, paginationData: PaginationApiProps): Promise<ActiveUsersDataResponse> => {
    const filters = mapFilters(filter);
    const paginationParams = mapPaginationData(paginationData);
    const params = {
        ...filters,
        ...paginationParams
    }
    try {
        const response = await axiosInstance.get<ActiveUsersDataResponse>(`/v1/user?`, {params});
        return response.data;
    } catch(error) {
        throw (error);
    }
}


export const getPendingUsers = async (filter: FilterProps | undefined, paginationData: PaginationApiProps): Promise<PendingUsersDataResponse> => {
    const filters = mapFilters(filter);
    const paginationParams = mapPaginationData(paginationData);
    const params = {
        ...filters,
        ...paginationParams
    }
    try {
        const response = await axiosInstance.get<PendingUsersDataResponse>('/v1/invite', {params});
        return response.data;
    } catch(error) {
        throw (error);
    }
}

export const deletePendingUser = async (email: string): Promise<void | undefined> => {
    try {
        const response = await axiosInstance.delete<void>('/v1/invite/user/cancel', {data: {email}});
        return response.data;
    } catch(error) {
        throw (error);
    }
}

export const deleteActiveUser = async ({id, assigneeId}: DeleteActiveUserFormProps): Promise<void | undefined> => {
    try {
        const response = await axiosInstance.delete<void>(`/v1/user/delete/${id}`, {data: {assigneeId}});
        return response.data;
    } catch(error) {
        throw (error);
    }
}

export const blockUser = async (id: string): Promise<ActiveUsersData | undefined> => {
    try {
        const response = await axiosInstance.patch<ActiveUsersData>(`/v1/user/block/${id}`);
        return response.data;
    } catch(error) {
        throw (error);
    }
}

export const unblockUser = async (id: string): Promise<ActiveUsersData | undefined> => {
    try {
        const response = await axiosInstance.patch<ActiveUsersData>(`/v1/user/unblock/${id}`);
        return response.data;
    } catch(error) {
        throw (error);
    }
}