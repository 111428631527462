import CloseIcon from "assets/icons/close";
import { Button } from "components/UI/buttons";
import { HrTextInput } from "components/forms/textInput";
import { Form, Formik, FormikHelpers } from "formik";
import { AddUserData } from "./addUserModel";
import { Dispatch, FC, SetStateAction } from "react";
import * as Yup from "yup";
import { HrSelect } from "components/forms/select";
import { useRoles } from "hooks/users/useRoles";
import { useAddUser } from "./useAddUser";
import { Modal } from "components/shared/modal";
import { UserIcon } from "@heroicons/react/24/outline";
import { Spinner } from "components/shared/spinner";

export const AddUsers: FC<{ open: boolean; setIsOpen: Dispatch<SetStateAction<boolean>> }> = ({ open, setIsOpen }) => {
	const { data } = useRoles({select: (data) => data?.rows.filter(role => role.name !== 'ADMIN')!, enabled: open});
	const { mutate: addUser } = useAddUser();
	const initialValues: AddUserData = { email: "", roleId: "" };

	const onSubmit = (values: AddUserData, {setSubmitting}: FormikHelpers<AddUserData>) => {
		addUser(values, { onSuccess: () => handleClose(), onError: () => setSubmitting(false)});
	};

	const formValidation = Yup.object({
		email: Yup.string().email("Invalid email address").required("Email can not be empty").matches(/@cognism\.com$/, 'Only @cognism.com domain is allowed'),
		roleId: Yup.string().required("Role can not be empty"), 
	});

	const handleClose = () => {
		setIsOpen(false);
	};

	return (
		<>
			{data && (
				<Modal open={open} setIsOpen={setIsOpen}>
					<div className="flex justify-between items-center mb-3">
						<div className="flex items-center gap-3">
							<div className="rounded-full p-3 bg-primary-100">
								<UserIcon className="h-4 w-4 text-primary-500"> </UserIcon>
							</div>
							<h2 className="text-xl font-medium"> Invite new Team member </h2>
						</div>

						<button onClick={handleClose} className="h-full">
							<CloseIcon />
						</button>
					</div>
					<Formik initialValues={initialValues} validationSchema={formValidation} onSubmit={onSubmit}>
					      {({isSubmitting}) => (
							<Form>
								<div>
									<div className="mb-4">
										<HrTextInput label="Email" autoComplete="email" name="email" type="email" placeholder="Enter email" />
									</div>
									<div className="mb-4">
										<HrSelect placeholder="Role" label="Role" name="roleId" options={data} />
									</div>
									<p className="text-xs text-gray-300 mb-4">
										We will email them instructions and a magic link to Sign in.
									</p>
								</div>
								<div className="flex gap-4 flex-wrap">
									<Button type="button" variant="secondary" onClick={handleClose} className="flex-1">
										Go back to User list
									</Button>
									<Button type="submit" variant="primary" className="flex-1">
										{isSubmitting ? <Spinner className="h-5 w-5 fill-gray-100" /> : 'Send Invite'}
									</Button>
								</div>
							</Form>
						  )}
					</Formik>
				</Modal>
			)}
		</>
	);
};
