function HidePasswordIcon() {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width="20" height="21" viewBox="0 0 20 21" fill="none">
			<path
				d="M11.5624 16.5621C11.0472 16.6595 10.5241 16.7084 9.99986 16.708C6.2682 16.708 3.10986 14.2555 2.04736 10.8746C2.33342 9.96472 2.77402 9.11085 3.34986 8.35047M8.23153 9.10713C8.70041 8.63825 9.33635 8.37484 9.99945 8.37484C10.6625 8.37484 11.2985 8.63825 11.7674 9.10713C12.2362 9.57601 12.4997 10.212 12.4997 10.8751C12.4997 11.5381 12.2362 12.1741 11.7674 12.643M8.23153 9.10713L11.7674 12.643M8.23153 9.10713L11.7665 12.6413M11.7674 12.643L14.5099 15.3846M8.2332 9.10797L5.49153 6.3663M5.49153 6.3663L2.49986 3.37463M5.49153 6.3663C6.83516 5.49927 8.40078 5.03914 9.99986 5.0413C13.7315 5.0413 16.8899 7.4938 17.9524 10.8746C17.3657 12.7333 16.1477 14.3284 14.509 15.3838M5.49153 6.3663L14.509 15.3838M14.509 15.3838L17.4999 18.3746"
				stroke="#16172C"
				strokeWidth="2"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
}

export default HidePasswordIcon;
