import { Button } from "components/UI/buttons";
import { HrTextInput } from "components/forms/textInput";
import { Form, Formik } from "formik";
import { useState } from "react";
import { LoginData } from "../login/loginModel";
import * as Yup from "yup";
import { requestPasswordReset } from "api/auth";
import useToastStore from "components/shared/toast/toastStore";
import { useNavigate } from "react-router-dom";

export const ForgotPassword = () => {
	const { showToast } = useToastStore();
	const navigate = useNavigate();
	const initialValues: Omit<LoginData, "password"> = { email: "" };
	const [isEmailSent, setEmailSent] = useState(false);
	const onSubmit = (values: Omit<LoginData, "password">) => {
		requestPasswordReset(values)
			.then(() => setEmailSent(true))
			.catch((error) => {
				showToast("Email does not exist", { type: "error", position: "top" });
			});
	};

	const formValidation = Yup.object({
		email: Yup.string().email("Invalid email address").required("Email can not be empty"),
	});

	const handleNavigate = () => {
		navigate('/login');
	};

	const handleEmailApp = () => {
		window.open('https://outlook.live.com/mail')
	};

	return (
		<>
			<div className="flex justify-between mb-4">
				{!isEmailSent && (
					<div>
						<h2 className="text-2xl font-medium"> Forgot password? </h2>
						<p className="text-base">
							No worries, we will send you reset instructions.Enter the email associated with your account.
						</p>
					</div>
				)}
			</div>
			<Formik initialValues={initialValues} validationSchema={formValidation} onSubmit={onSubmit}>
				{({ isSubmitting, values }) => (
					<Form>
						<div className="mb-10">
							{!isEmailSent ? (
								<div>
									<HrTextInput label="Email" name="email" autoComplete="email" type="email" placeholder="Enter email" />
								</div>
							) : (
								<div>
									<h2 className="text-2xl font-medium"> Check your email </h2>
									<p className="text-sm font-medium mb-5 text-black">
										We have sent an email to <span className="text-primary">{values.email} </span> Please check your
										inbox or spam and follow the instructions we have sent.
									</p>
								</div>
							)}
						</div>
						<div className="flex gap-4">
							<Button
								type="button"
								variant={!isEmailSent ? "secondary" : "primary"}
								onClick={handleNavigate}
								className="grow-2"
							>
								{!isEmailSent ? "Go back" : "Go back to login"}
							</Button>
							{!isEmailSent && (
								<Button type="submit" variant="primary" className="grow-2">
									Send email
								</Button>)}
							{isEmailSent && (
								<Button type="button" variant="secondary" onClick={handleEmailApp} className="grow-2">
									Open email app
								</Button>
							)}
						</div>
					</Form>
				)}
			</Formik>
			{isEmailSent && <p className="text-sm font-medium text-black absolute bottom-6 max-w-lg lg:max-w-sm xl:max-w-md 2xl:max-w-lg">
			       Did not receive the email? Check you spam filter or try another email adress.
				</p>}
		</>
	);
};
