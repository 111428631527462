import { useParams } from "react-router-dom";
import { useAccountDetails } from "./useAccountDetails";
import { AccountGeneralInfo } from "./accountGeneralInfo";
import { Breadscrumb } from "components/shared/breadscrumb";
import { OpportunitiesList } from "./opportunities/opportunitiesList";
import { useState } from "react";
import { OpportunityAdd } from "./opportunities/opportunity-add/opportunityAdd";
import { checkIsAdmin } from "utilis/helpers";
import { useAccountStatuses } from "./useAccountStatuses";

export const AccountDetails = () => {
	const [open, setOpen] = useState<boolean>(false);
	const params = useParams();
	const isAdmin = checkIsAdmin()
	const { data: accountStatuses } = useAccountStatuses(params.id!);
	const { data: account } = useAccountDetails(params.id!);


	return (
		<>
			{account && accountStatuses && (
				<div>
                    <Breadscrumb className="mb-5" showBackButton={true} />
					<AccountGeneralInfo account={account!} accountStatuses={accountStatuses} />
                    <div className="bg-white p-4 mt-6"> 
					<div className="border-b border-gray-100">
					<Breadscrumb title='Opportunities' className="pb-2" action={!isAdmin ? setOpen : undefined} buttonTitle="Add new opportunity" />
					</div>
					<OpportunitiesList account={account!} /> 
					</div>
			        {open && <OpportunityAdd account={account} open={open} setIsOpen={setOpen} />}
				</div>
			)}
		</>
	);
};
