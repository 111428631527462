import {FC, useEffect, useState} from "react";
import {OpportunityFieldRegistry} from "../../models/opportunities/opportunityFieldRegistry";
import {OpportunityFieldsSection} from "./opportunityFieldsSection";
import isEqual from 'lodash/isEqual';
import { useIsFirstRender } from "usehooks-ts";
interface OpportunityFieldsProps {
  schema?: any;
  allSchemas?: any;
  value?: any;
  readonly?: boolean;
  onChange?: (value: any) => void;
}

export const OpportunityFields: FC<OpportunityFieldsProps> = ({schema, allSchemas, readonly, value, onChange}) => {
  const [registry, setRegistry] = useState<OpportunityFieldRegistry>();
  const [fields, setFields] = useState(value);  
  const [sectionsOpened, setSectionsOpened] = useState<any[]>([]);
  const isFirstRender = useIsFirstRender();
  useEffect(() => {
    const registry = OpportunityFieldRegistry.create(schema);
    
    if(isFirstRender){
      const registryForSectionsInfo = OpportunityFieldRegistry.create(allSchemas);
      const sectionsOpenedL = registryForSectionsInfo.sections.map(section => ({sectionName: section, opened: false, isSaveDisabled: true}));
      setSectionsOpened(sectionsOpenedL);
    }
    setRegistry(registry);
  }, [schema, sectionsOpened, isFirstRender, allSchemas]);
  
  const enableSaveButton = (definitionNames: any[], newFields: any, section:string) => {
    const oldValues = value.fields?.filter((item: any) => definitionNames.some((def: any) => item.name === def)) || []
    const newValues = newFields.filter((item: any) => definitionNames.some((def: any) => item.name === def) )

    if(!isEqual(oldValues, newValues)){
      const newSectionsOpened = sectionsOpened.map((item: any) => item.sectionName === section ? {...item, isSaveDisabled: false} : item )
      setSectionsOpened(newSectionsOpened);
    }
  }

  const disableSaveButton = (section: string) => {
    const newSectionsOpened = sectionsOpened.map((item: any) => item.sectionName === section ? {...item, isSaveDisabled: true} : item)
    setSectionsOpened(newSectionsOpened);
  }

  const handleChange = (name: string, val: any, section: string, isValid: boolean) => {
    const internalValue = (fields || {});
    internalValue.fields = internalValue.fields || [];
    const existing = internalValue.fields.find((field: any) => field.name === name);
    const newFields = (
      existing
        ? internalValue.fields.map((f: any) => f.name === name ? {name, value: val} : f)
        : internalValue.fields.concat([{name, value: val}])
    )
      
    setFields({fields: newFields});
    isValid ? enableSaveButton([name], newFields, section) : disableSaveButton(section);
  };  

  const handleSaveChanges = (section: string) => {
    disableSaveButton(section);
    const sectionFields = schema.filter((item: any) => item.section === section).map((item: any) => item.name);    

    const editedFields = fields.fields.filter((item: any) => sectionFields.includes(item.name));
    // const restFieldsfromOldState = value.fields?.filter((item: any) => !sectionFields.includes(item.name)) || [];

    const newFields = {fields: [...editedFields]};
    onChange && onChange(newFields);
  }

  const handleCancel = (definitionNames: any[], section: string) => {
      const filteredFields = fields?.fields.filter((item: any) => !definitionNames.some((def: any) => item.name === def));
      const edited = fields?.fields.filter((item: any) => definitionNames.some((def: any) => item.name === def));

      if(edited.length){
        const editedToOldValue = value.fields.filter((item:any) => edited.some((it: any) => item.name === it.name))
        setFields({ fields: [...filteredFields, ...editedToOldValue] });
        disableSaveButton(section);
        return;
      } else {
        const removed = value?.fields.filter((item: any) => definitionNames.some((def: any) => item.name === def));
        const editedToOldValue = value.fields.filter((item:any) => removed.some((it: any) => item.name === it.name))
        
        setFields({ fields: [...filteredFields, ...editedToOldValue] });
        disableSaveButton(section);
      }
  }

  return <div className="bg-white w-full overflow-x-hidden">
    {
       (registry?.sections || []).map((section: string) => {
        const sectionDefinitions = (registry?.definitions || [])
          .filter((definition) => definition.section === section);
        // console.log(sectionDefinitions, section);
        
        return <OpportunityFieldsSection 
          sectionsOpened={sectionsOpened} 
          setSectionsOpened={setSectionsOpened} 
          key={section} 
          section={section}
          onCancel={() => handleCancel(sectionDefinitions.map((definition: any) => definition.name), section)}
          onSave={() => handleSaveChanges(section)}
          readonly={readonly}
        >
          <div className="flex disableSelection flex-col gap-4 py-4 px-6">
            {
              sectionDefinitions.map((definition) => {
                const Component = registry?.resolveComponent(definition)!;
                const existing = (fields?.fields || []).find((field: any) => field.name === definition.name);  
                return (
                  <div key={definition.name}>
                    <Component value={existing?.value} readonly={readonly} validation={definition?.validation} definition={definition} onChange={(name, val, isValid) => handleChange(name, val, section, Boolean(isValid))}/>  
                </div>
                )
              })
            }
          </div>
        </OpportunityFieldsSection>
      })
    }

  </div>
}
