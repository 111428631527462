import { CheckCircleIcon, ExclamationCircleIcon, XMarkIcon, CheckIcon } from "@heroicons/react/24/solid";
import { Fragment, useEffect } from "react";
import useToastStore from "./toastStore";
import { Transition } from "@headlessui/react";

export const Toast = () => {
	const { message, options, isVisible, hideToast, reset } = useToastStore();
	const positions = {
		"top-left": "top-0 left-0 max-w-sm",
		"top-right": "top-0 right-0 max-w-sm",
		"top": "top-0 w-full"
	};

	const bgColors = {
		success: "bg-green-400",
		error: "bg-red-100",
		info: "bg-red-100",
		warning: "bg-orange-100",
		default: "bg-white"
	};

	const textColors = {
		success: "text-green-600",
		error: "text-red-500",
		info: "text-red-100",
		warning: "text-orange-600",
		default: "text-black"
	};

	const position = options && options.position ? positions[options.position] : positions["top"];
	const textColor = options && options.type ? textColors[options.type] : textColors["default"];
	const bgColor = options && options.type ? bgColors[options.type] : bgColors["default"];

	const icons = {
		success: <CheckCircleIcon className={`h-8 w-8 ${textColor}`} />,
		error: <ExclamationCircleIcon className={`h-8 w-8 ${textColor}`} />,
		info: "text-red-100",
		warning: <ExclamationCircleIcon className={`h-8 w-8 ${textColor}`} />,
		default: <CheckCircleIcon className={`h-7 w-7 ${textColor}`} />,
	};

	const icon = options.type ? icons[options.type] : icons["success"];

	useEffect(() => {
		if (isVisible) {
			const timer = setTimeout(() => {
				hideToast();
				reset();
			}, 2000);

			return () => clearTimeout(timer);
		}
	}, [isVisible, hideToast, reset]);
	return (
		<>
			{isVisible && options.type !== "default" ? (
				<div className={`p-4 fixed flex justify-between items-center z-[60] ${position} ${bgColor}`}>
					<div className="flex items-center">
						<div className="flex-shrink-0">{icon}</div>
						<div className="ml-3">
							<h3 className={`text-sm font-medium ${textColor}`}>{message}</h3>
						</div>
					</div>
					<button
						type="button"
						className={`inline-flex pl-4 cursor-pointer ${textColor}`}
						onClick={() => {
							hideToast();
							setTimeout(() => reset(), 100)
						}}
					>
						<span className="sr-only">Close</span>
						<XMarkIcon className="h-7 w-7" aria-hidden="true" />
					</button>
				</div>
			) : (
				<div
					aria-live="assertive"
					className="pointer-events-none fixed inset-0 flex items-end px-4 py-6 sm:items-start sm:p-6 z-40"
				>
					<div className="flex w-full flex-col items-center space-y-4 sm:items-end">
						{/* Notification panel, dynamically insert this into the live region when it needs to be displayed */}
						<Transition
							show={isVisible}
							as={Fragment}
							enter="transform ease-out duration-300 transition"
							enterFrom="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
							enterTo="translate-y-0 opacity-100 sm:translate-x-0"
							leave="transition ease-in duration-100"
							leaveFrom="opacity-100"
							leaveTo="opacity-0"
						>
							<div className="pointer-events-auto w-full max-w-sm overflow-hidden rounded-lg bg-white shadow-lg ring-1 ring-black ring-opacity-5">
								<div className="p-4">
									<div className="flex justify-center items-center flex-col text-center">
										<div className="flex-shrink-0 mb-4 p-3 rounded-full bg-green-400">
											<CheckIcon className="h-4 w-4 text-green-600" aria-hidden="true" />
										</div>
										<div className="ml-3 flex-1 pt-0.5">
											<p className="text-sm font-medium text-gray-900 mb-4">{message}</p>
											<p className="mt-1 text-sm text-gray-500">{ options.description }</p>
										</div>
									</div>
								</div>
							</div>
						</Transition>
					</div>
				</div>
			)}
		</>
	);
};
