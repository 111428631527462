import { XCircleIcon } from "@heroicons/react/24/outline";
import { usePendingUsers } from "./usePendingUsers";
import { PendingUsersListItem } from "./pending-users-list-item/pendingUsersListItem";
import { useDeletePendingUser } from "./useDeletePendingUser";
import { useAddUser } from "../add-users/useAddUser";
import { Spinner } from "components/shared/spinner";
import { ErrorState } from "components/shared/errorState";
import { PendingUsersData } from "./pendingUsersModel";
import useConfirmModalStore from "components/shared/confirm-modal/confirmModalStore";
import { EmptyFilters } from "components/shared/emptyFilters";
import { useState } from "react";
import { PendingUsersFilter } from "./pending-users-filtering/pendingUsersFilter";
import { Pager } from "components/shared/pager";
import classNames from "classnames";
import { useSearchParams } from "react-router-dom";
import { EmptyState } from "components/shared/emptyState";

export const PendingUsersList = () => {
	const [searchParams, setSearchParams] = useSearchParams();
	//create custom hook for filter params;
	const allParams = Object.fromEntries(searchParams.entries());
	const { userStatus, ...params } = allParams;
	const hasFilters = params && Object.keys(params).length > 0;
	const [currentPage, setCurrentPage] = useState(1);
	const { isLoading, error, data: users, refetch } = usePendingUsers(params, { limit: 10, currentPage });
	const { mutate: cancelInvite } = useDeletePendingUser();
	const { mutate: resendInvite } = useAddUser({ isInvite: true });
	const { showModal } = useConfirmModalStore();

	const handlePage = (p: any) => {
		setCurrentPage(p);
	};

	const handleRetry = () => {
		setSearchParams({ userStatus: "pending" });
		setCurrentPage(1);
		refetch();
	};

	const handleMenuClick = (actionType: string, user: PendingUsersData) => {
		switch (actionType) {
			case "cancelInvite":
				showModal(
					"Cancel Invite",
					() =>
						cancelInvite(user.inviteeEmail, {
							onSuccess: () => {
								if (users?.rows.length === 1 && currentPage !== 1) {
									setCurrentPage((previousValue) => previousValue - 1);
								}
							},
						}),
					{
						icon: (
							<div className="p-3 bg-red-100 rounded-full">
								<XCircleIcon className="h-6 w-6 text-red-500" />{" "}
							</div>
						),
						description: (
							<div>
								{" "}
								<div>Are you sure you want to cancel invite for this user? </div> <span>Email:</span>{" "}
								<strong>{user.inviteeEmail} </strong>{" "}
							</div>
						),
						confirmText: "Cancel invite",
						cancelText: "Close",
						confirmBtnColor: "warning",
					}
				);
				break;
			case "resendInvite":
				showModal("Resend Invite", () => resendInvite({ email: user.inviteeEmail, roleId: user.roleId }), {
					description: "Are you Sure?",
					confirmText: "Resend invite",
				});
				break;
		}
	};

	if (isLoading)
		return (
			<div>
				<Spinner />
			</div>
		);

	if (error)
		return (
			<div>
				if (error) return{" "}
				<div>
					{" "}
					<ErrorState action={() => handleRetry()} />{" "}
				</div>
				;
			</div>
		);

	if (users && users.rows.length < 1 && !hasFilters)
		return (
			<div>
				{" "}
				<EmptyState title="users" />
			</div>
		);

	return (
		<>
			<div className="mb-4">
				<PendingUsersFilter
					results={users?.count!}
					filters={searchParams}
					setFilters={setSearchParams}
					setCurrentPage={setCurrentPage}
				/>
			</div>
			{users && users.rows.length < 1 && hasFilters ? (
				<div className="py-24">
					<EmptyFilters
						action={() => {
							setSearchParams({ userStatus: "pending" });
							setCurrentPage(1);
						}}
					/>
				</div>
			) : (
				<div>
					{users?.rows.map((user, index) => (
						<div key={user.id} className={classNames("pl-5", { "bg-gray-50": index % 2 === 0 })}>
							<PendingUsersListItem key={user.id} user={user} handleMenuClick={handleMenuClick} />
						</div>
					))}
					<div>
						{users && users.count > 10 && (
							<Pager current={currentPage} pageSize={10} total={users?.count} onChange={(p) => handlePage(p)} />
						)}
					</div>
				</div>
			)}
		</>
	);
};
