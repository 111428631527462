import { useQuery } from "@tanstack/react-query";
import { getOpportunities } from "api/opportunity";
import { FilterProps } from "models/filter";
import { PaginationApiProps } from "models/pager";


export const useOpportunity = (filters: FilterProps = {},  paginationData: PaginationApiProps = {limit: 10, currentPage: 1}) => {

	return useQuery({
        queryKey: ['opportunity', filters, paginationData],
		queryFn: () =>  getOpportunities(filters, paginationData),
		keepPreviousData: true
	});
};