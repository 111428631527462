import "./App.css";
import {QueryClient, QueryClientProvider} from "@tanstack/react-query";
import {RouterProvider, createBrowserRouter, redirect} from "react-router-dom";
import Login from "pages/login/login";
import Register from "pages/register/register";
import Auth from "pages/auth/auth";
import ResetPassword from "pages/reset-password/resetPassword";
import {ExpiredRegisterLink} from "pages/expired-register-link/expiredRegisterLink";
import {Homepage} from "pages/home/home";
import {AdminOverview} from "pages/admin/admin";
import {Toast} from "components/shared/toast/toast";
import {getCurrentUser} from "api/user";
import {useUserStore} from "store/userStore";
import {getLocalStorage, setLocalStorage} from "utilis/storage";
import {ConfirmModal} from "components/shared/confirm-modal/confirmModal";
import {AndrejOverview} from "./pages/andrej/andrejOverview";
import { FailedAzureLogin } from "pages/failed-azure-login/failedAzureLogin";
import { getRefreshToken } from "api/auth";
import { ForgotPassword } from "pages/forgot-password/forgotPassword";
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import AccountsOverview from "pages/accounts-overview/accountsOverview";
import { AccountDetails } from "pages/account-details/accountDetails";
import OpportunityDetails from "pages/account-details/opportunities/opportunity-details/opportunityDetails";
import { NotFoundPage } from "components/shared/notFoundPage";
import Opportunities from "pages/opportunities/opportunities";

const queryClient = new QueryClient({
	defaultOptions: {
		queries: {
      refetchOnWindowFocus: false,
			refetchOnMount: false,
			refetchOnReconnect: false,
			retry: 0,
		},
}
});

const loaderFunc = async () => {
  const urlParams = new URLSearchParams(window.location.search);
  const isAzureLogin = urlParams.get('azureLogin');
  const newRefreshToken = urlParams.get('data');
  if (isAzureLogin && newRefreshToken) {
    setLocalStorage<string>('refreshToken', newRefreshToken);
    const tokenData = await getRefreshToken();
    tokenData ? setLocalStorage('accessToken', tokenData.accessToken) : setLocalStorage('accessToken', null);
    tokenData ? setLocalStorage('refreshToken', tokenData.refreshToken) : setLocalStorage('refreshToken', null);
  }
  const token = getLocalStorage('accessToken');
  if (!token) throw redirect('/login');
  const user = await getCurrentUser();
  if (user) {
    useUserStore.getState().updateUser(user);
    return user;
  }
  return null;
}

const router = createBrowserRouter([
  {  
    path: '/',
    element: <Homepage/>,
    loader: loaderFunc,
    shouldRevalidate: () => false,
    children: [
      {index: true, element: <AccountsOverview/>},
      { path: 'accountDetails/:id', element: <AccountDetails />, children: []},
      { path: 'accountDetails/:id/opportunityDetails/:opportunityId', element: <OpportunityDetails /> }
    ],
  },
  {  
    path: '/opportunities',
    element: <Homepage/>,
    loader: loaderFunc,
    shouldRevalidate: () => false,
    children: [
      {index: true, element: <Opportunities />},
      { path: ':id/:opportunityId', element: <OpportunityDetails />},
    ],
  },
  {
    path: "/admin",
    shouldRevalidate: () => false,
    loader: loaderFunc,
    element: <AdminOverview/>,
  },
  {
    path: "andrej",
    element: <AndrejOverview/>,
  },
  {
    element: <Auth/>,
    children: [
      {path: "/login", element: <Login/>},
      {path: "/register", element: <Register/>},
      {path: "/reset", element: <ResetPassword/>},
      {path: "/forgotPassword", element: <ForgotPassword/>},
    ],
  },
  {path: "expiredRegister", element: <ExpiredRegisterLink/>},
  {path: "failedLogin", element: <FailedAzureLogin/>},
  {path: "*", element: <NotFoundPage />},
]);

function App() { 

  return (
    <QueryClientProvider client={queryClient}>
      <RouterProvider router={router}/>
      <Toast/>
      <ConfirmModal/>
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  );
}

export default App;
