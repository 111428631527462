function CloseIcon() {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
			<path
				d="M12 11.0103L11.6465 10.6568L6.49494 5.50523C6.49492 5.50521 6.4949 5.5052 6.49488 5.50518C6.36362 5.37398 6.18563 5.30028 6.00004 5.30028C5.81447 5.30028 5.6365 5.37396 5.50524 5.50512C5.50521 5.50516 5.50517 5.5052 5.50514 5.50523M12 11.0103L5.50514 5.50523M12 11.0103L12.3536 10.6568L17.5052 5.50518L17.5052 5.50523L17.5113 5.49898C17.5759 5.43212 17.6531 5.3788 17.7385 5.34211C17.8239 5.30542 17.9158 5.28611 18.0087 5.2853C18.1016 5.2845 18.1938 5.30221 18.2798 5.3374C18.3659 5.3726 18.444 5.42458 18.5098 5.4903C18.5755 5.55603 18.6275 5.63418 18.6627 5.72021C18.6979 5.80624 18.7156 5.89842 18.7148 5.99136C18.7139 6.08431 18.6946 6.17616 18.658 6.26156C18.6213 6.34696 18.5679 6.42421 18.5011 6.48878L18.501 6.48872L18.4949 6.49487L13.3433 11.6465L12.9897 12L13.3433 12.3536L18.4914 17.5017C18.6173 17.6334 18.6868 17.8091 18.6853 17.9914C18.6837 18.1749 18.6101 18.3505 18.4803 18.4803C18.3505 18.61 18.1749 18.6837 17.9914 18.6853C17.8091 18.6868 17.6335 18.6173 17.5017 18.4914L12.3536 13.3433L12 12.9897L11.6465 13.3433L6.49836 18.4914C6.36663 18.6173 6.19098 18.6868 6.0087 18.6853C5.82516 18.6837 5.64959 18.61 5.51981 18.4803C5.39002 18.3505 5.3164 18.1749 5.31481 17.9914C5.31322 17.8091 5.38281 17.6334 5.50867 17.5017L10.6568 12.3536L11.0103 12L10.6568 11.6465L5.50524 6.49492M12 11.0103L5.50524 6.49492M5.50514 5.50523C5.37397 5.63649 5.30029 5.81446 5.30029 6.00002C5.30029 6.18561 5.37399 6.36361 5.50519 6.49487M5.50514 5.50523L5.50519 6.49487M5.50519 6.49487C5.50521 6.49489 5.50523 6.49491 5.50524 6.49492M5.50519 6.49487L5.50524 6.49492"
				fill="#929292"
				stroke="#929292"
			/>
		</svg>
	);
}

export default CloseIcon;
