function StatusErroIcon() {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width="12" height="11" viewBox="0 0 12 11" fill="none">
			<rect width="11.9999" height="10.4999" transform="translate(0 0.124664)" fill="white" />
			<path
				d="M6.00875 7.12485C5.59599 7.12485 5.25 7.46685 5.25 7.87484C5.25 8.28284 5.59599 8.62484 6.00875 8.62484C6.40634 8.62484 6.76751 8.28284 6.7493 7.89284C6.76751 7.46385 6.42455 7.12485 6.00875 7.12485Z"
				fill="#FF5858"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M11.7129 7.55514C12.0977 8.19265 12.0952 8.95529 11.7079 9.59516C11.3182 10.2397 10.625 10.6246 9.85292 10.6246H2.13685C1.36722 10.6246 0.678991 10.2445 0.294174 9.61169C-0.0955756 8.97182 -0.0980424 8.20445 0.286774 7.55986L4.15467 1.14939C4.53702 0.509529 5.22772 0.124664 6.00229 0.124664C6.77685 0.124664 7.46755 0.507167 7.8499 1.15176L11.7129 7.55514ZM9.85056 9.69675C10.2748 9.69675 10.6547 9.48661 10.8693 9.13244C11.0815 8.78063 11.0839 8.36507 10.8767 8.02034L7.0113 1.61224C6.80163 1.26043 6.42421 1.05029 6.00239 1.05029C5.57811 1.05029 5.20316 1.25807 4.99348 1.60988L1.12559 8.01798C0.913444 8.37215 0.915911 8.79243 1.12805 9.14424C1.33773 9.49133 1.71514 9.69675 2.1345 9.69675H9.85056Z"
				fill="#FF5858"
			/>
			<path
				d="M5.82326 2.64133C5.4698 2.72146 5.25 2.97601 5.25 3.28477C5.26782 3.47096 5.28267 3.65951 5.30049 3.84571C5.35099 4.55514 5.40148 5.25043 5.45198 5.95986C5.4698 6.20026 5.70445 6.37468 6.00742 6.37468C6.31039 6.37468 6.54801 6.18848 6.56286 5.94572C6.56286 5.79959 6.56286 5.66525 6.58069 5.51676C6.61336 5.06188 6.649 4.60699 6.68168 4.15211C6.6995 3.8575 6.73217 3.56288 6.74999 3.26827C6.74999 3.16221 6.73217 3.06793 6.68168 2.97365C6.53019 2.70968 6.17673 2.57534 5.82326 2.64133Z"
				fill="#FF5858"
			/>
		</svg>
	);
}

export default StatusErroIcon;
