import { InformationCircleIcon } from "@heroicons/react/24/solid";

import classNames from "classnames";
import { FC } from "react";

export const Alerts: FC<{ type: "info" | "error" | "success"; children: JSX.Element }> = ({ type, children }) => {
	const bgColors = {
		info: "bg-blue-100",
		error: "bg-red-100",
		success: "bg-green-100",
	};

    const textColors = {
		info: "text-blue-400",
		error: "text-red-500",
		success: "text-green-400",
	};

    const icons = {
		info: <InformationCircleIcon className="h-10 w-10 text-blue-400" />,
		error: <InformationCircleIcon className="h-10 w-10 text-red-400" />,
		success: "bg-green-400",
	};

	return (
		<div className={classNames("rounded-md px-6 py-3", bgColors[type])}>
			<div className="flex items-center">
				<div>
					{icons[type]}
				</div>
				<div className={classNames("ml-3 text-left leading-6", textColors[type])}>{children}</div>
			</div>
		</div>
	);
};
