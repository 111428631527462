import { Form, Formik, FormikHelpers } from "formik";
import * as Yup from "yup";
import { Button } from "components/UI/buttons";
import { HrTextInput } from "components/forms/textInput";
import { useState } from "react";
import { ResetPasswordFormData } from "./resetPasswordModel";
import { PasswordValidation } from "components/shared/passwordValidation";
import { ShowPassword } from "components/shared/showPassword";
import { useSearchParams } from "react-router-dom";
import { useResetPassword } from "./useResetPassword";
import { hasLowerCase, hasSpecialChars, hasUpperCase } from "utilis/helpers";

const ResetPassword = () => {
	const [searchParams] = useSearchParams();
	const token = searchParams.get('token');
	const [showPassword, setShowPassword] = useState(false);
	const initialValues: ResetPasswordFormData = { password: "" };
	const {mutate: resetPassword} = useResetPassword();

	const onSubmit = (values: ResetPasswordFormData, helpers: FormikHelpers<ResetPasswordFormData>) => {
		const resetData = {
			...values,
			token
		}
		resetPassword(resetData)
		
	};

	const formValidation = Yup.object({
		password: Yup.string()
			.required("Password can not be empty")
			.min(10, "")
			.test("hasLowerCase", "", (val) => hasLowerCase(val))
			.test("hasUpperCase", "", (val) => hasUpperCase(val))
			.test("hasSpecChar", "", (val) => hasSpecialChars(val)),
	});

	return (
		<>
			<div className="mb-6">
				<h3 className="text-2xl text-black font-medium"> Password reset  </h3>
				<p className="font-normal text-black text-base"> Please create a new password for your account. </p>
			</div>
			<div className="mt-0">
				<Formik initialValues={initialValues} validationSchema={formValidation} onSubmit={onSubmit}>
					{({ isSubmitting, errors, values }) => (
						<Form>
							<div className="relative mb-2.5">
								<HrTextInput
									label="Password"
									name="password"
									type={showPassword ? "text" : "password"}
									placeholder="Enter password"
								/>
								<ShowPassword hasError = {errors.password} showPassword={showPassword} togglePassword = {setShowPassword} />
							</div>
							<div className="mb-6">
                                <PasswordValidation value={values.password} />
							</div>
							<div className="flex sm:flex-row flex-col">
								<Button type="submit" variant="primary" className="grow-2">
									Confirm
								</Button>
							</div>
						</Form>
					)}
				</Formik>
			</div>
		</>
	);
}

export default ResetPassword;