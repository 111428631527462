import { useMutation, useQueryClient } from "@tanstack/react-query";
import { deletePendingUser } from "api/user";
import useToastStore from "components/shared/toast/toastStore";

export const useDeletePendingUser = () => {
	const queryClient = useQueryClient();
	const { showToast } = useToastStore();
	return useMutation({
		mutationFn: deletePendingUser,
		onSuccess: (data) => {
            showToast('Invite has been canceled!', {type: 'success', position: 'top', description: 'User will be removed from the list'})
			queryClient.invalidateQueries({queryKey: ['pending-users'], type: 'active'});
			queryClient.removeQueries({queryKey: ['pending-users'], type: 'inactive'});
		},
	});
}