import { useMutation, useQueryClient } from "@tanstack/react-query";
import { addMeetingNote } from "api/meetings";
import useToastStore from "components/shared/toast/toastStore";



export const useAddMeetingNote = () => {
	const queryClient = useQueryClient();
	const { showToast } = useToastStore();
	return useMutation({
		mutationFn: addMeetingNote,
		onSuccess: () => {
			showToast("Note successfully added", { type: "success", position: "top" });
			queryClient.invalidateQueries(["meetings"]);
		},
	});
};