// import  Wyswyg  from 'components/shared/wysiwyg/wyswyg';
import {OpportunityFieldDefinition} from "./opportunityFieldDefinition";
import {OpportunityFieldType} from "./opportunityFieldType";
// import {SelectField} from "../../components/opportunities/inputs/selectField";
// import {TextField} from "../../components/opportunities/inputs/textField";
import {OpportunityFieldProps} from "../../components/opportunities/opportunityFieldProps";
import {FC} from "react";
// import {ToggleField} from "../../components/opportunities/inputs/toggleField";
// import {MultiselectField} from "../../components/opportunities/inputs/multiselectField";
import Textarea from 'components/shared/textarea';
// import Timepicker from 'components/shared/Timepicker';
import Datepicker from "components/shared/Datepicker";
// import { TagsInput } from "components/opportunities/inputs/inputTags";


export class OpportunityFieldRegistry {

  definitions: OpportunityFieldDefinition[] = [];
  sections: string[];

  private constructor(definitions: OpportunityFieldDefinition[]) {
    this.definitions = definitions;
    this.sections = this.getSections();
  }

  static create(schema: any): OpportunityFieldRegistry {
    return new OpportunityFieldRegistry(schema);
  }

  resolveComponent(definition: OpportunityFieldDefinition): FC<OpportunityFieldProps> {
    const {type} = definition;
    switch (type) {
      case OpportunityFieldType.DateTime:
        return Datepicker;
      default:
        return Textarea;
    }
  }

  private getSections = (): string[] => {
    const uniqueSectionsSet = new Set(this.definitions.map(definition => definition.section));
    return Array.from(uniqueSectionsSet);
  }

}
