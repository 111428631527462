import { HrMenu } from "components/UI/hrMenu";
import { FC } from "react";
import { PendingUsersData } from "../pendingUsersModel";
import { PlusCircleIcon, XCircleIcon } from "@heroicons/react/24/outline";
import Avatar from "components/shared/avatar";

export const PendingUsersListItem: FC<{
	isActiveUser?: boolean;
	user: PendingUsersData;
	handleMenuClick: (actionType: string, user: any) => void;
}> = ({ handleMenuClick, user, isActiveUser }) => {
	const menuItems = [
		{
			title: "Resend invite",
			icon: <PlusCircleIcon className="text-primary" width={20} height={20} />,
			actionType: "resendInvite",
		},
		{
			title: "Cancel invite",
			icon: <XCircleIcon className="text-red-500" width={20} height={20} />,
			actionType: "cancelInvite",
		},
	];

	const handleItemClick = (actionType: string) => {
		handleMenuClick(actionType, user);
	};

	return (
		<>
			<div className="py-3.5 flex justify-between items-center">
				<div className="flex gap-3 items-center">
					<Avatar userName={`${user.inviteeEmail}`} />
					<div>
						<p className="text-bs font-regular">
							<span className="text-gray-400 mr-2"> Email:</span>
							{user.inviteeEmail}
						</p>
					</div>
				</div>
				<div>
					<HrMenu handleMenuClick={handleItemClick} menuItems={menuItems} />
				</div>
			</div>
		</>
	);
};
