import {ReactElement, ReactNode, useRef} from 'react';

type AccordionProps = {
    header: ReactNode | string;
    open: boolean;
    children?: ReactNode | ReactElement;
    onChange?: (isOpen: boolean) => void;
};

export const Accordion = ({header, open, onChange, children}: AccordionProps) => {
    const contentRef = useRef<any>();

    const contentStyle = {
        display: open ? 'block' : 'none',
    };

    const toggleAccordion = () => onChange?.(!open);

    return (
      <>
          <div onClick={toggleAccordion}>
              {header}
          </div>
          <div ref={contentRef} style={contentStyle}>
              {children}
          </div>
      </>
    );
};

