import { useMutation, useQueryClient } from "@tanstack/react-query";
import { cancelMeeting } from "api/meetings";
import useToastStore from "components/shared/toast/toastStore";

export const useCancelMeeting = () => {
	const queryClient = useQueryClient();
	const { showToast } = useToastStore();
	return useMutation({
		mutationFn: cancelMeeting,
		onSuccess: () => {
			showToast('Meeting successfully canceled', {type: 'success', position: 'top'});
			queryClient.invalidateQueries(['meetings']);
		},
	});
}