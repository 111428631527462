import { FC, useEffect, useState } from "react";
import { MeetingProps } from "../meetingsModel";
import { useDeleteMeeting } from "../useDeleteMeeting";
import { MeetingListItem } from "./meeting-list-item";
import { useMeetings } from "./useMeetings";
import { EditMeeting } from "../meetings-edit/editMeeting";
import { MeetingNote } from "../meeting-note/meetingNote";
import useConfirmModalStore from "components/shared/confirm-modal/confirmModalStore";
import { TrashIcon, XCircleIcon } from "@heroicons/react/24/outline";
import { EmptyFilters } from "components/shared/emptyFilters";
import { useCancelMeeting } from "../useCancelMeeting";
import { useMeetingCountStore } from "store/meetingCountStore";

interface Props {
	readonly?: boolean;
	opportunityId: string;
	status: string[];
}

export const MeetingsList: FC<Props> = ({ opportunityId, readonly = false, status }) => {
	const { showModal } = useConfirmModalStore();
	const [open, setIsOpen] = useState(false);
	const [isNoteOpen, setNoteOpen] = useState(false);
	const [meeting, setMeeting] = useState<MeetingProps>();
	const { data: meetingsResponse, error, isLoading } = useMeetings({ opportunityId, status: status });
	const meetings = meetingsResponse?.rows ? meetingsResponse.rows : [];
	const { setMeetingsCount } = useMeetingCountStore();
	const { mutate: deleteMeeting } = useDeleteMeeting();
	const { mutate: cancelMeeting } = useCancelMeeting();

	const handleDelete = (meetingId: string) => {
		showModal("Delete meeting", () => deleteMeeting(meetingId), {
			icon: (
				<div className="p-3 bg-red-100 rounded-full">
					<TrashIcon className="h-6 w-6 text-red-500" />
				</div>
			),
			description: <div>Are you sure you want to delete meeting?</div>,
		});
	};

	useEffect( () => {
		setMeetingsCount(meetingsResponse?.count!);
	}, [setMeetingsCount, meetingsResponse?.count])

	const handleFinishMeeting = (meeting: MeetingProps) => {
		setNoteOpen(true);
		setMeeting(meeting);
	};

	const handleEdit = (meeting: MeetingProps) => {
		setIsOpen(true);
		setMeeting(meeting);
	};

	const handleCancel = (meetingId: string) => {
		showModal("Cancel meeting?", () => cancelMeeting(meetingId), {
			icon: (
				<div className="p-3 bg-red-100 rounded-full">
					<XCircleIcon className="h-6 w-6 text-red-500" />
				</div>
			),
			description: <div> 
			<p className="mb-4 text-sm"> Are you sure you want to cancel <strong> Meeting with the Team</strong>?</p>
			<p className="text-sm"> Meeting will be deleted on the Outlook side, contacts attached to this meeting will be informed </p>
			</div>,
			confirmBtnColor: 'error',
			confirmText: 'Cancel meeting',
			cancelText: 'Close'
		});
	};

	const handleMenuClick = (actionType: string, meeting: MeetingProps) => {
		switch (actionType) {
			case "editMeeting":
				handleEdit(meeting);
				break;
			case "cancelMeeting":
				handleCancel(meeting.id);
				break;
			case "deleteMeeting":
				handleDelete(meeting.id);
				break;
			case "endMeeting":
				handleFinishMeeting(meeting);
				break;
		}
	};

	if (isLoading) return <div></div>;

	if (error) return <div></div>;

	return (
		<>
			{status.length > 0 && meetings.length < 1 ? (
				<div className="px-6 py-10">
					<EmptyFilters content="No results match the filter search. You can try another filter or reset" />
				</div>
			) : (
				<div>
					{meetings.map((meeting) => (
						<MeetingListItem key={meeting.id} readonly={readonly} meeting={meeting} handleMenuClick={handleMenuClick} />
					))}
				</div>
			)}
			{open && <EditMeeting meeting={meeting!} open={open} setIsOpen={setIsOpen} />}
			{isNoteOpen && <MeetingNote meeting={meeting!} open={isNoteOpen} setIsOpen={setNoteOpen} />}
		</>
	);
};
