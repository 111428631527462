import { OpportunityFieldProps } from "components/opportunities/opportunityFieldProps";
import { FC } from "react";
import ReactDatePicker from "react-datepicker";

import styled from "styled-components";

const salesForceDateFormatter = (date: any) => {
  const year = date.getUTCFullYear();
  const month = date.getUTCMonth() + 1;
  const day = date.getUTCDate();
  const hour = date.getUTCHours();
  const minute = date.getUTCMinutes();
  const second = date.getUTCSeconds();

  return `${year}-${month.toString().padStart(2, '0')}-${day.toString().padStart(2, '0')}T${hour.toString().padStart(2, '0')}:${minute.toString().padStart(2, '0')}:${second.toString().padStart(2, '0')}.000+0000`;
}

const Datepicker: FC<OpportunityFieldProps> = ({readonly, value, definition, onChange}) => {
    const { name, caption } = definition;

    const handler = (date: Date | null, event: React.SyntheticEvent<any, Event> | undefined): void => {
      date && onChange(name, salesForceDateFormatter(date), true);
    }
    
    return readonly ? (
        <div>
          <label className="block text-sm font-normal leading-6 text-black-darker">{caption}</label>
          <div className="font-medium" dangerouslySetInnerHTML={{ __html: value || "N/A" }}></div>
        </div>
      ) : (
        <Wrapper>
            <ReactDatePicker
                selected={Boolean(value) ? new Date(value) : new Date()}
                onChange={handler}
                timeCaption="Date"
                showTimeSelect
                dateFormat="dd/MM/yyyy HH:mm"
                timeFormat="HH:mm"
            />
        </Wrapper>
    )
}

export const Wrapper = styled.div`
    width: 100%;
    .react-datepicker-wrapper{
      .react-datepicker__input-container{
        input {
          outline: none;
          border: 1px solid blue;
          padding: 8px 16px;
          border-radius: 5px;
          border-color: #4f3cb9;
          color: #4f3cb9;
          font-weight: 500;
          cursor: pointer;
          &:hover {
            background-color: #d9d9ff;
          }
        }
        .react-datepicker-ignore-onclickoutside {
            background-color: #d9d9ff;
        }
      }
    }

    .react-datepicker__tab-loop {
      .react-datepicker-popper{
        .react-datepicker__time-container{
          width: unset;
        }
        .react-datepicker__header--has-time-select {
          background-color: #d9d9ff;
        }
        .react-datepicker__header--time {
          background-color: #d9d9ff;
        }
        .react-datepicker__day {
          &:hover {
            background-color: #d9d9ff;
          }
        }
        .react-datepicker__day--selected {
          background-color: #6B67FE;
          &:hover {
            background-color: #6B67FE !important;
          }
        }
        .react-datepicker__time-list-item {
          &:hover {
            background-color: #d9d9ff !important;
          }
        }
        .react-datepicker__time-list-item--selected {
          background-color: #6B67FE !important;
          &:hover {
            background-color: #6B67FE !important;
          }
        }
        .react-datepicker__navigation-icon {
          &::before{
            border-color: #ffffff;
          }
        }

        .react-datepicker__time-list {
          &::-webkit-scrollbar {
            width: 3px;
            /* border-radius: 22px; */
          }

          /* Track */
          &::-webkit-scrollbar-track {
            background: #ffffff;
          }

          /* Handle */
          &::-webkit-scrollbar-thumb {
            background: #A9A7FE;
          }

          /* Handle on hover */
          &::-webkit-scrollbar-thumb:hover {
            background: #555;
          }
        }
      }
    }
`

export default Datepicker;