import { AddNewAccount } from "./add-new-account/addNewAccount";
import { Breadscrumb } from "components/shared/breadscrumb";
import { useEffect, useState } from "react";
import { AccountsOverviewList } from "./accountsOverviewList";
import { useIndustries } from "hooks/accounts/useIndustries";
import { useIndustryStore } from "store/industryStore";

const AccountsOverview = () => {
	const [isOpenAddUser, setIsOpenAddUser] = useState(false);
	const { data: industriesData } = useIndustries();
	const {setIndustries } = useIndustryStore();

	


	useEffect( () => {
		const industries = industriesData?.rows || [];
		setIndustries(industries);
	}, [setIndustries, industriesData])
	return (
		<>
			<Breadscrumb title="Accounts List" buttonTitle="Add new account" action={setIsOpenAddUser} />
			<div className="pb-5">
			<div className="filter-table my-3 bg-white rounded-md">
				<div className="p-4">
                  <AccountsOverviewList /> 
				</div>
			</div>
			</div>
			<AddNewAccount setIsOpen={setIsOpenAddUser} open={isOpenAddUser} />
		</>
	);
};

export default AccountsOverview;
