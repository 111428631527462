import {Dispatch, FC, ReactElement, ReactNode, SetStateAction, useState} from "react";
import ChevronDownIcon from "@heroicons/react/24/solid/ChevronDownIcon";
import ChevronUpIcon from "@heroicons/react/24/solid/ChevronUpIcon";
import {Accordion} from "../shared/accordion";
import clsx from "clsx";

interface OpportunityFieldsSectionProps {
  section: string;
  sectionsOpened: any[];
  setSectionsOpened: Dispatch<SetStateAction<any[]>>;
  readonly: boolean | undefined;
  onSave: () => void;
  onCancel: () => void;
  children: ReactNode | ReactElement;
}

export const OpportunityFieldsSection: FC<OpportunityFieldsSectionProps> = ({section, sectionsOpened, readonly, onCancel, onSave, setSectionsOpened, children}) => {
  const [isOpen, setOpen] = useState<boolean>(sectionsOpened.find((item) => section === item.sectionName).open);

  const saveDisabled = sectionsOpened.find((item) => section === item.sectionName).isSaveDisabled;
  const collapseSection = () => setOpen(false);

  return <Accordion open={isOpen} onChange={(b) => setOpen(b) } header={
    <div className="disableSelection flex gap-2 items-center p-4 border-b border-gray-100 cursor-pointer">
      <span className="inline-flex h-8 w-8 items-center justify-center rounded-full bg-primary-100">
        <span>
         {isOpen ? 
          <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="32" height="32" rx="16" fill="#6B67FE"/>
            <path d="M9 17L13 21L23 11" stroke="white" strokeWidth="1.76333" strokeLinecap="round" strokeLinejoin="round"/>
          </svg> 
          :
          <svg width="32" height="33" viewBox="0 0 32 33" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect y="0.0986328" width="32" height="32" rx="16" fill="#F0F0FF"/>
            <path d="M9 17.0986L13 21.0986L23 11.0986" stroke="#413F9B" strokeWidth="1.76333" strokeLinecap="round" strokeLinejoin="round"/>
          </svg>
        }
        </span>
      </span> 
      <span className="flex-grow">{section}</span>
      <span className="text-sm font-medium leading-none text-white">
          {isOpen ? <ChevronUpIcon className="h-6 w-6 text-primary-500"/> :
            <ChevronDownIcon className="h-6 w-6 text-primary-500"/>}
      </span>
    </div>
  }>
    {children}
    {
      // hasInValues(sectionDefinitions.map((definition: any) => definition.name)) &&
      !readonly && 
      <div className="disableSelection flex flex-row-reverse mt-4 gap-2 px-6">
         <button className={clsx("px-6 py-3 rounded text-white text-base", saveDisabled ? "bg-[#afafaf] cursor-not-allowed" : "bg-[#6B67FE]")} onClick={() => !saveDisabled && onSave()}>Save</button>
         <button className="px-6 py-3 rounded border border-[#A9A7FE] text-base text-[#6B67FE]" type="submit" onClick={() => {
          onCancel();
          collapseSection();
         }}>Cancel</button>
      </div>
    }
  </Accordion>
}
