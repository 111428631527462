import { MeetingNoteProps, MeetingProps, MeetingsFormProps, MeetingsResponseProps } from "components/meetings/meetingsModel";
import { FilterProps } from "models/filter";
import { axiosInstance } from "utilis/axiosConfig";
import { mapFilters } from "utilis/helpers";


const mapMeetingsData = (data: MeetingsFormProps) => {
    const participants = data.participants.map(email => { const participant = {email}; return participant});

    const mappedParticipants = {
        ...data,
        participants: participants
    }

    return mappedParticipants;
}

export const addMeeting = async (data: MeetingsFormProps): Promise<void | undefined> => {
    const body = mapMeetingsData(data);

    try {
        const response = await axiosInstance.post<void>('/v1/meeting', body);
        return response.data;
    } catch(error) {
        throw error
    }
}

export const getAllMeetings = async (filter: FilterProps | undefined): Promise<MeetingsResponseProps | undefined> => {
    const params = mapFilters(filter);
    try {
        const response = await axiosInstance.get<MeetingsResponseProps>('/v1/meeting', {params});
        return response.data;
    } catch(error) {
        throw error;
    }
}

export const getSingleMeeting = async (id: string): Promise<MeetingProps | undefined> => {
    try {
        const response = await axiosInstance.get<MeetingProps>(`/v1/meeting/${id}`);
        return response.data;
    } catch(error) {
        throw error;
    }
}

export const deleteMeeting = async (id: string): Promise<void | undefined> => {
    try {
        const response = await axiosInstance.delete<void>(`/v1/meeting/${id}`);
        return response.data;
    } catch(error) {
        throw error;
    }
}

export const editMeeting = async (data: MeetingsFormProps): Promise<void | undefined> => {
    const {id, ...body} = mapMeetingsData(data);
    try {
        const response = await axiosInstance.put<void>(`/v1/meeting/${id}`, body);
        return response.data;
    } catch(error) {
        throw error;
    }
}

export const addMeetingNote = async (data: MeetingNoteProps): Promise<void | undefined> => {
    const {id, ...body} = data;
    try {
        const response = await axiosInstance.patch<void>(`/v1/meeting/finish/${id}`, body);
        return response.data;
    } catch(error) {
        throw error;
    }
}

export const cancelMeeting = async (id: string): Promise<void | undefined> => {
    try {
        const response = await axiosInstance.patch<void>(`/v1/meeting/cancel/${id}`);
        return response.data;
    } catch(error) {
        throw error;
    }
}