import { FC } from "react";
import { ButtonProps } from "models/button";

export const Button: FC<ButtonProps> = ({ children, type, onClick, className, variant, disabled }) => {
	const data: { [key: string]: string } = {
		primary: "bg-primary text-white hover:bg-primary-500 focus-visible:outline-indigo-600",
	    microsoft: "bg-white, text-black-400 border-gray-300 border",
		secondary: "border border-primary-500 bg-white text-primary-500 hover:bg-primary-100 hover:text-primary-500",
		transparent: "border-none text-primary-500 shadow-none sm:shadow-none !px-1",
		icon: "border-none text-primary-500 shadow-none sm:shadow-none",
		error: "bg-red-500 text-white hover:bg-red-600 focus-visible:outline-red-600",
		success: "bg-green-500 text-white hover:bg-green-600 focus-visible:outline-green-600",
		warning: "bg-red-500 text-white hover:bg-red-600 focus-visible:outline-red-600",
	};

	return (
		<>
			<button type={type} onClick={onClick} disabled={disabled} className={`flex  whitespace-nowrap justify-center items-center rounded-md px-6 py-3 text-sm font-semibold leading-6 shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 ${data[variant]} ${className}`}> {children}</button>
		</>
	);
};
