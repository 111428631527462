import { useState } from "react";
import {
    ArrowRightOnRectangleIcon,
    UserGroupIcon,
	UserPlusIcon,
	Bars3Icon,
	LightBulbIcon
} from "@heroicons/react/24/outline";
import HomerunLogoIcon from "assets/icons/homerun-logo";
import { HomerunTextIcon } from "assets/icons/homerun-text";
import { NavLink, useNavigate } from "react-router-dom";
import { Button } from "components/UI/buttons";
import { logout } from "api/auth";
import { useUserStore } from "store/userStore";
import { checkIsAdmin } from "utilis/helpers";
import Avatar from "./avatar";

export const Sidebar = () => {
	const [sidebarOpen, setSidebarOpen] = useState(false);
	const {user} = useUserStore();
	const navigate = useNavigate();
	const isAdmin = checkIsAdmin();
	let navigation = [
		{ name: "Users", href: "/admin", icon: UserGroupIcon},
		{ name: "Accounts Overview", href: "/", icon: UserPlusIcon},
		{ name: "My Opportunities", href: "/opportunities", icon: LightBulbIcon},
	];

	if(!isAdmin) {
		navigation = navigation.filter(route => route.name !== 'Users');
	}

	if(isAdmin) {
		navigation = navigation.filter(route => route.name !== 'My Opportunities');
	}
    
	return (
		<>
			<div
				className={`fixed top-0 left-0 z-50 w-72 h-screen transition-transform ${
					!sidebarOpen ? "-translate-x-full" : ""
				} lg:translate-x-0`}
			>
				<div className="flex grow flex-col gap-y-5 overflow-y-auto bg-white py-10 h-full">
					<div className="flex gap-1 h-16 shrink-0 px-10">
						<HomerunLogoIcon />
						<HomerunTextIcon color="black"/>
					</div>
					<nav className="flex flex-1 flex-col">
						<ul className="flex flex-1 flex-col gap-y-7">
							<li>
								<ul className="space-y-1">
									{navigation.map((item) => (
										<li key={item.name}>
											<NavLink to={item.href}>
												{({ isActive }) => (
													<span
														className={`flex items-center gap-x-3 px-10 py-2 text-base transition-color font-medium leading-6 hover:bg-primary-70 hover:text-primary-500 hover:border-r-3 hover:border-primary-500 ${
															isActive ? "bg-primary-70 text-primary-500 border-r-3 border-primary-500" : "text-gray-500"
														}`}
													>
														<item.icon
															className={`
																${isActive ? "text-primary-500" : undefined}
																"h-6 w-6 shrink-0"
															`}
															aria-hidden="true"
														/>
														{item.name}
													</span>
												)}
											</NavLink>
										</li>
									))}
								</ul>
							</li>
							<li className="mt-auto mx-10 pt-6 px-0 border-t border-t-gray-100">
								<ul className="space-y-1">
									{/* <li className="pb-7">
										<NavLink
											to="#"
											className="group flex gap-x-3 rounded-md text-base font-medium leading-6 text-white"
										>
											<Cog6ToothIcon
												className="h-6 w-6 shrink-0 text-white"
												aria-hidden="true"
											/>
											Settings
										</NavLink>
									</li> */}
									<li className="pb-5">
										<Button
										    onClick={() => logout(navigate)}
										    variant='transparent'
											type='button'
											className="group flex gap-x-3 y-2 !text-base font-medium leading-6 !text-gray-500 !px-0"
										>
											<ArrowRightOnRectangleIcon
												className="h-6 w-6 shrink-0 text-gray-500 group-hover:text-gray-500"
												aria-hidden="true"
											/>
											Log out
										</Button>
									</li>
                                    <li className="flex gap-3">
										<Avatar userName={`${user?.firstName} ${user?.lastName}`} width={49} height={49} textSize="font-sm"/>
                                         <div>
                                            <p className="text-gray-500 font-medium truncate w-44"> {user?.firstName} {user?.lastName} </p>
                                            <p className="text-gray-500 text-sm truncate w-44"> {user?.emailData.email} </p>
                                         </div>
									</li>
								</ul>
							</li>
						</ul>
					</nav>
				</div>
			</div>
			{/* We removed topbar so this is temporary button for mobile devices, until we decide what we will do with topbar */}
			<div className="lg:pl-72">
					<button type="button" className=" p-6 text-gray-300 lg:hidden" onClick={() => setSidebarOpen(true)}>
						<span className="sr-only">Open sidebar</span>
						<Bars3Icon className="h-6 w-6" aria-hidden="true" />
					</button>
			</div>
			{/* <Topbar setSidebarOpen={setSidebarOpen} /> */}
			{sidebarOpen && (
					<div
						onClick={() => setSidebarOpen(false)}
						className="bg-gray-300 bg-opacity-50 dark:bg-opacity-80 fixed inset-0 z-40"
					></div>
				)}
		</>
	);
};
