export enum OpportunityFieldTypeEnum {
  TEXT = 'Text',
  SELECT = 'Select',
  CURRENCY = 'Currency',
  DATE = 'Date',
  TOGGLE = 'Toggle',
}

export enum OpportunityPhaseEnum {
  PRE_TRIAL = 'pre-trial',
  TRIAL = 'trial',
  POST_TRIAL = 'post-trial',
}
