import { ConfirmModalStoreProps } from "models/modal";
import { create } from "zustand";

const useConfirmModalStore = create<ConfirmModalStoreProps>((set) => ({
	title: '',
    onConfirm: () => {},
	options: {},
	isVisible: false,
	showModal: (title, onConfirm, options) =>
		set((state) => ({
			options: {
				...state.options,
				...options,
			},
            onConfirm,
			title,
			isVisible: true,
		})),
	hideModal: () => set({  isVisible: false}),
	reset: () => set({options: {}})
}));

export default useConfirmModalStore;