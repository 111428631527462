import React from "react";
import { useField } from "formik";
import { FormTextInput } from "models/forms";

export const HrSearchInput: React.FC<FormTextInput> = ({ label, field: inputField, form, ...props }) => {
	const [field] = useField(props);
	return (
		<>
			{label ? (
				<label
					className='block text-sm font-normal leading-6 text-black-darker'
					htmlFor={props.id || props.name}
				>
					{label}
				</label>
			) : null}
			<input
				className={'block w-full min-w-[15rem] rounded-md p-2 focus:outline-none placeholder:text-gray-300 bg-gray-50 sm:text-sm sm:leading-8'}
				{...field}
				{...props}
			/>
		</>
	);
};

