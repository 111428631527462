import { useQuery } from "@tanstack/react-query";
import { getAllSalesForceAccounts } from "api/account";
import { PaginationApiProps } from "models/pager";


export const useSalesForceAccounts = (search: string,  paginationData: PaginationApiProps = {limit: 10, currentPage: 1}) => {

	return useQuery({
        queryKey: ['sales-force-accounts', search],
		queryFn: () =>  getAllSalesForceAccounts( {name: { like: `${search}%`}}, paginationData),
		refetchOnMount: true,
		enabled: true
	});
};