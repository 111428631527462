import { PencilIcon, TrashIcon } from "@heroicons/react/24/outline";
import { FC, useState } from "react";
import { Action, ColumnProps } from "models/table";
import { OpportunityProps } from "./opportunitiesModel";
import { HrTable } from "components/shared/table";
import { AccountsProps } from "pages/accounts-overview/accountsOverviewModel";
import { useOpportunity } from "./useOpportunity";
import Avatar from "components/shared/avatar";
import { OpportunityStatus } from "./opportunity-status/opportunityStatus";
import { useNavigate, useSearchParams } from "react-router-dom";
import useConfirmModalStore from "components/shared/confirm-modal/confirmModalStore";
import { Alerts } from "components/shared/alerts";
import { useRemoveOpportunity } from "./useDeleteOpportunity";
import { OpportunityEdit } from "./opportunity-edit/opportunityEdit";
import { EmptyFilters } from "components/shared/emptyFilters";
import { ErrorState } from "components/shared/errorState";
import { Spinner } from "components/shared/spinner";
import { HRPopover } from "components/shared/popover";
import { OpportunityFilters } from "./opportunity-filter/opportunityFilters";
// import { useUserStore } from "store/userStore";
import { checkIsAE, checkIsAdmin, checkIsSDR } from "utilis/helpers";
import { Pager } from "components/shared/pager";
import { EmptyState } from "components/shared/emptyState";
import { format } from "date-fns";
import { useUserStore } from "store/userStore";

interface DefaultFilters {
	accountId: string;
	sdrId?: string;
	aeId?: string;
}

export const OpportunitiesList: FC<{ account: AccountsProps }> = ({ account }) => {
	const [openEditModal, setOpenEditModal] = useState<boolean>(false);
	const [searchParams, setSearchParams] = useSearchParams();
	const params = Object.fromEntries(searchParams.entries());
	const hasFilters = params && Object.keys(params).length > 0;
	const [currentPage, setCurrentPage] = useState(1);
	const navigate = useNavigate();
	const isSdr = checkIsSDR();
	const isAe = checkIsAE();
	const isAdmin = checkIsAdmin();
	const { user } = useUserStore();
	// const { user } = useUserStore();
	let defaultFilters: DefaultFilters = {
		accountId: account.id,
	};
	if (isSdr) {
		defaultFilters = {
			...defaultFilters,
			// sdrId: user?.id!,
		};
	}
	if (isAe) {
		defaultFilters = {
			...defaultFilters,
			// aeId: user?.id!,
		};
	}

	const { showModal } = useConfirmModalStore();
	const [opportunity, setOpportunity] = useState<OpportunityProps>();
	const { mutate: removeOpportunity } = useRemoveOpportunity();
	const {
		data: opportunitiesResponse,
		isLoading,
		error,
		refetch,
	} = useOpportunity({ ...defaultFilters, ...params }, { limit: 10, currentPage });
	const opportunities = opportunitiesResponse?.rows.length ? opportunitiesResponse.rows : [];

	const handlePage = (p: number) => {
		setCurrentPage(p);
	};

	const handleRetry = () => {
		setSearchParams({});
		setCurrentPage(1);
		refetch();
	};

	const handleNavigate = (opportunity: OpportunityProps) => {
		navigate(`opportunityDetails/${opportunity.id}`);
	};

	const checkIsMyOpportunity = (row: OpportunityProps) => {
		if(isAe) return Boolean(row.aeId === user!.id);
		if(isSdr) return Boolean(row.sdrId === user!.id);
		if(isAdmin) return false;
	}
	

	const removeHandler = (opportunity: OpportunityProps) => {
		showModal(
			"Remove opportunity",
			() =>
				removeOpportunity(opportunity.id, {
					onSuccess: () => {
						if (opportunities.length === 1 && currentPage !== 1) {
							setCurrentPage((previousValue) => previousValue - 1);
						}
					},
				}),
			{
				icon: (
					<div className="p-3 bg-red-100 rounded-full">
						<TrashIcon className="h-6 w-6 text-red-500" />{" "}
					</div>
				),
				description: (
					<div>
						<div className="mb-4">Are you sure you want to remove this opportunity?</div>
						<div className="mb-14">
							<Alerts type="error">
								<p className="font-normal">
									By removing this opportunity you will remove all segments from this opportunity.
								</p>
							</Alerts>
						</div>
					</div>
				),
				confirmText: "Confirm",
				cancelText: "Close",
			}
		);
	};

	const editHandler = (opportunity: OpportunityProps) => {
		setOpportunity(opportunity);
		setOpenEditModal(true);
	};

	const columns: ColumnProps<OpportunityProps, keyof OpportunityProps>[] = [
		{
			key: "name",
			title: "Opportunity Name",
			render: (_, { name }) => (
				<div className="flex items-center gap-2">
					<Avatar userName={name} />
					{name}
				</div>
			),
		},
		{
			key: "sdr",
			title: "Assigned to",
			render: (_, { sdr, ae }) => {
				const sdrName = `${sdr?.firstName} ${sdr?.lastName}`;
				const aeName = `${ae?.firstName} ${ae?.lastName}`;
				return (
					<div className="flex items-center gap-2">
						{sdr && (
							<HRPopover content={sdrName}>
								<Avatar userName={sdrName} />{" "}
							</HRPopover>
						)}
						{ae && (
							<HRPopover content={aeName}>
								<Avatar userName={aeName} />{" "}
							</HRPopover>
						)}
					</div>
				);
			},
		},
		{
			key: "meetings",
			title: "Last Meeting",
			render: (_, { meetings }) => (
				<> {meetings.length > 0 ? format(new Date(meetings[0].endsAt), "MMMM d, yyyy h:mm a") : ""}</>
			),
		},
		{
			key: "status",
			title: "Status",
			render: (_, { status }) => <OpportunityStatus type={status} />,
		},
	];

	const data: OpportunityProps[] = opportunities;

	const actions: Action<OpportunityProps>[] = [
		{
			label: "Edit",
			icon: <PencilIcon className="h-5, w-5 text-gray-600" />,
			condition: (row) => !isAdmin && checkIsMyOpportunity(row)!,
			callback: (row) => {
				editHandler(row);
			},
		},
		{
			label: "Delete",
			icon: <TrashIcon className="h-5, w-5 text-gray-600" />,
			condition: (row) => !isAdmin && checkIsMyOpportunity(row)!,
			callback: (row) => {
				removeHandler(row);
			},
		},
	];

	if (isLoading)
		return (
			<div>
				<Spinner />
			</div>
		);

	if (error)
		return (
			<div>
				<ErrorState action={() => handleRetry()} />
			</div>
		);

	if (opportunities && opportunities.length < 1 && !hasFilters)
		return (
			<div>
				<EmptyState title="opportunities" />
			</div>
		);

	return (
		<>
			<div className="my-5">
				<OpportunityFilters
					results={opportunitiesResponse?.count!}
					filters={searchParams}
					setFilters={setSearchParams}
					setCurrentPage={setCurrentPage}
				/>
			</div>
			{opportunities && opportunities.length < 1 && hasFilters ? (
				<div className="py-24">
					<EmptyFilters
						action={() => {
							setSearchParams({});
							setCurrentPage(1);
						}}
					/>
				</div>
			) : (
				<div>
					<HrTable columns={columns} data={data} actions={actions} handleNavigate={handleNavigate} />
					{opportunitiesResponse?.count! > 10 && (
						<Pager
							current={currentPage}
							pageSize={10}
							total={opportunitiesResponse?.count}
							onChange={(p) => handlePage(p)}
						/>
					)}
				</div>
			)}
			{openEditModal && (
				<OpportunityEdit
					opportunity={opportunity!}
					account={account}
					open={openEditModal}
					setIsOpen={setOpenEditModal}
				/>
			)}
		</>
	);
};
