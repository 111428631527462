import CloseIcon from "assets/icons/close";
import { Button } from "components/UI/buttons";
import { HrTextInput } from "components/forms/textInput";
import { Form, Formik, FormikHelpers } from "formik";
import { Dispatch, FC, SetStateAction } from "react";
import * as Yup from "yup";
import { Modal } from "components/shared/modal";
import { PencilIcon } from "@heroicons/react/24/outline";
import { Spinner } from "components/shared/spinner";
import { HrTagInput } from "components/forms/tagsInput";
import { HrDateTimePicker } from "components/forms/datetimePicker";
import { useAddMeeting } from "./useAddMeeting";
import { MeetingsFormProps } from "../meetingsModel";
import { regexValidation } from "utilis/regex";

interface Props {
	opportunityId: string;
	open: boolean;
	setIsOpen: Dispatch<SetStateAction<boolean>>;
}

export const AddMeeting: FC<Props> = ({ open, setIsOpen, opportunityId }) => {
	const EMAIL_REGX = regexValidation.EMAIL_REGX;
	const initialValues: MeetingsFormProps = {
		title: "",
		opportunityId: opportunityId,
		participants: [],
		startsAt: "",
		endsAt: "",
		description: "",
	};
	const { mutate: addMeeting } = useAddMeeting();

	const onSubmit = (values: MeetingsFormProps, { setSubmitting }: FormikHelpers<MeetingsFormProps>) => {
		addMeeting(values, { onSuccess: () => handleClose(), onError: () => setSubmitting(false) });
	};

	const validateChips = (chips: string[]) => {
		return chips.every(chip => Yup.string().matches(EMAIL_REGX).isValidSync(chip));
	  };

	const formValidation = Yup.object({
		title: Yup.string().required("Name can not be empty").max(40, 'Title length must be less than or equal to 40 characters long'),
		startsAt: Yup.string().required("Start time can not be empty"),
		endsAt: Yup.string().required("End time can not be empty"),
		participants: Yup.array().test('isValidEmails', 'All participants must have valid email address', (value) => validateChips(value!))
			.min(1, "At least one participants"),
	});

	const handleClose = () => {
		setIsOpen(false);
	};

	return (
		<>
			<Modal open={open} setIsOpen={setIsOpen}>
				<div className="flex justify-between items-center mb-3">
					<div className="flex items-center gap-3">
						<div className="rounded-full p-3 bg-primary-100">
							<PencilIcon className="h-4 w-4 text-primary-500"> </PencilIcon>
						</div>
						<h2 className="text-xl font-medium"> Create meeting </h2>
					</div>

					<button onClick={handleClose} className="h-full">
						<CloseIcon />
					</button>
				</div>
				<Formik initialValues={initialValues} validationSchema={formValidation} onSubmit={onSubmit}>
					{({ isSubmitting, values }) => (
						<Form>
							<div>
								<div className="mb-4">
									<HrTextInput label="Title*" name="title" type="text" placeholder="Enter title" />
								</div>
								<div className="mb-4">
									<div className="flex gap-1">
										<div className="w-full">
											<HrDateTimePicker startDate={new Date()} endDate={values.endsAt ? new Date(values.endsAt) : undefined} label="Meeting start*" type="" name="startsAt" placeholder="Enter date & time" />
										</div>
										<div className="w-full">
											<HrDateTimePicker startDate={values.startsAt ? new Date(values.startsAt) : new Date()} label="Meeting end*" type="" name="endsAt" placeholder="Enter date & time" />
										</div>
									</div>
								</div>
								<div className="mb-4">
									<HrTagInput label="Participants" name="participants" type="text" placeholder="Add participants" />
								</div>
								<div className="mb-4">
									<HrTextInput label="Description" name="description" type="text" placeholder="Enter description" />
								</div>
							</div>
							<div className="flex gap-4">
								<Button type="button" variant="secondary" onClick={handleClose} className="flex-1">
									Cancel
								</Button>
								<Button type="submit" variant="primary" className="flex-1" disabled={isSubmitting}>
									{isSubmitting ? <Spinner className="h-5 w-5 fill-gray-100" /> : "Create"}
								</Button>
							</div>
						</Form>
					)}
				</Formik>
			</Modal>
		</>
	);
};
