import { ScoreProps } from "pages/account-details/opportunities/opportunitiesModel";
import Step from "./step";
// import StepArrow from "./arrow";

interface Props {
    score: ScoreProps;
    setPhase: (s: string) => void;
    activeTab: number;
    phase: string | undefined;
}

const Stepper = ({score, setPhase, phase, activeTab}: Props) => {
    const checkActive = (ph: string) => activeTab === 2 ? ph === phase : ph === score.phase;

    const steps = [
        {
            active: checkActive('pretrial'), 
            stepNumber: 1, unlocked: true, 
            completed: +score.aeScoreByPhases.preTrial >= 50, 
            points: +score.aeScoreByPhases.preTrial, 
            title: "Pre-Trial", 
            phase: "pretrial"
        },
        {
            isArrow: true, 
            // arrowIcon: <StepArrow />,
            arrowIcon: <div className="relative w-full">saa<i className="absolute p-1 border border-black border-t-0 border-r-[1px] border-b-[1px] border-l-0 right-0 top-0 rotate-[-45deg]"></i></div> ,
            stepNumber: 0, 
            active: false, 
            unlocked: false, 
            completed: false, 
            points: 0, 
            title: "arrow1", 
            phase: "arrow1" },
        // {
        //     active: checkActive('trial'), 
        //     stepNumber: 2, 
        //     unlocked: +score.aeScore >= 35, 
        //     completed: +score.aeScoreByPhases.trial >= 30, 
        //     points: +score.aeScoreByPhases.trial, 
        //     title: "Trial", 
        //     phase: "trial"
        // },
        // {
        //     isArrow: true, 
        //     arrowIcon: <StepArrow />, 
        //     stepNumber: 0, 
        //     active: false, 
        //     unlocked: false, 
        //     completed: false, 
        //     points: 20, 
        //     title: "arrow2", 
        //     phase: "arrow2" 
        // },
        {
            active: checkActive('posttrial'), 
            stepNumber: 3, 
            unlocked: +score.aeScore >= 35,
            completed: false, 
            points: +score.aeScoreByPhases.postTrial, 
            title: "Post-Trial", 
            phase: 'posttrial'
        }
    ];
    
    return (
        <div className="bg-[#FAFAFB] rounded flex w-full md:w-[80%] flex-col gap-1 md:gap-2 items-center pl-5 md:flex-row">
            {
                steps.map((item, index) => item.isArrow ? 
                <div key={item.title} className="relative hidden md:block w-11/12 border-b-[2px]"><i className="absolute p-1 border border-black border-t-0 border-r-[2px] border-b-[2px] border-l-0 right-0 top-[-4px] rotate-[-45deg]"></i></div>
                    : 
                    <Step 
                        activeTab={activeTab} 
                        setPhase={setPhase} 
                        step={item}
                        isLast={index === steps.length - 1} 
                        key={item.title} 
                    />
                )
            }
        </div>
    )
}

export default Stepper;