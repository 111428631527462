function HomerunLogoIcon({width = 27, height = 27}: {width?: number, height?: number}) {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 27 27" fill="none">
			<g clipPath="url(#clip0_249_14223)">
				<path
					d="M5.00889 2.83853V23.5224C1.95768 21.1088 0.00012207 17.3732 0.00012207 13.1805C0.00012207 8.98771 1.95768 5.25221 5.00889 2.83853Z"
					fill="#6A67FF"
				/>
				<path
					d="M16.8984 13.4762C17.565 14.143 17.9725 15.0687 17.9725 16.8467V25.4627C16.4873 26.0426 14.8709 26.361 13.1802 26.361C12.0204 26.361 10.8955 26.2111 9.82385 25.9298V14.6986C10.4904 13.7726 12.1572 12.4762 14.1944 12.4762C15.3056 12.4762 16.2685 12.8466 16.8984 13.4762Z"
					fill="#FF6249"
				/>
				<path
					d="M22.7877 22.2037V17.2171C22.7877 14.1429 22.1209 12.3278 20.9357 10.9205C19.4541 9.14254 17.1945 8.03133 14.7129 8.03133C12.8611 8.03133 11.1572 8.66097 9.82385 9.69813V0.431158C10.8955 0.14987 12.0204 0 13.1802 0C20.4598 0 26.3608 5.9012 26.3608 13.1805C26.3608 16.6716 25.0036 19.8457 22.7877 22.2037Z"
					fill="#B8EB4B"
				/>
			</g>
			<defs>
				<clipPath id="clip0_249_14223">
					<rect width="26.3609" height="26.361" fill="white" />
				</clipPath>
			</defs>
		</svg>
	);
}

export default HomerunLogoIcon;
