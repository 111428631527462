import { useMutation, useQueryClient } from "@tanstack/react-query";
import { AEEditOportunity, SDREditOportunity } from "api/opportunity";
import { AxiosError } from "axios";
import useToastStore from "components/shared/toast/toastStore";

interface error  {
	field: number;
	message: string;
};

export const useOpportunityEdit = ( isSDR: boolean | null) => {
	const queryClient = useQueryClient();
	const { showToast } = useToastStore();
	return useMutation({
		mutationFn: isSDR ? SDREditOportunity : AEEditOportunity,
		onSuccess: () => {
			showToast('Opportunity successfully updated', {type: 'success', position: 'top', description: 'User will be notified'});
			queryClient.invalidateQueries({queryKey: ['opportunity'], type: "active"});
			queryClient.invalidateQueries({ queryKey: ['participants'], type: "active"});
			queryClient.invalidateQueries({ queryKey: ['opportunityById'], type: "active"});
			queryClient.removeQueries({queryKey: ['opportunity'], type: "inactive"});
			queryClient.removeQueries({ queryKey: ['opportunityById'], type: "inactive"});
			queryClient.removeQueries({queryKey: ['participants'], type: "inactive"});
			queryClient.removeQueries({queryKey: ['account-statuses'], type: "inactive"});

		},
		onError:(errorData: AxiosError<error>) => {
			if (errorData.response?.status === 400) {
				return showToast(errorData.response.data.message, { type: "error", position: "top-right" });
			}
		}
	});
}