import { useMutation, useQueryClient } from "@tanstack/react-query";
import { unblockUser } from "api/user";
import useToastStore from "components/shared/toast/toastStore";

export const useUnblockUser = () => {
	const queryClient = useQueryClient();
	const { showToast } = useToastStore();
	return useMutation({
		mutationKey: ["block-user"],
		mutationFn: unblockUser,
		onSuccess: (data) => {
			queryClient.invalidateQueries({queryKey: ['active-users'], type: 'active'});
			showToast("User is unblocked!", { type: "success", position: "top", description: "All permissions restored!" });
		},
	});
};
