export const setLocalStorage = <T>(key: string, data: T) => {
    const dataJson = JSON.stringify(data);
    localStorage.setItem(key, dataJson);
}

export const getLocalStorage = <T>(key: string): T | null => {
   const data =  localStorage.getItem(key);
   if (!data) {
     return null;
   }
   return JSON.parse(data);
}

export const clearLocalStorage = () => {
  localStorage.clear();
}

export const removeStorageItem = (key: string) => {
  localStorage.removeItem(key);
}