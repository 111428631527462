import CloseIcon from "assets/icons/close";
import { Button } from "components/UI/buttons";
import { 
	// ArrayHelpers, 
	// FieldArray, 
	Form, 
	Formik, 
	FormikHelpers } from "formik";
import { Dispatch, FC, SetStateAction, useState } from "react";
import * as Yup from "yup";
import { HrSelect } from "components/forms/select";
import { Modal } from "components/shared/modal";
import { UserIcon } from "@heroicons/react/24/outline";
import { Spinner } from "components/shared/spinner";
import { useActiveUsers } from "pages/admin/active-users-list/useActiveUsers";
import { useUserStore } from "store/userStore";
import { SelectOptionsData } from "models/forms";
import { checkIsSDR } from "utilis/helpers";
import { ActiveUsersData } from "pages/admin/active-users-list/activeUsersModel";
import { OpportunityAddProps} from "./opportunityAddModel";
import { AccountsProps } from "pages/accounts-overview/accountsOverviewModel";
import { useOpportunityAdd } from "./useOpportunityAdd";
import { useParams } from "react-router-dom";
import { useSalesForceOpportunities } from "./useSalesForceOpportunities";
// import { DropdownWithSearch } from "components/shared/dropdownWithSearch";
import InputSearchDropdown from "components/shared/inputSearchDropdown";
import { useGeogeaphies } from "hooks/accounts/useGeographies";
import { InputDisabledOnly } from "components/forms/inputDisabledOnly";
// import { ContactForm } from "../opportunityContactForm";
// import { regexValidation } from "utilis/regex";

interface Props {
	account: AccountsProps; 
	open: boolean;
	setIsOpen: Dispatch<SetStateAction<boolean>>
}

export const OpportunityAdd: FC<Props> = ({
	open,
	setIsOpen,
	account,
}) => {
	// const EMAIL_REGX = regexValidation.EMAIL_REGX;
	const geographies = useGeogeaphies();
	const { data: users } = useActiveUsers({}, { limit: 100, currentPage: 1 });
	const params = useParams();
	const [opportunitySearchTerm, setOpportunitySearchTerm] = useState("");
	const {data: opportunitiesSf, isFetching } = useSalesForceOpportunities(params.id!, opportunitySearchTerm);

	const [selectedOpportunity, setSelectedOpportunity] = useState<{id: string, name: string} | null>(null);
	
	const { user } = useUserStore();
	const isSDR = checkIsSDR()!;
	let sdrOptions: SelectOptionsData[] | undefined = [];
	let aeOptions: SelectOptionsData[] | undefined = [];
	
	const { mutate: addOportunity } = useOpportunityAdd(isSDR);
	const initialValues: OpportunityAddProps = {externalId: '', regionId: '', sdrId: isSDR ? user?.id : '' , aeId: !isSDR ? user?.id : '', industry: account.industry || "", contacts: []};

	//later improve performance for this;
	const filterOptions = () => {

		let sdrUsers: ActiveUsersData[] | undefined;
		let aeUsers: ActiveUsersData[] | undefined;
		aeUsers = users?.rows.filter((user) => {
			return user.assignedRoles.some(({ role }) => role.name === "AE");
		});

		sdrUsers = users?.rows.filter((user) => {
			return user.assignedRoles.some(({ role }) => role.name === "SDR");
		});
        sdrOptions = sdrUsers?.map((user) => {
			const data = {
				id: user.id,
				name:`${user.firstName} ${user.lastName}`,
			};
			return data;
		})
		aeOptions = aeUsers?.map((user) => {
				const data = {
					id: user.id,
					name: `${user.firstName} ${user.lastName}`,
				};
				return data;
			})
	};
	

	if (users) {
		filterOptions();
	}

	const onSubmit = (values: OpportunityAddProps, { setSubmitting }: FormikHelpers<OpportunityAddProps>) => {
		if (!values.aeId) delete values.aeId;
		if(!selectedOpportunity){
			return;
		}
		
		const data = {
			...values,
			externalId: selectedOpportunity.id
		}
		addOportunity(data, { onSuccess: () => handleClose(), onError: () => setSubmitting(false)});
	};
    
	const AEValidation =  Yup.object({
		sdrId: Yup.string().required(selectedOpportunity ? "Assignee can not be empty" : "Select Opportunity"),
		regionId: Yup.string().required(selectedOpportunity ? "Geography can not be empty" : "Select Opportunity"),
	});

	const SDRValidation =  Yup.object({
		aeId: Yup.string().required(selectedOpportunity ? "Assignee can not be empty" : "Select Opportunity"),
		regionId: Yup.string().required(selectedOpportunity ? "Geography can not be empty" : "Select Opportunity"),
	});

	const formValidation = isSDR ? SDRValidation : AEValidation;

	const handleClose = () => {
		setIsOpen(false);
		setSelectedOpportunity(null);
		// setIndustry("");
	};

	const opportunitySearchHandler = (e: string) => {
		setOpportunitySearchTerm(e);
	}

	const handleChangeOpportunity = (s: any) => {
		setSelectedOpportunity(s);
	}

	return (
		<>
			{sdrOptions && aeOptions && (
				<Modal open={open} setIsOpen={setIsOpen}>
					<div className="flex justify-between items-center mb-3">
						<div className="flex items-center gap-3">
							<div className="rounded-full p-3 bg-primary-100">
								<UserIcon className="h-4 w-4 text-primary-500"> </UserIcon>
							</div>
							<h2 className="text-xl font-medium"> Add opportunity </h2>
						</div>

						<button onClick={handleClose} className="h-full">
							<CloseIcon />
						</button>
					</div>
					<div className="mb-5">
						<InputSearchDropdown 
							placeholder="Search Opportunity"
							label="Opportunity"
							name='externalId'
							options={opportunitiesSf?.rows.map(item => ({...item, name: item.Name, id: item.Id, isDisabled: item.isConnected})) || []}
							searchHandler={opportunitySearchHandler}
							searchTerm={opportunitySearchTerm}
							loading={isFetching}
							onChange={(e) => handleChangeOpportunity(e)}
						/>
					</div>
					{/* <div className="mb-4">
						<InputDisabledOnly placeholder="The field will be filled after you select Opportunity" label="Industry" value={industry} />
					</div> */}
					<Formik initialValues={initialValues} validationSchema={formValidation} onSubmit={onSubmit}>
						{({ isSubmitting, values }) => (
							<Form>
								<div>
									{/* <div className="mb-5">
										<HrTextInput label="Industry" disabled={true} name='industry' type="text" placeholder="Industry" />
									</div> */}
									<div className="mb-5">
										<InputDisabledOnly label="Industry" value={account.industry} />
									</div>
									<div className="mb-5">
										<HrSelect
											placeholder={selectedOpportunity ? "Choose Geography" : "Select Opportunity First"}
											label="Geography"
											name='regionId'
											disabled={!selectedOpportunity}
											options={geographies.data?.map(geography => ({id: geography.id, name: geography.name})) ||  []}
										/>
									</div>
									<div className="mb-5">
										<HrSelect
											placeholder={selectedOpportunity ? "Choose SDR" : "Select Opportunity First"}
											label="Sales Development Representative"
											name='sdrId'
											disabled={isSDR || !selectedOpportunity}
											options={sdrOptions! }
										/>
									</div>
									<div className="mb-5">
										<HrSelect
											placeholder="Choose AE"
											label="Account Executive"
											name='aeId'
											disabled={!isSDR || !selectedOpportunity}
											options={aeOptions!}
										/>
									</div>
								</div>
								<div className="flex gap-4 flex-wrap">
									<Button type="button" variant="secondary" onClick={handleClose} className="flex-1">
										Cancel
									</Button>
									<Button type="submit" variant="primary" className="flex-1">
										{isSubmitting ? <Spinner className="h-5 w-5 fill-gray-100" /> : 'Create oportunity' }
									</Button>
								</div>
							</Form>
						)}
					</Formik>
				</Modal>
			)}
		</>
	);
};
