import {OpportunityFieldTypeEnum} from "./enums";

export const SdrFieldDefinition = [
  {
    name: 'kpis',
    caption: "KPI's",
    section: 'Metrics',
    type: OpportunityFieldTypeEnum.TEXT,
    weight: 10,
  },
  {
    name: 'pain_points',
    caption: 'What are their pain points',
    section: 'Identify pain',
    type: OpportunityFieldTypeEnum.TEXT,
    weight: 15,
  },
  {
    name: 'impact',
    section: 'Identify pain',
    caption: 'What is the impact of these challenges on their business?',
    type: OpportunityFieldTypeEnum.TEXT,
    weight: 15,
  },
  {
    name: 'current_solution',
    section: 'Competition',
    caption: 'What is their current solution?',
    type: OpportunityFieldTypeEnum.TEXT,
    weight: 15,
  },
  {
    name: 'checking_solutions',
    section: 'Competition',
    caption: 'What are the solutions they are checking?',
    type: OpportunityFieldTypeEnum.TEXT,
    weight: 15,
  },
  {
    name: 'key_hook',
    section: 'Other',
    caption: 'Key Hook',
    type: OpportunityFieldTypeEnum.TEXT,
    weight: 10,
  },
  {
    name: 'goals',
    section: 'Other',
    caption: 'Goals',
    type: OpportunityFieldTypeEnum.TEXT,
    weight: 10,
  },
  {
    name: 'attendee_insight',
    section: 'Other',
    caption: 'Attendee(s) insight',
    type: OpportunityFieldTypeEnum.TEXT,
    weight: 10,
  },
  {
    name: 'contact_insight',
    section: 'Other',
    caption: 'Any other company contact(s) insight',
    type: OpportunityFieldTypeEnum.TEXT,
    weight: 5,
  },
  {
    name: 'tech_stack',
    section: 'Other',
    caption: 'Tech Stack',
    type: OpportunityFieldTypeEnum.TEXT,
    weight: 10,
  },
  {
    name: 'current_process',
    section: 'Other',
    caption: 'Current process',
    type: OpportunityFieldTypeEnum.TEXT,
    weight: 10,
  },
  {
    name: 'target_audience',
    section: 'Other',
    caption: 'Target Audience',
    type: OpportunityFieldTypeEnum.TEXT,
    weight: 10,
  },
  {
    name: 'other_info',
    section: 'Other',
    caption: 'Any other relevant information',
    type: OpportunityFieldTypeEnum.TEXT,
    weight: 5,
  },
  {
    name: 'preferred_lang',
    section: 'Other',
    caption: 'Preferred language',
    type: OpportunityFieldTypeEnum.SELECT,
    weight: 5,
    options: [
      {
        key: 'en',
        label: 'English',
      },
      {
        key: 'de',
        label: 'German',
      },
    ],
  },
];
