import { useMutation, useQueryClient } from "@tanstack/react-query";
import { addNewContact, editContact } from "api/contacts";
import useToastStore from "components/shared/toast/toastStore";

export const useContactDetails = (contactId: string | undefined) => {
	const queryClient = useQueryClient();
	const { showToast } = useToastStore();
	return useMutation({
		mutationFn: contactId ? editContact : addNewContact,
		onSuccess: () => {
			showToast(contactId ? 'Contact successfully updated' : 'Contact successfully added', {type: 'success', position: 'top'});
			queryClient.invalidateQueries({ queryKey: ['opportunityById'], type: "active"});
		},
		onError: (res: any) => {
			showToast(res.response.status === 400 ? res.response.data.message : 'Something went wrong!', {type: 'error', position: 'top-right'});
		}
	});
}