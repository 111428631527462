import { PlusIcon } from "@heroicons/react/20/solid";
import { Button } from "components/UI/buttons";
import { BreadscrumbData } from "models/breadscrumb";
import { ChevronLeftIcon } from "@heroicons/react/24/outline";
import { FC } from "react";
import { Link } from "react-router-dom";
import classNames from "classnames";

export const Breadscrumb: FC<BreadscrumbData> = ({title, buttonTitle, className, action, showBackButton = false}) => {
    return <div className={classNames("flex-col gap-3 bg-white py-5 px-6 -ml-4 -mt-4 flex xs:flex-row justify-between", className)}>
      <div className="flex items-center gap-2"> 
      {showBackButton && <Link to="../.." relative="path" > <ChevronLeftIcon className="h-6 w-6 text-gray-400" /> </Link>}
      <h2 className="text-2xl font-medium"> {title || ""} </h2>
      </div>
      {action && <Button variant="primary" type="button" onClick={() => action(true)}> <PlusIcon width={24} height={24} /> { buttonTitle }</Button>}
    </div>

}