import { FC, Fragment, useState } from "react";

import { FormSelectSearchInput, SelectOptionsData } from "models/forms";

import { ChevronUpDownIcon } from "@heroicons/react/24/outline";
import { Combobox, Transition } from "@headlessui/react";
import classNames from "classnames";
import { Spinner } from "./spinner";

const InputSearchDropdown:FC<FormSelectSearchInput> = ({ placeholder, loading, label, disabled, options, width, onChange, searchHandler, searchTerm, ...props }) => {
	const [selectedPerson, setSelectedPerson] = useState<SelectOptionsData>({ name: "", id: "" });

    const handleOnChange = (option: SelectOptionsData) => {
		  setSelectedPerson(option);
		  onChange && onChange(option);
	  };

    return(
        <div className="w-full">
        <Combobox value={selectedPerson} onChange={handleOnChange}>
          <div className="relative">
            <div className="relative w-full cursor-default overflow-hidden bg-white text-left focus:outline-none sm:text-sm">
                <Combobox.Label
                    className={`block text-sm font-normal leading-6 text-black-darker`}
                    htmlFor={props.id || props.name}
                >
                {label}
                </Combobox.Label>
                <Combobox.Button className="relative w-full">
                <Combobox.Input
                    style={{ minWidth: width }}
                    className={classNames(
                        "block min-h-[46px] disabled:bg-gray-100 w-full rounded-md p-2 focus:outline-none  placeholder:text-gray-300 border sm:text-sm sm:leading-[1.875rem]",
                        { "min-w-[15rem]": !width },
                    )}
                    displayValue={(option: SelectOptionsData) =>  option.name}
                    onChange={(event) => searchHandler(event.target.value)}
                    placeholder={placeholder}
                />
                    <ChevronUpDownIcon className="h-10 w-7 absolute inset-y-0 top-[5px] right-0 flex items-center pr-2" aria-hidden="true" />
                </Combobox.Button>
            </div>
            <Transition
              as={Fragment}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
              afterLeave={() => searchHandler('')}
            >
              <Combobox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base 
                                           shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                {options.length === 0 && !loading ? (
                  <div className="relative cursor-default select-none py-2 px-4 text-gray-700">
                    {searchTerm ? "Nothing was found for this search term." : "Search..."}
                  </div> 
                ) : (
                  loading ?
                  <div className="py-3">
                    <Spinner />
                  </div>
                  :
                  options.map((option) => (
                    <Combobox.Option
                      key={option.id}
                      disabled={Boolean(option.isDisabled)}
                      className={({ active, disabled, selected }) =>
                      classNames(
                        selected ? "bg-gray-100 text-gray-900" : "text-gray-900",
                        disabled ? "bg-gray-300 text-gray-100" : "",
                        "relative cursor-default select-none py-2 pl-8 pr-4"
                    )
                      }
                      value={option}
                    >
                        {({ selected, active }) => (
					    	          <div className="flex gap-2">
					    	          	<span className={classNames(selected ? "font-semibold" : "font-normal", "block truncate")}>
					    	          	    {option.name}
					    	          	</span>
                            {option.isDisabled && <span className="text-green-500">connected</span>}
					    	          </div>
					              )}
                    </Combobox.Option>
                  ))
                )}
              </Combobox.Options>
            </Transition>
          </div>
        </Combobox>
      </div>
    )
}

export default InputSearchDropdown;