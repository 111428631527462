import { FC } from "react";
import classNames from "classnames";
import { MeetingStatusProps } from "./meetingStatusModel";

export const MeetingStatus:FC<MeetingStatusProps> = ({type}) => {

    const name =  {
        upcoming: 'Upcoming',
        ongoing: ' Ongoing',
        finished: 'Finished',
        canceled: 'Canceled'
    }

    const statusClasses = {
        upcoming: 'bg-primary text-white',
        ongoing: 'bg-green-500 text-white',
        finished:  'bg-gray-300 text-white',
        canceled: 'bg-red-500 text-white',
    }
    return <div className={classNames('rounded-xl flex items-center leading-5 w-max px-3 text-center text-xs', statusClasses[type])}>
        {name[type]}
    </div>

}