function StatusSuccessIcon() {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width="12" height="13" viewBox="0 0 12 13" fill="none">
			<circle cx="6" cy="6.37463" r="5.5" stroke="#0B9444" />
			<path d="M3.3335 6.72731L5.10569 8.69128L8.81302 4.37463" stroke="#0B9444" strokeLinecap="round" />
		</svg>
	);
}

export default StatusSuccessIcon;
