import { AccountsEditProps } from "pages/accounts-overview/add-new-account/accountsFormModel";
import { IndustryResponseProps } from "models/industry";
import { AccountsProps, AccountsResponseProps, SalesForceResponseProps } from "pages/accounts-overview/accountsOverviewModel";
import { axiosInstance } from "utilis/axiosConfig";
import { mapFilters, mapPaginationData } from "utilis/helpers";
import { FilterProps } from "models/filter";
import { PaginationApiProps } from "models/pager";
import { ParticipantsResponse } from "pages/account-details/participants/participantsModel";
import { AccountStatusInfoProps } from "models/accountStatusInfo";
import { GeographyResponseProps } from "models/geographies";

export const addNewAccount = async (data: {externalId: string}): Promise<void | undefined> => {
    try {
        const response = await axiosInstance.post<void>('/v1/account', data);
        return response.data;
    } catch(error) {
        throw error
    }
}

export const getAccount = async (id: string): Promise<AccountsProps | undefined> => {
    try {
        const response = await axiosInstance.get<AccountsProps>(`/v1/account/${id}`);
        return response.data;
    } catch(error) {
        throw error;
    }
}

export const editAccount = async (data: AccountsEditProps): Promise<void | undefined> => {
    const {id, ...values} = data;
    try {
        const response = await axiosInstance.put<void>(`/v1/account/${id}`, values);
        return response.data;
    } catch(error) {
        throw error;
    }
}

export const removeAccount = async (id: string): Promise<void | undefined> => {
    try {
        const response = await axiosInstance.delete<void>(`/v1/account/${id}`);
        return response.data;
    } catch(error) {
        throw error;
    }
}

export const getAllAccounts = async (filter: FilterProps | undefined,  paginationData: PaginationApiProps): Promise<AccountsResponseProps | undefined> => {
    const filters = mapFilters(filter);
    const paginationParams = mapPaginationData(paginationData);
    const params = {
        ...filters,
        ...paginationParams
    }
    try {
        const response = await axiosInstance.get<AccountsResponseProps>('/v1/account', {params});
        return response.data;
    } catch(error) {
        throw error;
    }
}

export const getAllSalesForceAccounts = async (search: {name?: { like: string}}, pagination: PaginationApiProps): Promise<SalesForceResponseProps | undefined> => {
    const paginationParams = mapPaginationData(pagination);
    const params = {
        filters: {...search},
        ...paginationParams
    }
    
    try {
        const response = await axiosInstance.get<SalesForceResponseProps>('/v1/account/sales-force', {params});
        return response.data;
    } catch(error) {
        throw error;
    }
}

export const getIndustries = async (): Promise<IndustryResponseProps | undefined> => {
    try {
        const response = await axiosInstance.get<IndustryResponseProps>('/v1/account/industries');
        return response.data;
    } catch(error) {
        throw error;
    }
}

export const getGeographies = async (): Promise<GeographyResponseProps[] | undefined> => {
    try {
        const response = await axiosInstance.get<GeographyResponseProps[]>('/v1/opportunity/regions');
        return response.data;
    } catch(error) {
        throw error;
    }
}

export const getParticipants = async (id: string): Promise<ParticipantsResponse | undefined> => {
    try {
        const response = await axiosInstance.get<ParticipantsResponse>(`/v1/account/${id}/participants`);
        return response.data;
    } catch(error) {
        throw error;
    }
} 

export const getAccountStatuses = async (id: string): Promise<AccountStatusInfoProps[] | undefined> => {
    try {
        const response = await axiosInstance.get<AccountStatusInfoProps[]>(`/v1/account/${id}/opportunity/count/status`);
        return response.data;
    } catch(error) {
        throw error;
    }
} 