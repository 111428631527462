import { ClosedLostIcon, ClosedWonIcon, OngoingIcon } from "components/shared/icons"
import { OpportunityEditApiProps } from "../../opportunity-edit/opportunityEditModel"
import { OpportunityProps, ScoreProps } from "../../opportunitiesModel";
import { useRef } from "react";
import { Formik, FormikProps } from "formik";
import { checkIsAE } from "utilis/helpers";
import { useOpportunityEdit } from "../../opportunity-edit/useOpportunityEdit";
import Stepper from "./stepper/stepper";
import { Form } from "react-router-dom";
import { HrSelect } from "components/forms/select";


interface Props {
    opportunity: OpportunityProps;
    score: ScoreProps;
    setPhase: (s: string) => void;
    activeTab: number;
    phase: string | undefined;
    isMyOpportunity: boolean;
}

const StepperStatusSection = ({opportunity, phase, score, setPhase, activeTab, isMyOpportunity}: Props) => {

    const formikRef = useRef<FormikProps<any>>(null);
    const isAE = checkIsAE()!;

    const {mutate: editOpportunity} = useOpportunityEdit(false);

    const statusOptions = [
        {name: "Ongoing", id: "ongoing", icon: <OngoingIcon />},
        {name: "Closed won", id: "closed won", icon:  <ClosedWonIcon />},
        {name: "Closed lost", id: "closed lost", icon: <ClosedLostIcon />}
    ]

    const initialValues = {
        name: opportunity?.status || "",
    }

    const onChange = (value: any) => {
        const contacts = opportunity.contacts.map(item => ({name: item.name, role: item.role, email: item.email}))
		const data:OpportunityEditApiProps = {
			// name: opportunity.name,
			sdrId: opportunity.sdrId,
			contacts,
			id: opportunity.id,
            status: value.id,
            regionId: opportunity.regionId
		};
        editOpportunity(data);
    }
    return(
        <div className="py-2">
            {(activeTab !== 1) && 
            <div className="flex flex-col md:flex-row md:justify-between gap-3 md:gap-2 ">
                <Stepper phase={phase} activeTab={activeTab} score={score} setPhase={setPhase} />
                <div className="flex bg-white md:flex-col items-center py-2 md:py-0 justify-around md:justify-between w-full md:w-[124px] h-[48px] mx-auto md:mx-0">
		    	    {(isAE && activeTab === 2 && isMyOpportunity ) ?
                    <Formik innerRef={formikRef} initialValues={initialValues} handleChange={(change: any) => {}} onSubmit={() => {}}>
		    	    	<Form className="flex gap-5 flex-wrap">
		    	    		    <div className="flex items-center">
		    	    			    <HrSelect onChange={onChange} width="124px" name="name" options={statusOptions} />
		    	    		    </div>
		    	    	</Form>
		    	    </Formik> 
                    : 
                    <div className="flex items-center w-[124px] gap-3 justify-center h-full">
                        <div>{statusOptions.find(item => item.id === opportunity?.status)?.icon}</div> 
                        <div className="text-sm">{statusOptions.find(item => item.id === opportunity?.status)?.name}</div>
                    </div>
                    }
                </div>
            </div>
        }
        </div>
    )
}

export default StepperStatusSection;