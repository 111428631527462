import { LoginData, LoginDataResponse } from "pages/login/loginModel";
import { RegisterData } from "pages/register/registerModel";
import { ResetPasswordData } from "pages/reset-password/resetPasswordModel";
import { NavigateFunction } from "react-router-dom";
import { axiosInstance } from "utilis/axiosConfig";
import { getLocalStorage } from "utilis/storage";

export const loginUser = async (data: LoginData): Promise<LoginDataResponse | undefined> => {
	try {
		const response = await axiosInstance.post<LoginDataResponse>("/v1/auth/login", data, {withCredentials: true});
		return response.data;
	} catch (error) {
		throw error
	}
};

export const registerUser = async (data: RegisterData): Promise<void | undefined> => {
	try {
		const response = await axiosInstance.post<void>("/v1/invite/user/accept", data);
		return response.data;
	} catch (error) {
		throw (error);
	}
};

export const loginUserViaAzure = async (): Promise<void | undefined> => {
	try {
		const response = await axiosInstance.get<void>("/v1/auth/azure/login", {withCredentials: true});
		return response?.data;
	} catch (error) {
	}
};

export const requestPasswordReset = async (data: Omit<LoginData, 'password'>): Promise<void | undefined> => {
	try {
		const response = await axiosInstance.post<void>("/v1/password/reset/request", data);
		return response?.data;
	} catch (error) {
        throw error;
	}
};

export const passwordReset = async (data: ResetPasswordData): Promise<void | undefined> => {
	try {
		const response = await axiosInstance.post<void>("/v1/password/reset", data);
		return response?.data;
	} catch (error) {
        throw error;
	}
};


export const getRefreshToken = async () => {
	const refreshToken = getLocalStorage('refreshToken');
	try {
		const response = await axiosInstance.post<LoginDataResponse>("/v1/auth/refresh", {refreshToken}, {withCredentials: true},);
		return response.data;
	} catch (error) {
		throw error;
	}
};


export const logout = async (navigate: NavigateFunction) => {
	await axiosInstance.post<void>("/v1/auth/sign-out", null);
	localStorage.clear();
	navigate("/login");
} 