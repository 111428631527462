import { FilterProps } from "models/filter";
import { PaginationApiProps } from "models/pager";
import { OpportunityAddApiProps, } from "pages/account-details/opportunities/opportunity-add/opportunityAddModel";
import { ISalesForceOpportunitiesResponse, OpportunityProps, OpportunityResponseProps, ScoreProps } from "pages/account-details/opportunities/opportunitiesModel";
import { axiosInstance } from "utilis/axiosConfig";
import { mapFilters, mapPaginationData } from "utilis/helpers";
import { OpportunityEditApiProps } from "pages/account-details/opportunities/opportunity-edit/opportunityEditModel";

export const AEaddOportunity = async (data: OpportunityAddApiProps): Promise<void | undefined> => {
    const {industry, aeId, ...body } = data;
    try {
        const response = await axiosInstance.post<void>('/v1/opportunity/ae', body);
        return response.data;
    } catch(error) {
        throw error
    }
}

export const SDRaddOportunity = async (data: OpportunityAddApiProps): Promise<void | undefined> => {
    const {industry, sdrId, ...body } = data;
    try {
        const response = await axiosInstance.post<void>('/v1/opportunity/sdr', body);
        return response.data;
    } catch(error) {
        throw error
    }
}

export const AEEditOportunity = async (data: OpportunityEditApiProps): Promise<void | undefined> => {
    const {id, industry, aeId, ...body} = data;
    try {
        const response = await axiosInstance.put<void>(`/v1/opportunity/ae/${id}`, body);
        return response.data;
    } catch(error) {
        throw error
    }
}

export const SDREditOportunity = async (data: OpportunityEditApiProps): Promise<void | undefined> => {
    const {id, industry, sdrId, ...body } = data;
    try {
        const response = await axiosInstance.put<void>(`/v1/opportunity/sdr/${id}`, body);
        return response.data;
    } catch(error) {
        throw error
    }
}

export const getOpportunities = async (filter: FilterProps | undefined, paginationData: PaginationApiProps): Promise<OpportunityResponseProps> => {
    const filters = mapFilters(filter);
    const paginationParams = mapPaginationData(paginationData);
    const params = {
        ...filters,
        ...paginationParams
    }
    try {
        const response = await axiosInstance.get<OpportunityResponseProps>(`/v1/opportunity`, {params});
        return response.data;
    } catch(error) {
        throw (error);
    }
}

export const removeOpportunity = async (id: string): Promise<void | undefined> => {
    try {
        const response = await axiosInstance.delete<void>(`/v1/opportunity/${id}`);
        return response.data;
    } catch(error) {
        throw error;
    }
}

export const getOpportunity = async (id: string): Promise<void | OpportunityProps> => {
    try {
        const response = await axiosInstance.get<void>(`/v1/opportunity/${id}`);
        return response.data;
    } catch(error) {
        throw error;
    }
}

export const getOpportunityScore = async (id: string): Promise<void | ScoreProps> => {
    try {
        const response = await axiosInstance.get<void>(`/v1/opportunity/${id}/score`);
        return response.data;
    } catch(error) {
        throw error;
    }
}

export const getAEFields = async (id: string): Promise<void | any> => {
    try {
        const response = await axiosInstance.get<void>(`/v1/opportunity/${id}/fields`, {params: {role: 'AE'}});
        return response.data;
    } catch(error) {
        throw error;
    }
}

export const getSDRFields = async (id: string): Promise<void | any> => {
    try {
        const response = await axiosInstance.get<void>(`/v1/opportunity/${id}/fields`, {params: {role: 'SDR'}});
        return response.data;
    } catch(error) {
        throw error;
    }
}

// export const updateFields = async (id: string, fields: any): Promise<void | OpportunityProps> => {
//     try {
//         const response = await axiosInstance.put<void>(`/v1/opportunity/${id}/fields`, {fields});
//         return response.data;
//     } catch(error) {
//         throw error;
//     }
// }

export const updateFields = async ({id, fields}: {id:string, fields: any}): Promise<void | undefined> => {
    try {
        const response = await axiosInstance.put<void>(`/v1/opportunity/${id}/fields`, fields);
        return response.data;
    } catch(error) {
        throw error
    }
}

export const getAllSalesForceOpportunities = async (accountId: string, search: {name: { like: string}}): Promise<ISalesForceOpportunitiesResponse | undefined> => {
    const params = {
        filters: {...search},
        limit: 10
    }
    try {
        const response = await axiosInstance.get<ISalesForceOpportunitiesResponse>(`/v1/opportunity/${accountId}/sales-force`, {params});
        return response.data;
    } catch(error) {
        throw error;
    }
}