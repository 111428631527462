import { IndustryProps } from "models/industry";
import { create } from "zustand";


interface IndustryStoreProps {
    industries: IndustryProps[];
	setIndustries: (industries: IndustryProps[]) => void;
}

export const useIndustryStore = create<IndustryStoreProps>((set) => ({
	industries: [],
	setIndustries: (industries: IndustryProps[]) => set((state) => ({industries: industries })),
}));