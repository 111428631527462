import Avatar from "components/shared/avatar";
import ContactsAndTeam from "./contacts-and-team/contactsAndTeam";
import { OpportunityProps } from "pages/account-details/opportunities/opportunitiesModel";
import { format } from "date-fns";
import { AccountsProps } from "pages/accounts-overview/accountsOverviewModel";

interface Props {
    opportunity: OpportunityProps;
    account: AccountsProps;
	isMyOpportunity: boolean;
}

const GeneralDetails = ({opportunity, account, isMyOpportunity}: Props) => {
    return(
        <div className="flex-1 rounded flex flex-col md:flex-row items-center gap-2">
            <div className="flex items-center gap-5 md:w-1/2">
			    <div>
		    		<Avatar userName={opportunity.name} width={120} height={120} textSize="text-xl" />
			    </div>
			    <div>
		    		<h3 className="text-3xl font-medium">{opportunity.name}</h3>
		    		{account.industry && <p className="text-base font-medium text-[#6B67FE]">{account.industry}</p>}
					<small className="text-xs font-light text-gray-500">Last update</small>
		    		<p className="text-xs font-regular"> {opportunity.updatedAt ? format(new Date(opportunity.updatedAt), "dd.MM.yyyy, h:mm a") : ""}</p>
					<small className="text-xs font-light text-gray-500">Region</small>
		    		{opportunity.region && <p className="text-xs font-regular"> {opportunity.region.name}</p>}
			    </div>
		    </div>
            {/*  */}
            <ContactsAndTeam opportunity={opportunity} isMyOpportunity={isMyOpportunity} />
        </div>
    )
}

export default GeneralDetails;