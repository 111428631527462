import { useQuery } from "@tanstack/react-query";
import { getAllSalesForceOpportunities } from "api/opportunity";

export const useSalesForceOpportunities = (id: string, search: string) => {

	return useQuery({
        queryKey: ['sales-force-opportunities', search],
		queryFn: () =>  getAllSalesForceOpportunities(id, {name: { like: `%${search}%`}}),
		refetchOnMount: true,
		enabled: true
	});
};