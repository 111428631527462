import Team from "./team";
import { useState } from "react";
import { OpportunityProps, OpportunityRoleProps } from "pages/account-details/opportunities/opportunitiesModel";
import { AccountsProps } from "pages/accounts-overview/accountsOverviewModel";
import { checkIsAdmin } from "utilis/helpers";
import ContactsList from "./contacts/contactsList";
import ContactBox from "./contacts/contactsBox";
import { ContactsProps } from "./contacts/contactsModel";
import { ContactDetails } from "./contacts/contact-details/contactDetails";

interface Props {
    opportunity: OpportunityProps;
    account?: AccountsProps;
    isMyOpportunity: boolean;
}

const ContactsAndTeam = ({opportunity, isMyOpportunity}: Props) => {
    const isAdmin = checkIsAdmin();

    const [isOpen, setIsOpen] = useState(false);
    const [isViewAllOpened, setIsViewAllOpened] = useState(false);

    const viewAllContacts = () => setIsViewAllOpened(true);

    let team:(OpportunityRoleProps)[] = [];
    let contacts = opportunity?.contacts.filter((value: ContactsProps, index: number, self: ContactsProps[]) => {
		return index === self.findIndex(element => element.email.toLowerCase() === value.email.toLowerCase());
	}) || []
    const favoriteContacts = contacts.filter(contact => contact.isFavorite === true);

    if(opportunity.ae) team.push({...opportunity.ae});
    if(opportunity.sdr) team.push({...opportunity.sdr});

    return(
        <div className="bg-[#FAFAFB] flex flex-col gap-3 py-2 px-3 w-full md:w-1/2 rounded">
            <ContactBox isMyOpportunity={isMyOpportunity} listLength={contacts.length} title="Contacts" viewAllHandler={viewAllContacts} list={favoriteContacts} onClick={isAdmin && !isMyOpportunity ? undefined : () => setIsOpen(true) } />
            {/*  */}
            <div className="border-t-[1px] border-[#ECECEE] w-full"></div>
            {/*  */}
            <Team title="Team" list={team} />
            {/*  */}
            {isOpen && <ContactDetails open={isOpen} setIsOpen={setIsOpen} /> }
            <ContactsList isMyOpportunity={isMyOpportunity} open={isViewAllOpened} setIsOpen={setIsViewAllOpened} contacts={contacts} />
        </div>
    )
}

export default ContactsAndTeam;