import { checkIsAdmin, checkIsSDR } from "utilis/helpers";
import { ScoreProps } from "../opportunitiesModel";

interface Props {
    score: ScoreProps;
    activeTab: number;
}

const Score = ({score, activeTab}: Props) => {
    const isSdr = checkIsSDR();
    const isAdmin = checkIsAdmin();

    const checkAdminScore = activeTab === 0 || activeTab === 2 ? score.aeScore : score.sdrScore;

    return(
        <div className="bg-[#FAFAFB] h-full rounded flex md:flex-col items-center justify-around md:justify-between py-4 px-1 w-full md:w-[184px] md:h-[190px] mx-auto md:mx-0">
            <div className="rounded-full w-10 h-10 bg-[#413F9B] flex justify-center items-center">
                <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M9.66299 2.8708C9.44922 2.21234 8.51765 2.21234 8.30316 2.8708L7.21716 6.21246C7.17039 6.35592 7.07942 6.4809 6.9573 6.56951C6.83517 6.65812 6.68814 6.70582 6.53725 6.70578H3.024C2.33193 6.70578 2.04309 7.59231 2.60361 7.99983L5.44624 10.0646C5.56835 10.1534 5.65922 10.2785 5.70584 10.4221C5.75246 10.5657 5.75243 10.7203 5.70576 10.8639L4.62048 14.2056C4.40599 14.864 5.16026 15.4124 5.72006 15.0049L8.56269 12.9401C8.68487 12.8513 8.83203 12.8035 8.98308 12.8035C9.13413 12.8035 9.28129 12.8513 9.40347 12.9401L12.2461 15.0049C12.8059 15.4124 13.5602 14.8647 13.3457 14.2056L12.2604 10.8639C12.2137 10.7203 12.2137 10.5657 12.2603 10.4221C12.3069 10.2785 12.3978 10.1534 12.5199 10.0646L15.3625 7.99983C15.9224 7.59231 15.6349 6.70578 14.9422 6.70578H11.4282C11.2774 6.70566 11.1306 6.6579 11.0086 6.5693C10.8866 6.4807 10.7957 6.3558 10.749 6.21246L9.66299 2.8708Z" stroke="white" strokeWidth="1.42989" strokeLinecap="round" strokeLinejoin="round"/>
                </svg>
            </div>
            <div className="text-2xl text-[#413F9B] font-bold">Score</div>
            <div className="text-3xl text-[#413F9B] font-bold">{Math.round(Number(isAdmin ? checkAdminScore : isSdr ? score.sdrScore : score.aeScore))}/100</div>
        </div>
    )
}

export default Score;