import { useMutation, useQueryClient } from "@tanstack/react-query";
import { deleteActiveUser } from "api/user";
import useToastStore from "components/shared/toast/toastStore";

export const useDeleteActiveUser = () => {
	const queryClient = useQueryClient();
	const { showToast } = useToastStore();
	return useMutation({
		mutationFn: deleteActiveUser,
		onSuccess: () => {
			showToast('Deletion complete and all  opportunities have been transfered!', {type: 'success', position: 'top'});
			queryClient.invalidateQueries({queryKey: ['active-users'], type: 'active'});
			queryClient.removeQueries({queryKey: ['active-users'], type: 'inactive'});
		},

	});
}