import { FC } from "react";

export const HomerunTextIcon: FC<{color: string}> = ({color}) => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width="116" height="22" viewBox="0 0 116 22" fill="none">
			<mask
				id="mask0_249_14227"
				// style="mask-type:luminance"
				maskUnits="userSpaceOnUse"
				x="0"
				y="0"
				width="116"
				height="22"
			>
				<path d="M115.602 0.827576H0.462036V21.5336H115.602V0.827576Z" fill="white" />
			</mask>
			<g mask="url(#mask0_249_14227)">
				<path
					d="M0 1H3.49639V9.42572C3.89761 8.64238 4.4899 8.04054 5.27324 7.62021C6.07569 7.18077 6.99277 6.96106 8.02449 6.96106C9.09443 6.96106 10.0306 7.20943 10.8331 7.70619C11.6546 8.18384 12.2851 8.85254 12.7246 9.71231C13.164 10.5721 13.3837 11.5656 13.3837 12.6928V21.6344H9.88732V13.438C9.88732 12.4445 9.60073 11.642 9.02756 11.0306C8.47348 10.4001 7.74746 10.0849 6.84948 10.0849C5.87508 10.0849 5.07263 10.4288 4.44213 11.1166C3.81163 11.8044 3.49639 12.6833 3.49639 13.7532V21.6344H0V1Z"
					fill={color}
				/>
				<path
					d="M23.3279 21.8637C21.8759 21.8637 20.5766 21.5484 19.4303 20.9179C18.2839 20.2683 17.386 19.3799 16.7364 18.2527C16.0868 17.1063 15.762 15.8262 15.762 14.4124C15.762 12.9985 16.0868 11.728 16.7364 10.6007C17.386 9.45438 18.2839 8.56595 19.4303 7.93546C20.5766 7.28586 21.8759 6.96106 23.3279 6.96106C24.7608 6.96106 26.0505 7.28586 27.1969 7.93546C28.3432 8.56595 29.2412 9.45438 29.8908 10.6007C30.5404 11.728 30.8652 12.9985 30.8652 14.4124C30.8652 15.8262 30.5404 17.1063 29.8908 18.2527C29.2412 19.3799 28.3432 20.2683 27.1969 20.9179C26.0505 21.5484 24.7608 21.8637 23.3279 21.8637ZM19.3157 14.4124C19.3157 15.7116 19.6787 16.772 20.4047 17.5935C21.1498 18.4151 22.1242 18.8258 23.3279 18.8258C24.5125 18.8258 25.4678 18.4151 26.1938 17.5935C26.9389 16.772 27.3115 15.7116 27.3115 14.4124C27.3115 13.1132 26.9389 12.0528 26.1938 11.2312C25.4678 10.4097 24.5125 9.9989 23.3279 9.9989C22.1242 9.9989 21.1498 10.4097 20.4047 11.2312C19.6787 12.0337 19.3157 13.0941 19.3157 14.4124Z"
					fill={color}
				/>
				<path
					d="M33.2441 7.19033H36.7692V9.45438C37.1513 8.69014 37.6958 8.08831 38.4027 7.64887C39.1287 7.19033 39.979 6.96106 40.9534 6.96106C42.0997 6.96106 43.055 7.19988 43.8192 7.67753C44.5835 8.13607 45.128 8.81433 45.4528 9.71231C45.7967 8.92897 46.3986 8.27937 47.2583 7.7635C48.1372 7.22854 49.1307 6.96106 50.2388 6.96106C51.2515 6.96106 52.1494 7.19033 52.9328 7.64887C53.7161 8.10741 54.318 8.74746 54.7383 9.56902C55.1586 10.3715 55.3688 11.279 55.3688 12.2916V21.6344H51.8724V13.352C51.8724 12.3203 51.624 11.4987 51.1273 10.8873C50.6305 10.2759 49.9618 9.97024 49.1211 9.97024C48.185 9.97024 47.4398 10.2759 46.8857 10.8873C46.3317 11.4987 46.0546 12.3203 46.0546 13.352V21.6344H42.5583V13.352C42.5583 12.3203 42.3099 11.4987 41.8131 10.8873C41.3164 10.2759 40.6477 9.97024 39.807 9.97024C38.8708 9.97024 38.1257 10.2855 37.5716 10.916C37.0366 11.5274 36.7692 12.3394 36.7692 13.352V21.6344H33.2441V7.19033Z"
					fill={color}
				/>
				<path
					d="M57.7428 14.3551C57.7428 13.0367 58.0103 11.8235 58.5452 10.7154C59.0802 9.58812 59.8826 8.68059 60.9526 7.99278C62.0225 7.30496 63.3122 6.96106 64.8215 6.96106C66.35 6.96106 67.6396 7.29541 68.6905 7.96412C69.7604 8.61372 70.5533 9.47349 71.0692 10.5434C71.585 11.5942 71.843 12.7215 71.843 13.9252C71.843 14.3455 71.8239 14.7467 71.7856 15.1288H61.1245C61.2583 16.2943 61.6786 17.2209 62.3855 17.9088C63.0924 18.5966 64 18.9405 65.1081 18.9405C66.0061 18.9405 66.7417 18.759 67.3148 18.396C67.888 18.033 68.2701 17.5075 68.4612 16.8197H71.9003C71.6328 18.3482 70.8781 19.571 69.6362 20.4881C68.3943 21.4052 66.885 21.8637 65.1081 21.8637C63.5987 21.8637 62.2804 21.5102 61.1532 20.8033C60.045 20.0964 59.1948 19.1698 58.6025 18.0234C58.0294 16.877 57.7428 15.6543 57.7428 14.3551ZM68.3179 12.8075C68.2415 11.8522 67.888 11.0784 67.2575 10.4861C66.6461 9.89382 65.8341 9.59768 64.8215 9.59768C62.7772 9.59768 61.5735 10.6676 61.2105 12.8075H68.3179Z"
					fill={color}
				/>
				<path
					d="M80.9222 10.1422C79.8523 10.1422 79.0594 10.5625 78.5435 11.4032C78.0468 12.2247 77.7984 13.3615 77.7984 14.8136V21.6344H74.2734V7.19033H77.7984V9.74097C78.1614 8.82388 78.6295 8.17428 79.2027 7.79216C79.795 7.39094 80.5592 7.19033 81.4954 7.19033H83.1863V10.1422H80.9222Z"
					fill={color}
				/>
				<path
					d="M98.5728 21.6344H95.0477V19.399C94.6465 20.1824 94.0542 20.7938 93.2709 21.2332C92.4875 21.6535 91.58 21.8637 90.5483 21.8637C88.9243 21.8637 87.6155 21.3383 86.622 20.2875C85.6476 19.2175 85.1604 17.8323 85.1604 16.1319V7.19033H88.6854V15.3868C88.6854 16.3803 88.9625 17.1923 89.5165 17.8228C90.0706 18.4342 90.7966 18.7399 91.6946 18.7399C92.6881 18.7399 93.4906 18.396 94.102 17.7081C94.7325 17.0203 95.0477 16.1415 95.0477 15.0715V7.19033H98.5728V21.6344Z"
					fill={color}
				/>
				<path
					d="M101.729 7.19033H105.254V9.42572C105.655 8.64238 106.247 8.04054 107.031 7.62021C107.814 7.18077 108.722 6.96106 109.753 6.96106C111.377 6.96106 112.677 7.49602 113.651 8.56595C114.644 9.61678 115.141 10.9924 115.141 12.6928V21.6344H111.616V13.438C111.616 12.4445 111.339 11.642 110.785 11.0306C110.231 10.4001 109.505 10.0849 108.607 10.0849C107.613 10.0849 106.801 10.4288 106.171 11.1166C105.56 11.8044 105.254 12.6833 105.254 13.7532V21.6344H101.729V7.19033Z"
					fill={color}
				/>
			</g>
		</svg>
	);
}

