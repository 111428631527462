import HomerunLogoIcon from "assets/icons/homerun-logo";
import { HomerunTextIcon } from "assets/icons/homerun-text";
import RegisterExpiredImg from "assets/img/register-expired.png";
import { Button } from "components/UI/buttons";
import { useNavigate } from "react-router-dom";

export const  FailedAzureLogin = () => {
    const navigate = useNavigate();
    return (
		<div className="p-6 h-full">
			<div className="flex gap-1 items-center">
				<HomerunLogoIcon />
				<HomerunTextIcon color="black" />
			</div>
            <div className="h-full flex flex-col justify-center items-center ">
                <div className="max-w-prose flex flex-col items-center justify-center text-center">
                <img src={RegisterExpiredImg} alt="expired-link" className="mb-6"></img>
                <h3 className="text-2xl font-medium mb-2"> Entered credentials are invalid </h3>
                <p className="text-base mb-2">
                Hi there, credentials are invalid or you are not invited by admin.
                </p>
                <p className="text-lg font-medium mb-6"> 
                   Go to login and try with correct credentials.
                </p>
                <Button type="button" variant="primary" className="w-full" onClick={() => navigate('/login')}> Go to login </Button>
                </div>
            </div>
		</div>
	);
}