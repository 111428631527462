import { useQuery } from "@tanstack/react-query";
import { getAEFields, getSDRFields } from "api/opportunity";


export const useSDRFields = (id: string, isSDR: boolean, isAdmin: boolean) => {
	return useQuery({
        queryKey: ['ae-fields'],
		queryFn: () =>  getSDRFields(id),
	});
};

export const useAEFields = (id: string, isAE: boolean, isAdmin: boolean) => {
	return useQuery({
        queryKey: ['sdr-fields'],
		queryFn: () =>  getAEFields(id),
	});
};