import emptyFilters from "assets/img/emptyFilters.png";
import { FC } from "react";

export const EmptyState: FC<{title?: string}> = ({title = 'content'}) => {
	return (
		<div className="flex items-center text-center flex-col py-24">
			<div className="max-w-sm">
				<img src={emptyFilters} alt="empty-filter" className="mb-6 mx-auto" />
				<p className="text-2xl font-medium mb-3"> No {title} yet</p>
				<p className="text-base font-regular mb-3">
				      We don’t have current {title} to show.
				</p>
			</div>
		</div>
	);
};
