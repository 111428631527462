function ShowPasswordIcon() {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width="20" height="21" viewBox="0 0 20 21" fill="none">
			<path
				d="M11.7678 12.6424C12.2366 12.1736 12.5 11.5377 12.5 10.8746C12.5 10.2116 12.2366 9.57571 11.7678 9.10687C11.2989 8.63803 10.663 8.37463 10 8.37463C9.33696 8.37463 8.70107 8.63803 8.23223 9.10687C7.76339 9.57571 7.5 10.2116 7.5 10.8746C7.5 11.5377 7.76339 12.1736 8.23223 12.6424C8.70107 13.1112 9.33696 13.3746 10 13.3746C10.663 13.3746 11.2989 13.1112 11.7678 12.6424Z"
				stroke="#16172C"
				strokeWidth="2"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M2.04834 10.8746C3.11001 7.49379 6.26917 5.04129 10 5.04129C13.7317 5.04129 16.89 7.49379 17.9517 10.8746C16.89 14.2555 13.7317 16.708 10 16.708C6.26917 16.708 3.11001 14.2555 2.04834 10.8746Z"
				stroke="#16172C"
				strokeWidth="2"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
}

export default ShowPasswordIcon;
