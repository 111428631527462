import {OpportunityFields} from "../../components/opportunities/opportunityFields";
import {useState} from "react";
// import {AeFieldDefinition} from "../account-details/opportunities/schemas/ae.schema";
import {SdrFieldDefinition} from "../account-details/opportunities/schemas/sdr.schema";

export const AndrejOverview = () => {
  const [value, setValue] = useState({
    "fields": [
      {
        "name": "pain_points",
        "value": "ssssss"
      },
      {
        "name": "impact",
        "value": "ssss"
      },
      {
        "name": "kpis",
        "value": "Nice nije"
      }
    ]
  });

  return <div className="p-24">
    <pre>
      {JSON.stringify(value, null, 10)}
    </pre>
    <OpportunityFields schema={SdrFieldDefinition} value={value} onChange={setValue}/>
  </div>
}
