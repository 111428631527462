import { useMutation, useQueryClient } from "@tanstack/react-query";
import { removeContact } from "api/contacts";
import useToastStore from "components/shared/toast/toastStore";

export const useDeleteContact = () => {
	const queryClient = useQueryClient();
	const { showToast } = useToastStore();
	return useMutation({
		mutationFn: removeContact,
		onSuccess: () => {
			showToast('Contact successfully removed', {type: 'success', position: 'top'});
			queryClient.invalidateQueries({ queryKey: ['opportunityById'], type: "active"});
		},
	});
}