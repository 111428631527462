import { FC } from "react";
import { OpportunityStatusProps } from "./opportunityStatusModel";
import classNames from "classnames";

export const OpportunityStatus:FC<OpportunityStatusProps> = ({type}) => {

    const name =  {
        ongoing: 'Ongoing',
        'closed won': 'Closed won',
        'closed lost': 'Closed lost',
    }

    const statusClasses = {
        ongoing: 'bg-primary text-white font-medium',
        'closed won': 'bg-green-100 text-black font-medium',
        'closed lost':  'bg-red-400 text-white font-medium',
    }
    return <div className={classNames('rounded-xl flex items-center leading-5 w-max px-3 text-center text-xs', statusClasses[type])}>
        {name[type]}
    </div>

}