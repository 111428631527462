import { Button } from "components/UI/buttons";
import { Action, TableProps } from "models/table";

export const HrTable = <T extends object, K extends keyof T>({ data, columns, actions, handleNavigate }: TableProps<T, K>) => {
    const handleClick = (event: React.MouseEvent, action: Action<T>, item: T) => {
	   event.stopPropagation();
       action.callback(item);
	}

	return (
		<div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-6">
			<div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
				<table className="min-w-full divide-y divide-neutral-200">
					<thead>
						<tr>
							{columns.map((column, columnIndex) => (
								<th
									scope="col"
									className="py-1 pl-4 pr-3 text-left text-xs font-semibold text-gray-700"
									key={`table-head-cell-${columnIndex}`}
								>
									{column.title}
								</th>
							))}
							{actions && <th scope="col" className="py-1 pl-4 pr-3 text-left text-xs font-semibold text-gray-700">
							</th>}
						</tr>
					</thead>
					<tbody className="bg-white">
						{data.map((item, itemIndex) => (
							<tr key={`table-body-${itemIndex}`} className="even:bg-gray-50 cursor-pointer" onClick={ handleNavigate ? () => handleNavigate(item) : () => {}}>
								{columns.map((column, columnIndex) => {
									return (
										<td
											key={`table-row-cell-${columnIndex}`}
											className="whitespace-nowrap px-3 py-2.5 text-sm text-gray-500"
										>
											{column.render ? column.render(column, item) : (item[column.key] as string)}
										</td>
									);
								})}
								{actions && <td className="whitespace-nowrap px-3 py-2.5 text-sm text-gray-500 ">
									<div className="flex justify-end gap-5">
									{actions.map((action, actionIndex) => (
									action.condition(item) ? <Button
											key={actionIndex}
											variant="transparent"
											type="button"
											className="!py-0"
											onClick={(event: React.MouseEvent) => handleClick(event, action, item)}
										>
											{action.icon}
										</Button> : null
									))}
									 </div>
								</td>}
							</tr>
						))}
					</tbody>
				</table>
			</div>
		</div>
	);
};
