import { ContactsProps } from "./contactsModel"
import Avatar from "components/shared/avatar";
import { HRPopover } from "components/shared/popover";
import { Button } from "components/UI/buttons";
import { CopyToClipboard, checkIsAdmin } from "utilis/helpers";
import CopyToClipboardIcon from "assets/icons/copy-to-clipboard";
import { HrMenu } from "components/UI/hrMenu";
import { EllipsisHorizontalIcon, PencilIcon, StarIcon, TrashIcon } from "@heroicons/react/24/outline";
import { StarIcon as StarIconSolid } from "@heroicons/react/24/solid";


interface Props {
    contact: ContactsProps
    handleItemClick: (actionType: string, contact: ContactsProps) => void;
    isMyOpportunity: boolean;
}

export const ContactListItem = ({contact, handleItemClick, isMyOpportunity}: Props) => {
    const isAdmin = checkIsAdmin();
    const menuItems = [
		{
			title: "Edit Contact",
			icon: <PencilIcon className="text-gray-600" width={20} height={20} />,
			actionType: "editContact",
		},
		{
			title: "Delete Contact",
			icon: <TrashIcon className="text-gray-600" width={20} height={20} />,
			actionType: "deleteContact",
		},
		{
			title: contact.isFavorite ? "Remove from favorite" : "Add to Favorite",
			icon: <StarIcon className="text-gray-600" width={20} height={20} />,
			actionType: "toggleFavorite",
		},
	];


    const handleMenuClick = (actionType: string) => {
        handleItemClick(actionType, contact);
    } 

    return (
        <div
            className="py-2 px-3 flex justify-between items-center odd:bg-gray-50"
            key={contact.email}
        >
            <div className="flex gap-3 items-center">
                <div className="relative flex">
                    <Avatar userName={`${contact.name}`} />
                    {contact.isFavorite && <StarIconSolid className="text-yellow-400 absolute -bottom-0.5 right-0" width={15} height={15} /> }
                </div>
                <div>
                    <div className="flex gap-2">
                        <p className="font-base font-medium">{contact.name}</p>
                    </div>
                    <p className="text-xs font-regular">
                        <span className="text-gray-400 mr-1"> Email:</span>
                        {contact.email}
                    </p>
                </div>
            </div>
            {!isAdmin && isMyOpportunity &&
            <div className="flex items-center gap-4">
                <HRPopover content="Copy to clipboard" openOnClick>
                    <Button
                        className="!p-0"
                        variant="icon"
                        type="button"
                        onClick={() => CopyToClipboard(contact.email)}
                    >
                        <CopyToClipboardIcon />
                    </Button>
                </HRPopover>
                <HrMenu
                    buttonIcon={<EllipsisHorizontalIcon className="h-6 w-6 stroke-2" aria-hidden="true" />}
                    handleMenuClick={handleMenuClick}
                    menuItems={menuItems}
                />
            </div>}
        </div>
    );

}