import { FC } from "react";
import { useParticipants } from "./useParticipants";
import Avatar from "components/shared/avatar";
import { HRPopover } from "components/shared/popover";
import { ParticipantsProps } from "./participantsModel";
import { ContactsProps } from "../opportunities/opportunity-details-components/main-details-widget/general-details/contacts-and-team/contacts/contactsModel";

export const Participants: FC<{ accountId: string }> = ({ accountId }) => {
	const { data } = useParticipants(accountId);
	const contacts = data?.contacts.filter((value: ContactsProps, index: number, self: ContactsProps[]) => {
		return index === self.findIndex(element => element.email.toLowerCase() === value.email.toLowerCase());
	}) || []

	const users = data?.users.filter((value: ParticipantsProps, index: number, self: ParticipantsProps[]) => {
		return index === self.findIndex(element => element.id === value.id);
	}) || []

	return (
		<>
			{data && (
				<div className="px-2 py-1 rounded-md w-full md:w-1/2 bg-gray-50">
						<div className="mb-2">
							<p className="text-sm font-bold mb-2"> Contacts </p>
							<div className="flex gap-1 items-cente flex-wrap">
								{contacts.length > 0 ?
								contacts.slice(0, 10).map((contact) => {
									return (
										<div key={contact.id}>
											<HRPopover  
												content={
                           							<div>
                           							    <div>{contact.name}</div>
                           							    <div>{contact.email}</div>
                           							    <div>{contact.role}</div>
                           							</div>}
											>
												<Avatar userName={contact.name} />
											</HRPopover>
										</div>
									);
								})
								:
								"No contacts added."
							}
								{contacts.length > 10 && (
									<div className="bg-primary text-white rounded-full w-[33px] h-[33px] mt-1 text-sm flex items-center justify-center">
										{`+${contacts.length - 10}`}
									</div>
								)}
							</div>
						</div>
						<div className="pt-2 border-t border-gray-30">
							<p className="text-sm font-bold mb-2"> Team </p>
							<div className="flex gap-1 items-center flex-wrap">
								{users.length > 0 ?
								users.slice(0, 10).map((user) => {
									const userName = `${user.firstName} ${user.lastName}, ${user.emailData.email}, ${user.role}`;
									return (
										<div key={user.id}>
											<HRPopover content={
                           							<div>
                           							    <div>{`${user.firstName} ${user.lastName}`}</div>
                           							    <div>{user.emailData.email}</div>
                           							    <div>{user.role}</div>
                           							</div>}>
												<Avatar userName={userName} />
											</HRPopover>
										</div>
									);
								})
								:
								"No team members added."
								}
								{users.length > 10 && (
									<div className="bg-primary text-white rounded-full w-[33px] h-[33px] mt-1 text-sm flex items-center justify-center">
										{`+${users.length - 10}`}
									</div>
								)}
							</div>
						</div>
				</div>
			)}
		</>
	);
};
