import { useMutation, useQueryClient } from "@tanstack/react-query";
import { addNewAccount } from "api/account";
import useToastStore from "components/shared/toast/toastStore";

export const useAddNewAccount = () => {
	const queryClient = useQueryClient();
	const { showToast } = useToastStore();
	return useMutation({
		mutationFn: addNewAccount,
		onSuccess: () => {
			showToast('Account successfully added', {type: 'success', position: 'top'});
			queryClient.invalidateQueries(['accounts-overview']);
		},
	});
}
