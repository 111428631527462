import { useMutation } from "@tanstack/react-query";
import { registerUser } from "api/auth";
import useToastStore from "components/shared/toast/toastStore";
import { createSearchParams, useNavigate } from "react-router-dom";

export const useRegister = () => {
    const navigate = useNavigate();
    const {showToast} = useToastStore();

	return useMutation({
		mutationFn: registerUser,
		onSuccess: () => {
			navigate({
				pathname: "login",
				search: createSearchParams({
					register: "success"
				}).toString()
			});
            navigate("/login");
		},
        onError: (error) => {
			showToast('You are not invited or invite email has expired!', {type: 'error', position: 'top'});
        }
	});
}