import { Disclosure, Transition } from "@headlessui/react";
import { FunnelIcon, XMarkIcon, ArrowPathIcon } from "@heroicons/react/24/outline";
import classNames from "classnames";
import { Button } from "components/UI/buttons";
import { FC } from "react";
import { getLocalStorage, setLocalStorage } from "utilis/storage";

export const Filter:FC<{children: JSX.Element, results: number, action: () => void, hasFilters: boolean}> = ({children, results, action, hasFilters}) => {
	const isOpen = getLocalStorage<boolean>('isFilterActive');
	const handleClose = (open: boolean) => {
		setLocalStorage('isFilterActive', open);
	}
	
	return (
		<Disclosure defaultOpen={isOpen! && hasFilters}>
			{({ close, open }) => (
				<div className={classNames({'shadow-hrShadow': open}, 'p-4')}>
                    <div className="relative"> 
                    { open ? <XMarkIcon onClick={() => {close(); handleClose(!open) }} className="h-5 w-5 cursor-pointer absolute right-0"> </XMarkIcon> :
					  <div className="flex gap-4 xs:ml-auto items-center">
							 {hasFilters &&  <Button
									type="button"
									key="notsubmit"
									variant="transparent"
									onClick={() => action()}
									className="flex-1 absolute right-0 !p-0 top-1"
								>
									<div className="flex items-center gap-2 w-max">
									<ArrowPathIcon className="h-4 w-4 text-primary-500" />
										Clear Filter</div>
								</Button> }
					</div>}
					<Disclosure.Button className="flex justify-between gap-2" onClick={() => handleClose(!open)}>
						<div className="text-base  flex items-center gap-2">
						<FunnelIcon className="h-4 w-4 text-primary-500" />  
						 <span className="text-primary-500 font-medium">Filter</span>
						 {hasFilters && <span className="ml-3"> Results ({results}) </span> }
						</div>
					</Disclosure.Button> 
                    </div>
					<Transition
						enter="transition duration-100 ease-out"
						enterFrom="transform scale-95 opacity-0"
						enterTo="transform scale-100 opacity-100"
						leave="transition duration-75 ease-out"
						leaveFrom="transform scale-100 opacity-100"
						leaveTo="transform scale-95 opacity-0"
						className="relative z-10"
					>
						<Disclosure.Panel>{children}</Disclosure.Panel>
					</Transition>
				</div>
			)}
		</Disclosure>
	);
};
