import { useMutation, useQueryClient } from "@tanstack/react-query";
import { deleteMeeting } from "api/meetings";
import useToastStore from "components/shared/toast/toastStore";

export const useDeleteMeeting = () => {
	const queryClient = useQueryClient();
	const { showToast } = useToastStore();
	return useMutation({
		mutationFn: deleteMeeting,
		onSuccess: () => {
			showToast('Meeting successfully removed', {type: 'success', position: 'top'});
			queryClient.invalidateQueries(['meetings']);
		},
	});
}