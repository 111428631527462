import classNames from "classnames";
import { extractFirstLetters } from "utilis/helpers";

interface IAvatarProps {
    imageSrc?: string;
    userName: string;
    width?: number;
    height?: number;
    textSize?: string;
}

const Avatar = ({imageSrc, userName, width= 32, height=32, textSize='text-xs'}: IAvatarProps) => {
    return (
        imageSrc ? 
        <img
          className="inline-block rounded-full"
          src={imageSrc}
          alt=""
        /> :
        <div className="relative inline-flex items-center justify-center bg-primary-200 border-2 border-primary-300 rounded-full" style={{width, height, minWidth: width}}>
          <div className={classNames("absolute top-0 left-0 right-0 bottom-0 font-bold flex items-center justify-center leading-[150%] text-primary-500", textSize)}>{extractFirstLetters(userName).toUpperCase()}</div>
        </div>
    )
};

export default Avatar;