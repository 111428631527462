import { Breadscrumb } from "components/shared/breadscrumb";
import { useState } from "react";
import { AddUsers } from "./add-users/addUsers";
import { ActiveUsersList } from "./active-users-list/activeUsersList";
import { PendingUsersList } from "./pending-users-list/pendingUsersList";
import { Sidebar } from "components/shared/sidebar";
import { Navigate, useSearchParams } from "react-router-dom";
import { checkIsAdmin } from "utilis/helpers";

export const AdminOverview = () => {
	const [open, setOpen] = useState<boolean>(false);
	const [searchParams, setSearchParams] = useSearchParams();
	const status = searchParams.get('userStatus'); 
	const [usersStatus, setUsersStatus] = useState<string>(status || 'active');
	const isAdmin = checkIsAdmin();



	if (!isAdmin) {
		return <Navigate to="/" replace={true} />
	}

	const toggleUsers = (userStatus: string) => {
		setSearchParams({userStatus}, {replace: true}); 
		setUsersStatus(userStatus)
	}
	

	return (
		<>
			<Sidebar />
			<main className="p-3 lg:ml-72 bg-gray-50 h-full">
				<div className="pb-5">
					<Breadscrumb title="All users" buttonTitle="Invite new Team members" action={setOpen} />
					<div className="flex my-3 bg-white">
						<div
							className={`py-2.5 px-5 font-medium cursor-pointer ${
								usersStatus === "active"
									? "bg-primary-100 text-primary-500 border-b-2 border-primary-500"
									: "text-gray-500"
							}`}
							onClick={() => toggleUsers('active')}
						>
							<p> Active </p>
						</div>
						<div
							className={`py-2.5 px-5 font-medium cursor-pointer ${
								usersStatus === "pending"
									? "bg-primary-100 text-primary-500 border-b-2 border-primary-500"
									: "text-gray-500"
							}`}
							onClick={() => {toggleUsers('pending')}}
						>
							<p> Pending Users </p>
						</div>
					</div>
					<div className="p-4 bg-white">
						{usersStatus === "active" ? <ActiveUsersList /> : <PendingUsersList />}
					</div>
				</div>
				<AddUsers open={open} setIsOpen={setOpen} />
			</main>
		</>
	);
};
