import { useMutation } from "@tanstack/react-query";
import { passwordReset } from "api/auth";
import { AxiosError } from "axios";
import useToastStore from "components/shared/toast/toastStore";
import { createSearchParams, useNavigate } from "react-router-dom";

export const useResetPassword = () => {
    const navigate = useNavigate();
	const {showToast} = useToastStore();
	return useMutation({
		mutationFn: passwordReset,
		onSuccess: (data) => {
			navigate({
				pathname: "/login",
				search: createSearchParams({
					resetPassword: "success"
				}).toString()
			});
		},
        onError: (error: AxiosError) => {
			if (error.response?.status === 404) {
				showToast('Password reset request not found', {type: 'error', position: 'top'})
			}
        }
	});
}