import { XMarkIcon } from "@heroicons/react/24/outline";
import { useField } from "formik";
import { FormTextInput } from "models/forms";
import React, { FC, useState } from "react";
import exlamation from "assets/img/exclamation.png";
import classNames from "classnames";


export const HrTagInput: FC<FormTextInput> = ({ label, field: inputField, form, ...props }) => {
    const [inputValue, setInputValue] = useState('');
    const [field, meta, helpers] = useField<string[]>(props);
  
    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      setInputValue(event.target.value);
    };
  
    const handleInputKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
      if (event.key === 'Enter' && inputValue.trim() !== '') {
        event.preventDefault();
        const newTags = [...field.value, inputValue.trim()];
        helpers.setValue(newTags);
        setInputValue('');
      }
    };

    const handleOnBlur = (event: React.ChangeEvent<HTMLInputElement>) => {
      if (inputValue.trim() !== '') {
        event.preventDefault();
        const newTags = [...field.value, inputValue.trim()];
        helpers.setValue(newTags);
        setInputValue('');
      }
    };
  
    const handleRemoveTag = (tag: string) => {
      const newTags = field.value.filter(t => t !== tag);
      helpers.setValue(newTags);
    };
  
    return (
      <>
      {label ? (
				<label
					className={`block text-sm font-normal leading-6 text-black-darker ${
						meta.touched && meta.error ? "text-red-600" : ""
					}`}
					htmlFor={props.id || props.name}
				>
					{label}
				</label>
			) : (
				""
			)}
      <div className={classNames("flex flex-wrap items-center gap-2 border rounded p-2",
        {"border-red-600": meta.touched && meta.error, "border-gray-300": !meta.touched && !meta.error})}>
        {field?.value.map((tag, index) => (
          <div key={index} className="bg-gray-100 px-2 flex items-center rounded-sm text-gray-300 text-sm">
            {tag}
            <button
              type="button"
              onClick={() => handleRemoveTag(tag)}
              className="ml-2 text-gray-300"
            >
              <XMarkIcon className="text-gray-300 h-4 w-4"/>
            </button>
          </div>
        ))}
        <input
          {...field}
          type="text"
          value={inputValue}
          onChange={handleInputChange}
          onBlur={handleOnBlur}
          onKeyDown={handleInputKeyDown}
          className={classNames("flex-1 outline-none sm:text-sm sm:leading-[1.875rem] bg-no-repeat bg-[right_2px_bottom_4px]")}
        
          style={{ backgroundImage: meta.touched && meta.error ? `url(${exlamation})` : "" }}
          placeholder={props.placeholder}
        />
      </div>
      {meta.touched && meta.error ? <div className="error text-red-600 text-xs">{meta.error}</div> : null}
      </>
    );
  };