import { useMutation } from "@tanstack/react-query";
import { loginUser } from "api/auth";
import { AxiosError } from "axios";
import useToastStore from "components/shared/toast/toastStore";
import { useNavigate } from "react-router-dom";
import { setLocalStorage } from "utilis/storage";

export const useLogin = () => {
    const navigate = useNavigate();
    const {showToast} = useToastStore();

	return useMutation({
		mutationFn: loginUser,
		onSuccess: (data) => {            
            if (data?.accessToken) {
                setLocalStorage<string>('accessToken', data.accessToken)
            }
            if (data?.refreshToken) {
                setLocalStorage<string>('refreshToken', data.refreshToken)
            }
            navigate("/")
		},
        onError: (error: AxiosError) => {
            if (error.response?.status === 403) {
            return showToast('User is blocked from accessing the platform!', {type: 'error', position: 'top'});
            }
            showToast('Entered credentials are invalid, please try again!', {type: 'error', position: 'top-right'});
        }
	});
}