import CloseIcon from "assets/icons/close";
import { Button } from "components/UI/buttons";
import { Dispatch, FC, SetStateAction, useState } from "react";
import { Modal } from "components/shared/modal";
import { UserIcon } from "@heroicons/react/24/outline";
import { useAddNewAccount } from "./useAddNewAccount";
import { Spinner } from "components/shared/spinner";
import { useSalesForceAccounts } from "./useSalesForceAccounts";

import InputSearchDropdown from "components/shared/inputSearchDropdown";

import * as Yup from "yup";
import { Form, Formik } from "formik";
import { HrSelect } from "components/forms/select";
import { useIndustries } from "hooks/accounts/useIndustries";
import { HrTextInput } from "components/forms/textInput";
import { SalesForceAccount } from "../accountsOverviewModel";
import { SelectOptionsData } from "models/forms";
import { InputDisabledOnly } from "components/forms/inputDisabledOnly";

export const AddNewAccount: FC<{ open: boolean; setIsOpen: Dispatch<SetStateAction<boolean>> }> = ({ open, setIsOpen }) => {
    const { mutate: addNewAccount } = useAddNewAccount();
	const [searchTerm, setSearchTerm] = useState('');
	const { data, isFetching } = useSalesForceAccounts(searchTerm, {limit: 5, currentPage: 1});
	const [selectedAccount, setSelectedAccount] = useState<SalesForceAccount | null>(null);
	
	const values = { 
		industryId: selectedAccount?.Industry ? selectedAccount.Industry : "", 
		linkedInUrl: selectedAccount?.Company_Linkedin__c ? selectedAccount.Company_Linkedin__c : "" 
	};
	
	const industries = useIndustries();
	const onSubmit = (values: {industryId?: string; linkedInUrl?: string;}) => {
		if(selectedAccount?.Industry) delete values.industryId
		if(!values.linkedInUrl) delete values.linkedInUrl
		selectedAccount?.Id && addNewAccount({...values, externalId: selectedAccount?.Id});
		handleClose();
	};

	const handleClose = () => {
		setIsOpen(false);
		setSelectedAccount(null);
	};

	const handleOnChange = (e: string) => {
		setSearchTerm(e);
	};

	const handleAccount = (option: SelectOptionsData) => {
		const account = data?.rows.find(acc => acc.Id === option.id);
		account && setSelectedAccount(account);
	}

	const validation =  Yup.object({
		industryId: Yup.string(),
		// industry: Yup.string().required(selectedOpportunity ? "Industry can not be empty" : "Select Opportunity"),
		linkedInUrl: Yup.string(),
	});

	return (
		<>
			<Modal open={open} setIsOpen={setIsOpen}>
				<div className="flex justify-between stify-between items-center mb-8">
					<div className="flex items-center gap-3">
						<div className="rounded-full p-3 bg-primary-100">
							<UserIcon className="h-4 w-4 text-primary-500"> </UserIcon>
						</div>
						<h2 className="text-xl font-medium"> Add New Account </h2>
					</div>
					<button onClick={handleClose} className="h-full">
						<CloseIcon />
					</button>
				</div>
				<div>
					<div className="mb-5">
						<InputSearchDropdown 
							placeholder="Search Accounts"
							label="Accounts"
							name='externalId'
							options={data?.rows.map(item => ({...item, name: item.Name, id: item.Id, isDisabled: item.isConnected})) || []}
							searchHandler={handleOnChange}
							searchTerm={searchTerm}
							loading={searchTerm !== "" ? isFetching : false}
							onChange={(e) => handleAccount(e)}
						/>
					</div>
					<Formik initialValues={values} validationSchema={validation} onSubmit={onSubmit}>
						{({ isSubmitting, values }) => (
							<Form>
								<div>
									{/* <div className="mb-5">
										<HrTextInput label="Industry" disabled={true} name='industry' type="text" placeholder="Industry" />
									</div> */}
									{selectedAccount?.Industry ?
										<div className="mb-5">
											<InputDisabledOnly 
												value={selectedAccount.Industry} 
												label={"Industry"}
										/>
										</div>
										:
										<div className="mb-5">
											<HrSelect
												placeholder={selectedAccount ? "Industry is not assigned" : "Select Account First"}
												label="Industry"
												name='industryId'
												disabled={!selectedAccount}
												options={industries?.data?.rows.map(industry => ({id: industry.id, name: industry.name})) || []}
											/>
										</div>
									}
									<div className="mb-5">
										<HrTextInput
											placeholder={selectedAccount ? "Add LinkedIn Url" : "Select Account First"}
											label="LinkedIn Url"
											type={"text"}
											name='linkedInUrl'
											disabled={!selectedAccount}
										/>
									</div>
								</div>
								<div className="flex gap-4 flex-wrap">
									<Button type="button" variant="secondary" onClick={handleClose} className="flex-1">
										Cancel
									</Button>
									<Button type="submit" variant="primary" className="flex-1">
										{isSubmitting ? <Spinner className="h-5 w-5 fill-gray-100" /> : 'Add account'}
									</Button>
								</div>
							</Form>
						)}
					</Formik>
				</div>
				{/* <div className="flex gap-4">
					<Button type="button" variant="secondary" onClick={handleClose} className="flex-1">
						Cancel
					</Button>
					<Button onClick={onSubmit} type="submit" variant="primary" className="flex-1" disabled={isLoading}>
					{isLoading ? <Spinner className="h-5 w-5 fill-gray-100" /> : 'Add account'}
					</Button>
				</div> */}
			</Modal>
		</>
	);
};
