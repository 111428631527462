import HomerunLogoIcon from "assets/icons/homerun-logo";
import { HomerunTextIcon } from "assets/icons/homerun-text";
import ellipse from "assets/img/ellipse.png";
import resetPasswordFrame from "assets/img/resetPasswordFrame.png";
import { Outlet, useLocation } from "react-router-dom";

const Auth = () => {
	const location = useLocation();
	const isPathNameReset = location.pathname === "/reset" || location.pathname === "/forgotPassword";
	return (
		<>
			<div className="p-6 absolute top-0 left-0">
				<div className="flex gap-1 flex-row items-center">
					<HomerunLogoIcon />
					<HomerunTextIcon color="black" />
				</div>
			</div>
			<div className="flex min-h-full flex-1">
				<div className="flex flex-1 flex-col justify-center px-4 py-14 sm:px-6 lg:flex-none lg:px-28 lg:basis-2/4">
					<div className="mx-auto w-full max-w-lg xl:w-128">
						<Outlet />
					</div>
				</div>
				<div className="relative hidden w-0 flex-1 lg:block bg-primary-500 bg-[center_top] bg-no-repeat" style={{ backgroundImage: isPathNameReset ? `url(${resetPasswordFrame})` : '' }}>
					<div className="h-full pt-20">
					 {!isPathNameReset && <> 
						<div className="text-center">
							<h2 className="text-4xl text-white font-bold mb-5"> Welcome to Homerun</h2>
							<p className="text-sm text-white"> Empower Your Sales Success: </p>
							<p className="text-sm text-white font-medium"> Get Prepared. Win. Repeat </p>
						</div>
						<div
							className="bg-no-repeat flex justify-center items-center bg-[center_center] p-28 mt-24"
							style={{ backgroundImage: `url(${ellipse})` }}
						>
							<HomerunLogoIcon width={200} height={200} />
						</div>
					  </> }
					</div>
				</div>
			</div>
		</>
	);
};

export default Auth;
