export const PencilIcon = () => {
    return <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M12.8996 4.32706L15.824 7.25148M14.1401 3.0865C14.5279 2.6987 15.0539 2.48083 15.6024 2.48083C16.1508 2.48083 16.6768 2.6987 17.0646 3.0865C17.4524 3.4743 17.6702 4.00028 17.6702 4.54871C17.6702 5.09715 17.4524 5.62312 17.0646 6.01092L5.67785 17.3976H2.7832V14.4434L14.1401 3.0865Z" stroke="#6A67FE" strokeWidth="1.65408" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
}

export const TrashIcon = () => {
    return <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M8.42148 9.09741V14.0597M11.7296 9.09741V14.0597M3.45923 5.78925H16.6919M15.8649 5.78925L15.1478 15.8312C15.1181 16.2485 14.9314 16.6391 14.6252 16.9242C14.3191 17.2093 13.9162 17.3678 13.4979 17.3678H6.65326C6.2349 17.3678 5.83207 17.2093 5.52591 16.9242C5.21976 16.6391 5.03302 16.2485 5.00332 15.8312L4.28627 5.78925H15.8649ZM12.5567 5.78925V3.30812C12.5567 3.08878 12.4696 2.87841 12.3145 2.72331C12.1594 2.56821 11.949 2.48108 11.7296 2.48108H8.42148C8.20213 2.48108 7.99177 2.56821 7.83667 2.72331C7.68157 2.87841 7.59444 3.08878 7.59444 3.30812V5.78925H12.5567Z" stroke="#FF6249" strokeWidth="1.65408" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
}

export const OngoingIcon = () => {
    return(
        <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="32" height="32" rx="16" fill="#6B67FE"/>
            <path d="M17 11H25M25 11V19M25 11L17 19L13 15L7 21" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>  
    )
}

export const ClosedWonIcon = () => {
    return(
        <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="32" height="32" rx="16" fill="#B8EB4B"/>
            <path d="M18 14H22.764C23.1049 14 23.4401 14.0871 23.7378 14.2531C24.0355 14.4191 24.2859 14.6584 24.4651 14.9484C24.6444 15.2383 24.7465 15.5692 24.7619 15.9098C24.7773 16.2503 24.7054 16.5891 24.553 16.894L21.053 23.894C20.8869 24.2265 20.6314 24.5061 20.3152 24.7014C19.999 24.8968 19.6347 25.0002 19.263 25H15.246C15.083 25 14.92 24.98 14.761 24.94L11 24M18 14V9C18 8.46957 17.7893 7.96086 17.4142 7.58579C17.0391 7.21071 16.5304 7 16 7H15.905C15.405 7 15 7.405 15 7.905C15 8.619 14.789 9.317 14.392 9.911L11 15V24M18 14H16M11 24H9C8.46957 24 7.96086 23.7893 7.58579 23.4142C7.21071 23.0391 7 22.5304 7 22V16C7 15.4696 7.21071 14.9609 7.58579 14.5858C7.96086 14.2107 8.46957 14 9 14H11.5" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    )
}

export const ClosedLostIcon = () => {
    return(
        <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="32" height="32" rx="16" fill="#FF8570"/>
            <path d="M20.999 8L17.239 7.06C17.0804 7.02025 16.9175 7.0001 16.754 7H12.736C12.3645 7.00001 12.0004 7.10349 11.6844 7.29884C11.3684 7.49419 11.1131 7.77369 10.947 8.106L7.44702 15.106C7.29465 15.4109 7.22274 15.7497 7.23812 16.0902C7.2535 16.4308 7.35566 16.7617 7.53489 17.0516C7.71412 17.3416 7.96449 17.5809 8.26221 17.7469C8.55994 17.9129 8.89515 18 9.23602 18H14H16M20.999 8L21 17L17.608 22.088C17.211 22.683 17 23.381 17 24.096C17 24.595 16.595 25 16.095 25H15.999C15.4686 25 14.9599 24.7893 14.5848 24.4142C14.2097 24.0391 13.999 23.5304 13.999 23V18M20.999 8H23C23.5304 8 24.0392 8.21071 24.4142 8.58579C24.7893 8.96086 25 9.46957 25 10V16C25 16.5304 24.7893 17.0391 24.4142 17.4142C24.0392 17.7893 23.5304 18 23 18H20.5" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    )
}

export const OpportunityIcon = () => {
    return (
        <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M8.0525 14.7784H11.9467M10 3.11169V3.94503M15.3033 5.30836L14.7142 5.89753M17.5 10.6117H16.6667M3.33333 10.6117H2.5M5.28583 5.89753L4.69667 5.30836M7.05333 13.5584C6.47072 12.9756 6.074 12.2332 5.91331 11.4249C5.75263 10.6167 5.83521 9.779 6.15061 9.0177C6.466 8.25641 7.00006 7.60573 7.68524 7.14794C8.37042 6.69015 9.17596 6.4458 10 6.4458C10.824 6.4458 11.6296 6.69015 12.3148 7.14794C12.9999 7.60573 13.534 8.25641 13.8494 9.0177C14.1648 9.779 14.2474 10.6167 14.0867 11.4249C13.926 12.2332 13.5293 12.9756 12.9467 13.5584L12.49 14.0142C12.2289 14.2753 12.0218 14.5853 11.8806 14.9265C11.7393 15.2676 11.6666 15.6333 11.6667 16.0025V16.445C11.6667 16.8871 11.4911 17.311 11.1785 17.6235C10.866 17.9361 10.442 18.1117 10 18.1117C9.55797 18.1117 9.13405 17.9361 8.82149 17.6235C8.50893 17.311 8.33333 16.8871 8.33333 16.445V16.0025C8.33333 15.2567 8.03667 14.5409 7.51 14.0142L7.05333 13.5584Z" stroke="#4F3CB9" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
    )
}