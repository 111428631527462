import { Menu, Transition } from "@headlessui/react";
import { EllipsisVerticalIcon } from "@heroicons/react/24/outline";
import { DropdownMenu } from "models/dropdownMenu";
import { Fragment } from "react";


interface Props {
	menuItems: DropdownMenu[];
	handleMenuClick: (userId: string) => void;
	buttonIcon?: JSX.Element,
}

export const HrMenu = ({ menuItems, handleMenuClick, buttonIcon } : Props) => {
	const handleMenuData = (action: string) => {
		handleMenuClick(action);
	};

	return (
		<>
			<Menu as="div" className="relative inline-block text-left">
				<div>
					<Menu.Button className="flex items-center rounded-full text-gray-400 hover:text-gray-600">
						<span className="sr-only">Open options</span>
						{buttonIcon ? buttonIcon : <EllipsisVerticalIcon className="h-7 w-7" aria-hidden="true" /> }
					</Menu.Button>
				</div>

				<Transition
					as={Fragment}
					enter="transition ease-out duration-100"
					enterFrom="transform opacity-0 scale-95"
					enterTo="transform opacity-100 scale-100"
					leave="transition ease-in duration-75"
					leaveFrom="transform opacity-100 scale-100"
					leaveTo="transform opacity-0 scale-95"
				>
					<Menu.Items className="absolute right-0 z-10 mt-2 w-max origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
						<div className="py-1">
							{menuItems.map((menuItem) => (
								menuItem.condition !== false && <Menu.Item key={menuItem.title}>
									{({ active }) => (
										<button
											onClick={() => handleMenuData(menuItem.actionType)}
											className={`${
												active ? "bg-gray-100 text-gray-900" : "text-gray-700"
											} px-4 py-2 text-sm flex items-center gap-2 w-full`}
										>
											{menuItem.icon} <span className="text-sm"> {menuItem.title} </span>
										</button>
									)}
								</Menu.Item>
							))}
						</div>
					</Menu.Items>
				</Transition>
			</Menu>
		</>
	);
};
