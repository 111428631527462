import { FilterProps } from "models/filter";
import { PaginationApiProps } from "models/pager";
import { useUserStore } from "store/userStore";

export const checkIsAdmin = () => {
	const user = useUserStore.getState().user;
	if (user) {
		const isAdmin = user.assignedRoles.some(({ role }) => role.name === "ADMIN");
		return isAdmin;
	}

	return null;
};

export const checkIsAE = () => {
	const user = useUserStore.getState().user;
	if (user) {
		const isAE = user.assignedRoles.some(({ role }) => role.name === "AE");
		return isAE;
	}

	return null;
};

export const checkIsSDR = () => {
	const user = useUserStore.getState().user;
	if (user) {
		const isSDR = user.assignedRoles.some(({ role }) => role.name === "SDR");
		return isSDR;
	}

	return null;
};

export const hasSpecialChars = (str: string) => {
	const specialChars = /[`!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?~]/;
	return specialChars.test(str);
};

export const hasUpperCase = (str: string) => {
	return str !== str.toLowerCase();
};

export const hasLowerCase = (str: string) => {
	return str !== str.toUpperCase();
};


export const mapFilters = (filters: FilterProps | undefined) => {
	const selectFiltersList = ['role_id', 'industryId', 'accountId', 'status', 'sdrId', 'aeId', 'opportunityId'];
	let mappedFilters = {};
	if (filters) {
		Object.entries(filters).map((element) => {
			if (element[1]) {
				let data: { [key: string]: { like?: string; is?: string | string[] } } = {};
				if (selectFiltersList.includes(element[0])) {
					data[element[0]] = { is: element[1] };
				} else {
					data[element[0]] = { like: `%${element[1]}%` };

				}
				mappedFilters = {
					...mappedFilters,
					...data,
				};
			}
			return null;
		});
	}
	return {filters: mappedFilters};
};

export const mapPaginationData = (paginationData: PaginationApiProps) => {
   const offset = paginationData.limit * (paginationData.currentPage - 1);
   return {limit: paginationData.limit, offset}
}

export const extractFirstLetters = (str: string) => {
	const words = str.split(' ');
	let result = '';
	if (words.length === 1) {
		result = words[0].slice(0, 2);
	} else {
		for (const [i, word] of words.entries()) {
			if (i > 1) {
				break;
			}
			if (word.length > 0) {
			  result += word[0];
			}
		  }
	}

	return result;
}


export const CopyToClipboard = (value: string) => {
	return navigator.clipboard.writeText(value);
}
export const removeKeys = <T extends Record<string, any>>(
	obj: T,
	keysToRemove: string[]
  ): { [key: string] : string; } => {
	const newObj: { [key: string]: string; } = { ...obj };
  
	for (const key of keysToRemove) {
	  delete newObj[key];
	}
  
	return newObj;
};

// export const extractFirstLetters = (str: string) => {
//    const word = str.slice(0, 2);
//    return word;
// }


// export const removeEmptyValues = <T extends object, K extends keyof T>(values: MyProps<T, K>) => {
// 	const newValues = {...values.data};
//     Object.keys(newValues).forEach(key => {
// 		if (Array.isArray(newValues[key])) {
// 			return newValues[key].forEach((item: {[key: string]: string}) => {
// 				Object.keys(item).forEach(childKey => {
// 					if (item[childKey] === '' || item[childKey] === null) {
// 						delete item[childKey];
// 				    }
// 				})
// 				newValues[key] =  newValues[key].filter((element: {[key: string]: string}) => {
// 					if (Object.keys(element).length !== 0) {
// 					  return true;
// 					}
				  
// 					return false;
// 				  });
// 			})
		
// 		} else {
// 			if (newValues[key] === '' || newValues[key] === null) {
// 				delete newValues[key];
// 		 }
// 		}
// 		});
// 		return newValues;
// }
