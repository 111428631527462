import { Button } from "components/UI/buttons";
import { HrTextInput } from "components/forms/textInput";
import { Form, Formik, FormikHelpers } from "formik";
import * as Yup from "yup";
import { LoginData } from "./loginModel";
import { useEffect, useState } from "react";
import { ShowPassword } from "components/shared/showPassword";
import { useLogin } from "./useLogin";
import { useNavigate, useSearchParams } from "react-router-dom";
import useToastStore from "components/shared/toast/toastStore";

const Login = () => {
	const navigate = useNavigate();
	let [searchParams, setSearchParams] = useSearchParams();
	const initialValues: LoginData = { email: "", password: "" };
	const [showPassword, setShowPassword] = useState(false);
	const { mutate: login } = useLogin();
	const {showToast} = useToastStore();

	const handleNavigate = () => {
       navigate('/forgotPassword')
	};

	const onSubmit = (values: LoginData, helpers: FormikHelpers<LoginData>) => {
		login(values, {onError: () => {
		}});
	};

	const formValidation = Yup.object({
		email: Yup.string().email("Invalid email address").required("Email can not be empty"),
		password: Yup.string().required("Password can not be empty"),
	});

	const loginUserViaAzure = () => {
		const url = process.env.REACT_APP_AZURE_LOGIN!;
		window.location.href = url;
	}

	useEffect(() => {
	if (searchParams.get('resetPassword') === 'success') {
        showToast('Reset password success!', {type: 'success', position: 'top'});
		searchParams.delete('resetPassword');
		setSearchParams(searchParams)
	}
	if (searchParams.get('register') === 'success') {
        showToast('Register success!', {type: 'success', position: 'top'});
		searchParams.delete('register');
		setSearchParams(searchParams)
	}
	// eslint-disable-next-line
	}, [])

	return (
		<>
			<div className="mb-6">
				<h3 className="text-2xl text-black font-medium"> Log in </h3>
			</div>
			<Button variant="secondary" type="button" className="w-full mb-4 py-2.5 group hover:!bg-primary-500 hover:!text-white" onClick={() => {loginUserViaAzure()}}>
			<svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 24 25" fill="none">
			<path
				d="M14.613 6.32736V10.4351L16.05 11.3389C16.1019 11.3503 16.1557 11.3503 16.2075 11.3389L22.386 7.17336C22.3815 6.9712 22.3076 6.77675 22.1766 6.62269C22.0456 6.46863 21.8656 6.36434 21.6668 6.32736H14.613Z"
				fill="#0072C6"
				className="group-hover:fill-white"

			/>
			<path
				d="M14.613 11.9674L15.9233 12.8674C15.9846 12.9047 16.0551 12.9245 16.1269 12.9245C16.1987 12.9245 16.2692 12.9047 16.3305 12.8674C16.1055 13.0031 22.3853 8.83386 22.3853 8.83386V16.3834C22.4017 16.5363 22.3844 16.691 22.3348 16.8367C22.2851 16.9823 22.2042 17.1153 22.0978 17.2264C21.9913 17.3375 21.8619 17.4239 21.7185 17.4798C21.5752 17.5356 21.4213 17.5594 21.2678 17.5496H14.6123L14.613 11.9674ZM7.83004 10.0736C7.61233 10.0726 7.3984 10.1304 7.21091 10.2411C7.02342 10.3517 6.86936 10.511 6.76504 10.7021C6.48012 11.2086 6.34342 11.7851 6.37054 12.3656C6.34093 12.9449 6.47782 13.5206 6.76504 14.0246C6.86892 14.2078 7.01919 14.3605 7.20076 14.4672C7.38232 14.574 7.58879 14.631 7.7994 14.6327C8.01001 14.6344 8.21736 14.5806 8.40059 14.4768C8.58383 14.3729 8.73651 14.2227 8.84329 14.0411C9.12737 13.5394 9.26143 12.9667 9.22954 12.3911C9.26207 11.7974 9.13244 11.206 8.85454 10.6804C8.75523 10.4955 8.60731 10.3413 8.42676 10.2344C8.2462 10.1274 8.03987 10.0718 7.83004 10.0736Z"
				fill="#0072C6"
				className="group-hover:fill-white"

			/>
			<path
				d="M1.61475 4.24087V20.3111L13.8397 22.8746V1.87462L1.61475 4.24087ZM9.79575 14.9929C9.56706 15.3153 9.26298 15.5768 8.91002 15.7547C8.55706 15.9327 8.16593 16.0215 7.77075 16.0136C7.38551 16.0205 7.00431 15.9342 6.65958 15.7621C6.31486 15.59 6.01679 15.3371 5.79075 15.0251C5.25464 14.2766 4.98645 13.3693 5.0295 12.4496C4.98389 11.4848 5.25692 10.5319 5.8065 9.73762C6.03807 9.40907 6.34692 9.14253 6.70582 8.96152C7.06472 8.7805 7.46264 8.69056 7.8645 8.69962C8.24695 8.69194 8.62543 8.77827 8.96673 8.95101C9.30803 9.12375 9.60172 9.37763 9.822 9.69037C10.3527 10.4551 10.6164 11.3736 10.572 12.3034C10.6188 13.2612 10.3457 14.2073 9.79575 14.9929Z"
				fill="#0072C6"
				className="group-hover:fill-white"

			/>
		</svg>
				<p className="text-base ml-1"> Log in with Outlook </p>
			</Button>
			<div className="relative">
				<div className="absolute inset-0 flex items-center text-gray-100" aria-hidden="true">
					<div className="w-full border-t border-gray-200" />
				</div>
				<div className="relative flex justify-center text-sm font-normal leading-6">
					<span className="bg-white px-6 text-gray-300 text-base">or continue with email</span>
				</div>
			</div>
			<div className="mt-0">
				<Formik initialValues={initialValues} validationSchema={formValidation} onSubmit={onSubmit} enableReinitialize = {true}>
					{({ errors }) => (
						<Form className="space-y-6">
							<div>
								<HrTextInput label="Email" name="email" unfreezeinput autoComplete="email" type="email" placeholder="Enter email" />
							</div>
							<div className="relative">
								<HrTextInput
									label="Password"
									name="password"
									type={showPassword ? "text" : "password"}
									placeholder="Enter password"
									unfreezeinput
								/>
								<ShowPassword hasError={errors.password} showPassword={showPassword} togglePassword={setShowPassword} />
							</div>

							<div className="flex sm:flex-row flex-col">
								<Button type="submit" variant="primary" className="grow-2">
									Log in
								</Button>
								<button
									type="button"
									onClick={handleNavigate}
									className="font-semibold leading-6 px-4 text-center sm:px-6 lg:px-4 xl:px-10 py-3 text-primary-500 hover:text-default"
								>
									Forgot password?
								</button>
							</div>
						</Form>
					)}
				</Formik>
			</div>
		</>
	);
};

export default Login;
