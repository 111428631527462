import { getActiveUsers } from "api/user";
import { useQuery } from "@tanstack/react-query";
import { FilterProps } from "models/filter";
import { PaginationApiProps } from "models/pager";


export const useActiveUsers = (filters: FilterProps = {}, paginationData: PaginationApiProps = {limit: 10, currentPage: 1}) => {
	return useQuery({
        queryKey: ['active-users', filters, paginationData],
		queryFn: () =>  getActiveUsers(filters, paginationData),
		keepPreviousData: true
	});
};