import React, { FC, useState } from "react";

interface Props {
	openOnClick?: boolean;
	content: string | React.ReactNode;
	children: React.ReactNode;
}

export const HRPopover = ({ content, children, openOnClick }: Props) => {
	const [isVisible, setIsVisible] = useState(false);

	const handleOnClick = () => {
		setIsVisible(true);

		setTimeout(() => {
			setIsVisible(false);
		}, 1200);
	};

	const htmlContent = (
		<div className=" bg-white text-sm rounded-lg shadow-md p-2 w-max absolute z-10 bottom-full left-1/2 transform -translate-x-1/2 mb-2">
			{content}
			<div className="after:content-[''] after:absolute after:-bottom-2 after:left-1/2 after:transform after:-translate-x-1/2 after:border-4 after:border-t-white after:border-l-transparent after:border-r-transparent after:border-b-transparent"></div>
		</div>
	);

	return (
		<>
			{!openOnClick ? (
				<div onMouseEnter={() => setIsVisible(true)} onMouseLeave={() => setIsVisible(false)} className="relative">
					{children}
					{isVisible && htmlContent}
				</div>
			) : (
				<div onClick={handleOnClick} className="relative">
					{children}
					{isVisible && htmlContent}
				</div>
			)}
		</>
	);
};
