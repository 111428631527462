import { MeetingProps } from "../meetingsModel";
import { DocumentTextIcon, PencilIcon, PhoneXMarkIcon, TrashIcon, XMarkIcon, EllipsisHorizontalIcon, ClockIcon, CalendarDaysIcon, UserIcon  } from "@heroicons/react/24/outline";
import { MeetingStatus } from "../meeting-status/meetingStatus";
import { format } from "date-fns";
import { HrMenu } from "components/UI/hrMenu";
import { useState } from "react";
import { MeetingParticipants } from "../meeting-participants/meetingParticipants";

interface Props {
	meeting: MeetingProps;
	readonly?: boolean;
	handleMenuClick: (actionType: string, meeting: MeetingProps) => void;
}

export const MeetingListItem = ({ meeting, handleMenuClick, readonly = false }: Props) => {
	const [isOpen, setIsOpen] = useState(false);
	const menuItems = [
		{
			title: "Edit meeting",
			icon: <PencilIcon className="h-4 w-4 text-gray-200" />,
			actionType: "editMeeting",
			condition: meeting.status === 'upcoming'
		},
		{
			title: "Cancel meeting",
			icon:   <XMarkIcon className="h-4 w-4 text-gray-200" />,
			actionType: "cancelMeeting",
			condition:  meeting.status === 'upcoming'
		},
		{
			title: "Delete meeting",
			icon: <TrashIcon className="h-4 w-4 text-gray-200" />,
			actionType: "deleteMeeting",
			condition: meeting.status !== 'ongoing'
		},
		{
			title: "End meeting",
			icon:  <PhoneXMarkIcon className="h-4 w-4 text-gray-200" />,
			actionType: "endMeeting",
			condition: meeting.status === 'ongoing'
		},
		{
			title: "View note",
			icon:   <DocumentTextIcon className="h-4 w-4 text-gray-200" />,
			actionType: "endMeeting",
			condition: meeting.status === 'finished'
		},
	];



	const handleItemClick = (actionType: string) => {
		handleMenuClick(actionType, meeting);
	};

	return (
		<> 
		<div className="p-2.5 bg-gray-70 rounded-md mb-2.5">
			<div className="flex justify-between gap-2 mb-1">
				<MeetingStatus type={meeting.status} />
				{!readonly && <HrMenu 
				buttonIcon={<EllipsisHorizontalIcon className="h-6 w-6 stroke-2" aria-hidden="true" />} 
				handleMenuClick={handleItemClick}
				menuItems={menuItems} 
				/> }
			</div>
			<h3 className="text-sm font-medium mb-1"> {meeting.title} </h3>
			<div className="flex justify-between items-center gap-2 mb-2">
				<div className="flex gap-5">
				<p className="text-xs font-medium text-gray-550 flex gap-0.5"> <CalendarDaysIcon className="h-4 w-4 text-gray-550 stroke-2" />{format(new Date(meeting.startsAt), "dd/MM/yyyy")}</p>
                <p className="text-xs font-medium text-gray-550 flex gap-0.5"> <ClockIcon className="h-4 w-4 text-gray-550 stroke-2" /> {format(new Date(meeting.startsAt), "hh:mm a")} </p>
				</div>
			</div>
			<div className="flex gap-1 items-center cursor-pointer" onClick={() => setIsOpen(true)}>
		    <div className="rounded-full p-0.5 bg-primary-200 border border-primary-500">
			<UserIcon className="h-4 w-4 text-primary-500 stroke-2" />
			</div>
			<p className="text-xs font-medium text-gray-550"> {meeting.participants.length} {meeting.participants.length > 1 ? 'Participants' : 'Participant' }
			</p>
			</div>
		</div>
		{isOpen &&<MeetingParticipants open={isOpen} setIsOpen={setIsOpen} participants={meeting.participants} /> }
		</>
	);
};
