
import HidePasswordIcon from "assets/icons/hide-password";
import ShowPasswordIcon from "assets/icons/show-password";
import { showPasswordData } from "models/showPassword";
import { FC } from "react";

export const ShowPassword: FC<showPasswordData> = ({ showPassword, hasError, togglePassword }) => {

    const showPasswordHandler = () => {
        togglePassword(previuesValue => !previuesValue);
	};

	return (
		<>
			{!hasError && (
			<button type="button" onClick={showPasswordHandler} className="absolute bottom-3 right-3">
				{!showPassword ? <ShowPasswordIcon /> : <HidePasswordIcon />}
			</button>
			)}
		</>
	);
};
