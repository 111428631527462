import CloseIcon from "assets/icons/close";
import { Dispatch, SetStateAction, useRef } from "react";
import { Modal } from "components/shared/modal";
import { UserIcon } from "@heroicons/react/24/outline";
import { Form, Formik, FormikHelpers } from "formik";
import { HrTextInput } from "components/forms/textInput";
import { Button } from "components/UI/buttons";
import { Spinner } from "components/shared/spinner";

import * as Yup from "yup";
import { regexValidation } from "utilis/regex";
import { ContactFormProps, ContactSaveProps, ContactsProps } from "../contactsModel";
import { useParams } from "react-router-dom";
import { useContactDetails } from "./useContactDetails";

interface Props {
	open: boolean;
	setIsOpen: Dispatch<SetStateAction<boolean>>;
	contact?: ContactsProps;
}

export const ContactDetails = ({ open, setIsOpen, contact }: Props) => {
	const EMAIL_REGX = regexValidation.EMAIL_REGX;
	const {opportunityId} = useParams();
	let closeButtonRef = useRef(null);
    const {mutate: saveContact } = useContactDetails(contact?.id);
	const initialValues = { name: "", email: "", role: "" };
	const contactFormValues: ContactFormProps = contact ? {name: contact.name, email: contact.email, role: contact.role} : initialValues;

	const onSubmit = (values: ContactFormProps, { setSubmitting }: FormikHelpers<ContactFormProps>) => {
        const data: ContactSaveProps = {
			...values,
			opportunityId: opportunityId!,
			contactId: contact?.id || undefined
		}

		saveContact(data, {onSuccess: () => handleClose(), onError: () => setSubmitting(false)});
	};

	const formValidation = Yup.object({
		name: Yup.string().required("Name can not be empty"),
		email: Yup.string().matches(EMAIL_REGX, "Invalid email address").required("Email can not be empty"),
        role: Yup.string().required("Role is required")
	});

	const handleClose = () => {
		setIsOpen(false);
	};

    
	return (
		<>
				<Modal open={open} setIsOpen={setIsOpen} focusElement={closeButtonRef}>
					<div className="flex justify-between stify-between items-center mb-3">
						<div className="flex items-center gap-3">
							<div className="rounded-full p-3 bg-primary-100">
								<UserIcon className="h-4 w-4 text-primary-500"> </UserIcon>
							</div>
							<h2 className="text-xl font-medium">{contact ? 'Edit Contact' : 'Add Contact'}  </h2>
						</div>
						<button ref={closeButtonRef} onClick={handleClose} className="h-full">
							<CloseIcon />
						</button>
					</div>
                    <div>
                    <Formik initialValues={contactFormValues} validationSchema={formValidation} onSubmit={onSubmit} >
					{({isSubmitting}) => (
							<Form>
								<div className="z-50">
									<div className="mb-4">
										<HrTextInput unfreezeinput label="Name*" name="name" type="text" placeholder="Name" />
									</div>
                                    <div className="mb-4">
										<HrTextInput unfreezeinput label="Email*" name="email" type="text" placeholder="Email" />
									</div>
                                    <div className="mb-4">
										<HrTextInput unfreezeinput label="Role*" name="role" type="text" placeholder="Role" />
									</div>
								</div>
								<div className="flex gap-4">
									<Button type="button" variant="secondary" onClick={handleClose} className="flex-1">
										Discard
									</Button>
									<Button type="submit" variant="primary" className="flex-1" disabled={isSubmitting}>
									   {isSubmitting ? <Spinner className="h-5 w-5 fill-gray-100" /> : contact ? 'Edit Contact' : 'Add Contact'}
									</Button>
								</div>
							</Form>
						)}
					</Formik>
                    </div>
				</Modal>
		</>
	);
};
