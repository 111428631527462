import { useActiveUsers } from "./useActiveUsers";
import { ActiveUsersListItem } from "./active-users-list-item/activeUsersListItem";
import { Spinner } from "components/shared/spinner";
import { ErrorState } from "components/shared/errorState";
import { ActiveUsersData } from "./activeUsersModel";
import useConfirmModalStore from "components/shared/confirm-modal/confirmModalStore";
import { useUnblockUser } from "./useUnblockUser";
import { NoSymbolIcon } from "@heroicons/react/24/outline";
import { useBlockUser } from "./useBlockUser";
import { ActiveUsersFilter } from "./active-users-filtering/activeUsersFilter";
import { useState } from "react";
import { EmptyFilters } from "components/shared/emptyFilters";
import { Pager } from "components/shared/pager";
import { DeleteActiveUsers } from "./delete-active-users/deleteActiveUser";
import classNames from "classnames";
import { useSearchParams } from "react-router-dom";
import { EmptyState } from "components/shared/emptyState";

export const ActiveUsersList = () => {
	const [open, setOpen] = useState<boolean>(false);
	const [userId, setUserId] = useState<string>();
	const [currentPage, setCurrentPage] = useState(1);
	const [searchParams, setSearchParams] = useSearchParams();
	//create custom hook for filter params;
	const allParams = Object.fromEntries(searchParams.entries());
	const { userStatus, ...params } = allParams;
	const hasFilters = params && Object.keys(params).length > 0;
	const { isLoading, error, data: users, refetch } = useActiveUsers(params, { limit: 10, currentPage });
	const { showModal } = useConfirmModalStore();
	const { mutate: blockUser } = useBlockUser();
	const { mutate: unblockUser } = useUnblockUser();

	const handlePage = (p: any) => {
		setCurrentPage(p);
	};

	const handleRetry = () => {
		setSearchParams({ userStatus: "active" });
		setCurrentPage(1);
		refetch();
	};

	const handleMenuClick = (actionType: string, user: ActiveUsersData) => {
		switch (actionType) {
			case "deleteUser":
				setOpen(true);
				setUserId(user.id);
				break;
			case "blockUser":
				showModal("Block user", () => blockUser(user.id), {
					icon: (
						<div className="p-3 bg-red-100 rounded-full">
							<NoSymbolIcon className="h-6 w-6 text-red-500" />{" "}
						</div>
					),
					description: "Are you sure you want to block this user? You can unblock this user at any point!",
					confirmText: "Block user",
					cancelText: "Close",
					confirmBtnColor: "warning",
				});
				break;
			case "unblockUser":
				showModal("Unblock user", () => unblockUser(user.id), {
					icon: (
						<div className="p-3 bg-red-100 rounded-full">
							<NoSymbolIcon className="h-6 w-6 text-red-500" />{" "}
						</div>
					),
					description: "Are you sure you want to unblock this user?",
					confirmText: "Unblock user",
					confirmBtnColor: "warning",
					cancelText: "Close",
				});
				break;
		}
	};

	if (isLoading)
		return (
			<div>
				{" "}
				<Spinner />
			</div>
		);

	if (error)
		return (
			<div>
				{" "}
				<ErrorState action={() => handleRetry()} />{" "}
			</div>
		);

	if (users && users.rows.length < 1 && !hasFilters)
		return (
			<div>
				{" "}
				<EmptyState title="users" />
			</div>
		);

	return (
		<>
			<div className="mb-4">
				<ActiveUsersFilter
					results={users?.count!}
					filters={searchParams}
					setFilters={setSearchParams}
					setCurrentPage={setCurrentPage}
				/>
			</div>
			{users && users.rows.length < 1 && hasFilters ? (
				<div className="py-24">
					<EmptyFilters
						action={() => {
							setSearchParams({ userStatus: "active" });
							setCurrentPage(1);
						}}
					/>
				</div>
			) : (
				<div>
					{users?.rows.map((user, index) => (
						<div key={user.id} className={classNames("pl-5", { "bg-gray-50": index % 2 === 0 })}>
							<ActiveUsersListItem user={user} isActiveUser={true} handleMenuClick={handleMenuClick} />{" "}
						</div>
					))}
					<div>
						{users && users.count > 10 && (
							<Pager current={currentPage} pageSize={10} total={users?.count} onChange={(p) => handlePage(p)} />
						)}
					</div>
				</div>
			)}
			{userId && <DeleteActiveUsers users={users?.rows!} id={userId!} open={open} setIsOpen={setOpen} /> }
		</>
	);
};
