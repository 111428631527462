import { UserIcon } from "@heroicons/react/24/outline";
import CloseIcon from "assets/icons/close";
import { Modal } from "components/shared/modal";
import { ModalProps } from "models/modal";
import { MeetingParticipantsProps } from "../meetingsModel";
import Avatar from "components/shared/avatar";
import { Button } from "components/UI/buttons";
import CopyToClipboardIcon from "assets/icons/copy-to-clipboard";
import classNames from "classnames";
import { CopyToClipboard } from "utilis/helpers";
import { HRPopover } from "components/shared/popover";

interface Props extends ModalProps {
	participants: MeetingParticipantsProps[];
}

export const MeetingParticipants = ({ open, setIsOpen, participants }: Props) => {
	const handleClose = () => {
		setIsOpen(false);
	};

	return (
		<>
			<Modal open={open} setIsOpen={setIsOpen}>
				<div className="flex justify-between stify-between items-center mb-3">
					<div className="flex items-center gap-3">
						<div className="rounded-full p-3 bg-primary-100">
							<UserIcon className="h-4 w-4 text-primary-500"> </UserIcon>
						</div>
						<h2 className="text-xl font-medium"> Participants </h2>
					</div>
					<button onClick={handleClose} className="h-full">
						<CloseIcon />
					</button>
				</div>
				<div className="my-10">
					{participants.map((participant, index) => (
						<div key={participant.id} className={classNames("py-2 px-3 flex justify-between items-center", { "bg-gray-50": index % 2 !== 0 })}>
							<div className="flex gap-3 items-center">
								<Avatar userName={`${participant.email}`} />
								<div>
									<p className="text-base font-medium">
										{participant.email}
									</p>
								</div>
							</div>
							<div>
								<HRPopover content="Copy to clipboard" openOnClick>
								<Button className="!p-0" variant="icon" type="button" onClick={() => CopyToClipboard(participant.email)}> <CopyToClipboardIcon /> </Button>
								</HRPopover>
							</div>
						</div>
					))}
				</div>
			</Modal>
		</>
	);
};
