import { useMutation, useQueryClient } from "@tanstack/react-query";
import { AEaddOportunity, SDRaddOportunity } from "api/opportunity";
import useToastStore from "components/shared/toast/toastStore";

export const useOpportunityAdd = ( isSDR: boolean | null) => {
	const queryClient = useQueryClient();
	const { showToast } = useToastStore();
	return useMutation({
		mutationFn: isSDR ? SDRaddOportunity : AEaddOportunity,
		onSuccess: () => {
			showToast('Opportunity successfully added!', {type: 'success', position: 'top', description: 'User will be notified'});
			queryClient.invalidateQueries({ queryKey: ['opportunity'], type: "active"});
			queryClient.invalidateQueries({ queryKey: ['participants'], type: "active"});
			queryClient.invalidateQueries({ queryKey: ['account-statuses'], type: "active"});
			queryClient.removeQueries({ queryKey: ['opportunity'], type: "inactive"});
			queryClient.removeQueries({ queryKey: ['accounts-overview']});
			

		},
	});
}