import { useMutation, useQueryClient } from "@tanstack/react-query";
import { blockUser } from "api/user";
import { AxiosError } from "axios";
import useToastStore from "components/shared/toast/toastStore";

export const useBlockUser = () => {
	const queryClient = useQueryClient();
	const { showToast } = useToastStore();
	return useMutation({
        mutationKey: ['block-user'],
		mutationFn: blockUser,
		onSuccess: () => {
			queryClient.invalidateQueries({queryKey: ['active-users'], type: 'active'});
            showToast('User is blocked!', {type: 'success', position: 'top', description: 'You can unblock this user at any point'})
		},
        onError: (error: AxiosError) => {
			if (error.response?.status === 400) {
				showToast('You can not block yourself', {type: 'error'})
			}
        }
	});
}