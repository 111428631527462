import Avatar from "components/shared/avatar";
import { StarIcon as StarIconSolid } from "@heroicons/react/24/solid";
import { ContactsProps } from "./contactsModel";
import { checkIsAdmin } from "utilis/helpers";


interface Props {
    title: string;
    list: ContactsProps[];
    onClick?: () => void;
    viewAllHandler?:() => void;
    listLength?: number;
    isMyOpportunity: boolean;
}

const ContactBox = ({title, list, onClick, viewAllHandler, listLength, isMyOpportunity}: Props) => {
    const isAdmin = checkIsAdmin();
    return(
        <div className="">
            <div className="text-base font-bold mb-3 flex justify-between items-center">
                <div>{title} <span className="text-[#6B67FE]">{listLength}</span></div>
                {viewAllHandler && <div className="text-[#4F3CB9] text-sm cursor-pointer" onClick={viewAllHandler}>View all</div>}
            </div>
            <div className="flex justify-between items-center">
                <div className="flex gap-2">
                    {list.length > 0 ? 
                    list.slice(0, 6).map((item, index) => (
                         <div className="relative flex" key={item.id}> 
                            <Avatar userName={item.name}  width={32} height={32} />
                            <StarIconSolid className="text-yellow-400 absolute -bottom-0.5 right-0" width={15} height={15} />
                         </div>   
                    ))
                    :
                    title === "Contacts" ? "No favorite contacts." : "No team members added." 
                    }
                </div>
                {!isAdmin && isMyOpportunity && onClick && <button onClick={onClick} className="text-sm font-medium text-[#4F3CB9] flex gap-8 py-2 px-3 border border-[#8481FE] rounded-[5px]">
                    <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M10 5.5V10.5M10 10.5V15.5M10 10.5H15M10 10.5H5" stroke="#413F9B" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                    </svg>
                    <span>Add</span>
                </button>}
            </div>
        </div>
    )
}

export default ContactBox;