import { Button } from "components/UI/buttons";
import { Form, Formik } from "formik";
import { FC } from "react";
import * as Yup from "yup";
import { HrSelect } from "components/forms/select";
import { Modal } from "components/shared/modal";
import { TrashIcon } from "@heroicons/react/24/outline";
import { useDeleteActiveUser } from "./useDeleteActiveUser";
import { DeleteActiveUserFormProps, DeleteActiveUserProps } from "./deleteActiveUserModel";
import { Alerts } from "components/shared/alerts";
import { useActiveUsers } from "../useActiveUsers";
import { useGetUser } from "hooks/users/useGetUser";

export const DeleteActiveUsers: FC<DeleteActiveUserProps> = ({ id, open, setIsOpen }) => {
	const {data: user } = useGetUser(id);
	const userIdRoles = user?.assignedRoles.map(role => role.roleId);
	const { data } = useActiveUsers({}, {limit: 100, currentPage: 1});
	const activeUsers = data?.rows ? data.rows : [];
	const usersWithSameRole = activeUsers.filter(user => user.assignedRoles.some(role => userIdRoles?.includes(role.roleId)));
	const options = usersWithSameRole?.map((user) => {
		const data = {
			id: user.id,
			name: user.emailData.email,
		};
		return data;
	}).filter(option => option.id !== id);
	const { mutate: deleteActiveUser } = useDeleteActiveUser();
	const initialValues: DeleteActiveUserFormProps = {id,  assigneeId: "" };

	const onSubmit = (values: DeleteActiveUserFormProps) => {
		deleteActiveUser(values, { onSuccess: () => {
			handleClose()}
	});
}

	const formValidation = Yup.object({
		assigneeId: Yup.string().required("Email can not be empty"),
	});

	const handleClose = () => {
		setIsOpen(false);
	};

	return (
		<> {options && 
			<Modal open={open} setIsOpen={setIsOpen}>
					<div className="flex flex-col items-center mb-5">
						<div className="rounded-full p-3.5 bg-red-100 mb-5">
							<TrashIcon className="h-5 w-5 text-red-500 stroke-2"> </TrashIcon>
						</div>
						<h2 className="text-xl font-medium mb-5"> Delete user </h2>
						<p className="text-sm font-regular text-gray-500 mb-5"> Are you sure you want to delete this user? </p>
                        <Alerts type="error">
                               <p className="font-normal"> This user has <span className="font-bold">{user?.opportunitiesCount}</span> opportunities, in order to proceed to deletion you need to transfer the opportunities to another user.</p>
                        </Alerts>
					</div>

					<Formik initialValues={initialValues} validationSchema={formValidation} onSubmit={onSubmit}>
						<Form>
							<div>
								<div className="mb-4">
									<HrSelect label="Transfer opportunities" placeholder="Select transfer opportunities" name="assigneeId" options={options} />
								</div>
								<p className="text-xs mb-4">We will email them about the assigned opportunities.</p>
							</div>
							<div className="flex gap-4">
								<Button type="button" variant="secondary" onClick={handleClose} className="flex-1">
									Cancel
								</Button>
								<Button type="submit" variant="primary" className="flex-1">
									Confirm
								</Button>
							</div>
						</Form>
					</Formik>
				</Modal> }
		</>
	);
};
