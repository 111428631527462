import React from "react";
import { FormTextInput } from "models/forms";
import { ErrorMessage } from "formik";
import classNames from "classnames";
import exlamation from "assets/img/exclamation.png";
import search from "assets/img/search.png";



export const SearchInput: React.FC<FormTextInput> = ({ label, field, form, ...props }) => {
	const name = field ? field.name : '';
	return (
		<>
			{label ? (
				<label
					className={`block text-sm font-normal leading-6 text-black-darker ${
						form && form.touched[name] && form.errors[name] ? "text-red-600" : ""
					}`}
					htmlFor={props.id || props.name}
				>
					{label}
				</label>
			) : (
				""
			)}
			<input
				className={classNames(
					`block w-full min-w-[15rem] rounded-md p-2 focus:outline-none disabled:bg-gray-100  placeholder:text-gray-300  border-solid border sm:text-sm sm:leading-[1.875rem] bg-no-repeat bg-[right_10px_bottom_11px]`,
					props.className,
					{
						"border-red-600":  form && form.touched[name] && form.errors[name],
						"border-gray-300": form && !form.touched[name] && !form.errors[name],
					}
				)}
				style={{ backgroundImage: form && form.touched[name] && form.errors[name] ? `url(${exlamation})` : `url(${search})` }}
				{...field}
				{...props}
			/>
			{field && (
				<div className="error text-red-600 text-xs">
					{" "}
					<ErrorMessage name={field.name} />
				</div>
			)}
		</>
	);
};
