import {FC} from "react";
import classNames from 'classnames';
import {PageInfo, PagerProps} from "../../models/pager";
import {ArrowLongLeftIcon, ArrowLongRightIcon} from "@heroicons/react/20/solid";

const getPages = (totalPages: number, maxVisiblePages: number = 50) => {
  // TODO: Implement maxVisiblePages
  const pages = [];
  for (let i = 1; i <= totalPages; i++) {
    pages.push(i);
  }

  return pages;
}

// Funkcija za generiranje liste stranica
const generatePageList = (currentPage: number, totalPages: number): number[] => {
  
  let startPage: number = currentPage - 1;
  let endPage: number = currentPage + 1;
  
  if (startPage < 1) {
    if(totalPages > 2) endPage = endPage + 1 - startPage;
    startPage = 1;
  }

  if (endPage > totalPages) {
    if(totalPages > 2) startPage -= endPage - totalPages;
    endPage = totalPages;
  }

  const pageList: number[] = [];
  for (let i: number = startPage; i <= endPage; i++) {
    pageList.push(i);
  }

  return pageList;
}


const getPageInfo = (total: number, pageSize: number, current: number): PageInfo => {
  const totalPages = Math.ceil(total / pageSize);
  const from = total > 0 ? (current - 1) * pageSize + 1 : 0;
  const to = Math.min(from + pageSize - 1, total);

  const pages = getPages(totalPages);

  return {
    from,
    to,
    total,
    current,
    pageSize,
    totalPages,
    pages
  };
}

export const Pager: FC<PagerProps> = ({total, current = 1, pageSize = 10, onChange, isLoading}) => {

  const info = getPageInfo(total || 0, pageSize || 10, current || 1);
  
  const isFirst = current === 1;
  const isLast = info.totalPages <= info.current;
  const list = generatePageList(current, info.totalPages);

  const previousLinkClassName = classNames("inline-flex items-center border-t-2 border-transparent pr-1 pt-4 text-sm font-medium", {
    "text-gray-500 hover:border-gray-300 hover:text-gray-700": !isFirst,
    "text-gray-100 cursor-default": isFirst
  })
  const previousIconClassName = classNames("mr-3 h-5 w-5", {
    "text-gray-400": !isFirst,
    "text-gray-100 cursor-default": isFirst
  })

  const nextLinkClassName = classNames("inline-flex items-center border-t-2 border-transparent pr-1 pt-4 text-sm font-medium", {
    "text-gray-500 hover:border-gray-300 hover:text-gray-700": !isLast,
    "text-gray-100 cursor-default": isLast
  })
  const nextIconClassName = classNames("ml-3 h-5 w-5", {
    "text-gray-400": !isLast,
    "text-gray-100 cursor-default": isLast
  })

  const handleNextClick = () => {
    if (isLast || isLoading) return;

    onChange && onChange(++current!);
  }

  const handlePreviousClick = () => {
    if (isFirst || isLoading) return;
    onChange && onChange(--current!);
  }

  const handlePageClick = (page: number) => {
    if (current === page || isLoading) return;

    onChange && onChange(page);
  }

  return (
    <nav className="flex items-center justify-between border-t border-gray-100 px-4 sm:px-0">
      <div className="-mt-px flex w-0 flex-1">
        <button
          onClick={handlePreviousClick}
          className={previousLinkClassName}
        >
          <ArrowLongLeftIcon className={previousIconClassName} aria-hidden="true"/>
          Previous
        </button>
      </div>
      <div className="hidden md:-mt-px md:flex">
        {
          current > 3 && (<div>
            <button
              key={1}
              onClick={() => handlePageClick(1)}
              className={'border-t-2 border-transparent px-4 pt-4 text-sm font-medium text-gray-500 hover:border-gray-300 hover:text-gray-700'}
            >
              {1}
            </button>
            ...
          </div>)
        }
        {
          list.map((page: number) => {

            const isCurrent: boolean = page !== current;
            const className = classNames('inline-flex items-center', {
              'border-t-2 border-transparent px-4 pt-4 text-sm font-medium text-gray-500 hover:border-gray-300 hover:text-gray-700': isCurrent,
              'border-t-2 border-primary-500 px-4 pt-4 text-sm font-medium text-primary-500': !isCurrent,
            });

            return <button
              key={page}
              onClick={() => handlePageClick(page)}
              className={className}
            >
              {page}
            </button>
          })
        }
        {
          current < info.totalPages - 3  && (<div>
            ...
            <button
              key={info.totalPages}
              onClick={() => handlePageClick(info.totalPages)}
              className={'border-t-2 border-transparent px-4 pt-4 text-sm font-medium text-gray-500 hover:border-gray-300 hover:text-gray-700'}
            >
              {info.totalPages}
            </button>
          </div>)
        }
      </div>
      <div className="-mt-px flex w-0 flex-1 justify-end">
        <button
          onClick={handleNextClick}
          className={nextLinkClassName}
        >
          Next
          <ArrowLongRightIcon className={nextIconClassName} aria-hidden="true"/>
        </button>
      </div>
    </nav>
  )
}
