import Avatar from "components/shared/avatar";
import { AccountsProps } from "pages/accounts-overview/accountsOverviewModel";
import { FC } from "react";
import { Participants } from "./participants/participants";
import { format } from "date-fns";
import { AccountStatusInfoProps } from "models/accountStatusInfo";
import { ClosedLostIcon, ClosedWonIcon, OngoingIcon } from "components/shared/icons";
import { Link } from "react-router-dom";

export const AccountGeneralInfo: FC<{ account: AccountsProps; accountStatuses: AccountStatusInfoProps[] }> = ({
	account,
	accountStatuses,
}) => {
	const total = accountStatuses.reduce((total: number, item: AccountStatusInfoProps) => (total += +item.count), 0);

	const iconHandle = (status: string) => {
		switch(status){
			case "ongoing":
				return 	<OngoingIcon />;
			case "closed won":
				return <ClosedWonIcon />;
			case "closed lost":
				return 	<ClosedLostIcon />;
		}
	}
		
	return (
		<div className="p-5 bg-white flex justify-between flex-wrap gap-4">
			<div className="flex items-center gap-5">
				<div>
					<Avatar userName={account.name} width={121} height={121} textSize="text-4xl" />
				</div>
				<div>
					<h3 className="text-3xl font-medium"> {account.name}</h3>
					<p className="text-base font-medium text-[#6B67FE]">{account.industry || ""}</p>
					{/* <small className="text-xs font-light text-gray-500"> Type</small>
					<p className="text-base font-medium"> {account.industry}</p> */}
					<small className="text-xs font-light text-gray-500">Last update</small>
					<p className="text-xs font-regular"> {format(new Date(account.updatedAt), "MMMM d, yyyy h:mm a")}</p>
					{
					account.linkedInUrl && 
						<>
							<small className="text-xs font-light text-gray-500">Link</small>
							<Link to={account.linkedInUrl} target="_blank" className="text-xs block font-regular text-[#6B67FE]">LinkedIn</Link>
						</>
					}
				</div>
			</div>
			<Participants accountId={account.id!} />
			<div className="flex-col gap-2 flex mr-8">
				<div className="flex items-center gap-2">
					<svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
						<rect width="32" height="32" rx="16" fill="#413F9B"/>
						<path d="M13 9H11C10.4696 9 9.96086 9.21071 9.58579 9.58579C9.21071 9.96086 9 10.4696 9 11V23C9 23.5304 9.21071 24.0391 9.58579 24.4142C9.96086 24.7893 10.4696 25 11 25H21C21.5304 25 22.0391 24.7893 22.4142 24.4142C22.7893 24.0391 23 23.5304 23 23V11C23 10.4696 22.7893 9.96086 22.4142 9.58579C22.0391 9.21071 21.5304 9 21 9H19M13 9C13 9.53043 13.2107 10.0391 13.5858 10.4142C13.9609 10.7893 14.4696 11 15 11H17C17.5304 11 18.0391 10.7893 18.4142 10.4142C18.7893 10.0391 19 9.53043 19 9M13 9C13 8.46957 13.2107 7.96086 13.5858 7.58579C13.9609 7.21071 14.4696 7 15 7H17C17.5304 7 18.0391 7.21071 18.4142 7.58579C18.7893 7.96086 19 8.46957 19 9M16 16H19M16 20H19M13 16H13.01M13 20H13.01" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
					</svg>
					<strong className="text-sm"> {total} </strong>
					<span className="text-xs text-gray-500"> total </span>
				</div>
				{accountStatuses.map((item: AccountStatusInfoProps) => (
					<div key={item.status} className="flex items-center gap-2">
						{iconHandle(item.status)}
						<strong className="text-sm"> {item.count} </strong>
						<span className="text-xs text-gray-500"> {item.status} </span>
					</div>
				))}
			</div>
		</div>
	);
};
