import { useMutation, useQueryClient } from "@tanstack/react-query";
import { addUser } from "api/user";
import { AxiosError } from "axios";
import useToastStore from "components/shared/toast/toastStore";

export const useAddUser = (props: {isInvite: boolean} = {isInvite: false}) => {
	const queryClient = useQueryClient();
	const { showToast } = useToastStore();
	return useMutation({
		mutationFn: addUser,
		onSuccess: () => {
			showToast('Invite has been sent!', {type: 'success', position: 'top', description: 'User will be notified'});
			queryClient.invalidateQueries({queryKey: ['pending-users'], type: 'active'});
			queryClient.removeQueries({queryKey: ['pending-users'], type: 'inactive'});

		},
		onError: (error: AxiosError) => {
			if (error.response?.status === 409) {
				showToast('User with email already exist', {type: 'error', position: 'top-right'});
			}
		}

	});
}