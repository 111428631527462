import { useParams } from "react-router-dom";
import MainDetailsWidget from "../opportunity-details-components/main-details-widget/mainDetailsWidget";
import { useOpportunityById } from "../opportunity-details-components/useOpportunityById";
import { useAccountDetails } from "pages/account-details/useAccountDetails";
import { Breadscrumb } from "components/shared/breadscrumb";
import { useOpportunityScore } from "../opportunity-details-components/useOpportunityScore";
import { OpportunityFields } from "components/opportunities/opportunityFields";
import { useEditFields } from "../opportunity-details-components/useEditFields";
import { HrTabs } from "components/shared/tabs";
import { checkIsAE, checkIsAdmin, checkIsSDR } from "utilis/helpers";
import useToastStore from "components/shared/toast/toastStore";
import { useState } from "react";
import {
  useAEFields,
  useSDRFields,
} from "../opportunity-details-components/useFields";
import { Spinner } from "components/shared/spinner";
import { Meetings } from "components/meetings/meetings";
import StepperStatusSection from "../opportunity-details-components/main-details-widget/stepperStatusSection";
import { useUserStore } from "store/userStore";

const OpportunityDetails = () => {
  const [activeTab, setActiveTab] = useState(0);
  const { opportunityId, id } = useParams();
  const { showToast } = useToastStore();
  const isSDR = checkIsSDR()!;
  const isAdmin = checkIsAdmin()!;
  const isAE = checkIsAE()!;
  const { user } = useUserStore()!;
  const { data: opportunity, isLoading: isOpportunityLoading } =
    useOpportunityById(opportunityId!);

  const {
    data: account,
    isLoading: isAccountLoading,
    error: accountError,
  } = useAccountDetails(id!);
  const {
    data: score,
    isLoading: isScoreLoading,
    isError: isScoreError,
  } = useOpportunityScore(opportunityId!);
  const {
    data: sdrSchema,
    isLoading: isSDRSchemaLoading,
    error: SDRShemaError,
  } = useSDRFields(opportunityId!, isSDR, isAdmin);
  const {
    data: aeSchema,
    isLoading: isAESchemaLoading,
    error: AEShemaError,
  } = useAEFields(opportunityId!, isAE, isAdmin);
  const { mutate: editFields } = useEditFields();

  const [phase, setPhase] = useState(score?.phase);

  if (
    isSDRSchemaLoading ||
    isAESchemaLoading ||
    isAccountLoading ||
    isScoreLoading
  )
    return <Spinner></Spinner>;
  if (SDRShemaError || AEShemaError || isScoreError || accountError)
    return <div> </div>;

  const currentPhase = phase || score?.phase;

  const objMap = new Map();

  for (const obj of [...aeSchema, ...sdrSchema]) {
    objMap.set(obj.name, obj);
  }

  const overviewSchema = Array.from(objMap.values());
  const AEschema = isAdmin
    ? aeSchema
    : aeSchema?.filter((item: any) => item.phase === currentPhase);
  const SDRSchema = sdrSchema;

  const handleChanges = (values: any) => {
    editFields(
      { id: opportunityId!, fields: values },
      {
        onSuccess: () =>
          showToast("Score successfully updated!", {
            type: "success",
            position: "top",
          }),
      }
    );
  };

  let isMyOpportunity: boolean = false;

  if (isAE) {
    isMyOpportunity = opportunity?.aeId === user?.id;
  }

  if (isSDR) {
    isMyOpportunity = opportunity?.sdrId === user?.id;
  }

  const tabs = [
    {
      label: "Overview",
      condition: isAdmin || isSDR || isAE,
      content: (
        <div>
          <div className="flex flex-col xl:flex-row w-full lg:gap-2">
            <div className="xl:w-[80%]">
              <StepperStatusSection
                isMyOpportunity={isMyOpportunity}
                setPhase={setPhase}
                phase={phase ? phase : currentPhase}
                activeTab={activeTab}
                score={score!}
                opportunity={opportunity!}
              />
              <OpportunityFields
                allSchemas={overviewSchema}
                schema={overviewSchema}
                readonly={true}
                value={{ fields: opportunity?.fields }}
                onChange={handleChanges}
              />
            </div>
            <Meetings readonly />
          </div>
        </div>
      ),
    },
    {
      label: "SDR",
      condition: isAdmin || isSDR || isAE,
      content: (
        <div className="flex flex-col xl:flex-row w-full lg:gap-2">
          <StepperStatusSection
            isMyOpportunity={isMyOpportunity}
            setPhase={setPhase}
            phase={phase ? phase : currentPhase}
            activeTab={activeTab}
            score={score!}
            opportunity={opportunity!}
          />
          <OpportunityFields
            allSchemas={overviewSchema}
            schema={SDRSchema}
            readonly={isAdmin || isAE || !isMyOpportunity}
            value={{ fields: opportunity?.fields }}
            onChange={handleChanges}
          />
          {/* {isAdmin && <Meetings readonly /> }  */}
        </div>
      ),
    },
    {
      label: "AE",
      condition: isAdmin || isSDR || isAE,
      content: (
        <div>
          <div className="flex flex-col xl:flex-row lg:gap-2 w-full">
            <div className="xl:w-[80%]">
              <StepperStatusSection
                isMyOpportunity={isMyOpportunity}
                setPhase={setPhase}
                phase={phase ? phase : currentPhase}
                activeTab={activeTab}
                score={score!}
                opportunity={opportunity!}
              />
              <OpportunityFields
                allSchemas={overviewSchema}
                schema={AEschema}
                readonly={isAdmin || isSDR || !isMyOpportunity}
                value={{ fields: opportunity?.fields }}
                onChange={handleChanges}
              />
            </div>
            <Meetings
              readonly={isAdmin || isSDR || !isMyOpportunity}
              hideFilters={!isAE}
            />
          </div>
        </div>
      ),
    },
  ];

  return (
    <>
      {aeSchema && sdrSchema && (
        <div>
          <Breadscrumb showBackButton />
          <div className="mt-3 flex flex-col gap-2">
            {!isOpportunityLoading && !isAccountLoading && !isScoreLoading && (
              <>
                <MainDetailsWidget
                  isMyOpportunity={isMyOpportunity}
                  activeTab={activeTab}
                  score={score!}
                  opportunity={opportunity!}
                  account={account!}
                />
                <HrTabs
                  initialTab={activeTab}
                  onTabChange={(t: number) => setActiveTab(t)}
                  tabs={tabs}
                />
              </>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default OpportunityDetails;
