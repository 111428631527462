import { Button } from "components/UI/buttons";
import { HrSelect } from "components/forms/select";
import { Form, Formik, FormikProps } from "formik";
import { ArrowPathIcon, MagnifyingGlassIcon } from "@heroicons/react/24/outline";
import { FC, useRef } from "react";

import { Filter } from "components/shared/filter";
import { SetURLSearchParams } from "react-router-dom";
import { HrSearchInput } from "components/forms/searchInput";
import { FilterProps } from "models/filter";

interface Props {
	results: number;
	filters: URLSearchParams;
	setFilters: SetURLSearchParams;
	setCurrentPage: React.Dispatch<React.SetStateAction<number>>;
}

export const OpportunityFilters: FC<Props> = ({ results, filters, setFilters, setCurrentPage }) => {
	const params = Object.fromEntries(filters.entries());
	const hasFilters = params && Object.keys(params).length > 0;
	const values = hasFilters ? params : { name: "", status: "" };
	const initialValues = values;
	const formikRef = useRef<FormikProps<any>>(null);
	const statuses = [
		{
			id: "ongoing",
			name: "Ongoing",
		},
		{
			id: "closed won",
			name: "Closed won",
		},
		{
			id: "closed lost",
			name: "Closed lost",
		},
	];

	const onSubmit = (values: FilterProps) => {
		if (!!values.name || !!values.status) {
			setFilters(values);
			setCurrentPage(1);
		} else {
			handleReset();
		}
	};

	const handleReset = () => {
		setFilters({});
		setCurrentPage(1);
		formikRef.current?.resetForm();
	};

	return (
		<Filter results={results} action={handleReset} hasFilters={hasFilters}>
			<Formik innerRef={formikRef} initialValues={initialValues} onSubmit={onSubmit} enableReinitialize>
				<Form className="flex gap-5 mt-4 flex-wrap">
				<div className="flex gap-4 2xl:gap-14 flex-wrap items-center">
					<div>
						<HrSearchInput name="name" type="text" placeholder="Opportunity name" />
					</div>
					<div className="flex items-center">
						<HrSelect placeholder="Select Status" name="status" options={statuses} />
					</div>
			    </div>
					<div className="flex gap-4 flex-col xs:ml-auto xs:flex-row w-full xs:w-max items-center flex-wrap">
						<Button
							type="button"
							key="notsubmit"
							variant="transparent"
							onClick={() => handleReset()}
							className="flex-1"
						>
							<div className="flex items-center gap-2 w-max">
								<ArrowPathIcon className="h-4 w-4 text-primary-500" />
								Clear Filter
							</div>
						</Button>
						<Button type="submit" variant="primary" className="flex-1 w-full">
							<div className="flex items-center gap-2 w-max">
								<MagnifyingGlassIcon className="h-4 w-4 text-white" />
								Filter
							</div>
						</Button>
					</div>
				</Form>
			</Formik>
		</Filter>
	);
};
