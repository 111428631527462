import Avatar from "components/shared/avatar";
import { Pager } from "components/shared/pager";
// import { EditAccount } from "./edit-account/editAccount";
import { useState } from "react";
import { HrTable } from "components/shared/table";
import { Action, ColumnProps } from "models/table";
import { AccountsProps } from "./accountsOverviewModel";
import useConfirmModalStore from "components/shared/confirm-modal/confirmModalStore";
import { useRemoveAccount } from "./remove-account/useRemoveAccount";
import { 
	// PencilIcon, 
	TrashIcon 
} from "@heroicons/react/24/outline";
import { Alerts } from "components/shared/alerts";
import { EmptyFilters } from "components/shared/emptyFilters";
import { ErrorState } from "components/shared/errorState";
import { useAccountsOverview } from "./useAccountsOverview";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Spinner } from "components/shared/spinner";
import AccountsOverviewFilters from "./account-filtering/accountFilters";
import { checkIsAdmin, removeKeys } from "utilis/helpers";
// import { useUserStore } from "store/userStore";
import { EmptyState } from "components/shared/emptyState";

export const AccountsOverviewList = () => {
	// const [isOpenEditUser, setIsOpenEditUser] = useState(false);
	const [searchParams, setSearchParams] = useSearchParams();
	const params = Object.fromEntries(searchParams.entries());
	const hasFilters = params && Object.keys(params).length > 0;
	const isAdmin = checkIsAdmin();
	// const { user } = useUserStore();
	const [currentPage, setCurrentPage] = useState(1);
	const navigate = useNavigate();
	const { showModal } = useConfirmModalStore();
	// const [account, setAccount] = useState<AccountsProps>();
	const { mutate: removeUser } = useRemoveAccount();
	
	const {
		isLoading,
		isFetching,
		error,
		data: accountsOverviewData,
		refetch,
	} = useAccountsOverview(removeKeys(params, ['azureLogin', 'data']), { limit: 10, currentPage });	
	const users = accountsOverviewData?.rows.length ? accountsOverviewData.rows : [];

	const handlePage = (p: number) => {
		setCurrentPage(p);
	};

	const handleRetry = () => {
		setSearchParams({});
		setCurrentPage(1);
		refetch();
	};

	const handleNavigate = (account: AccountsProps) => {
		navigate(`accountDetails/${account.id}`);
	};

	const removeHandler = (account: AccountsProps) => {
		showModal(
			"Remove Account",
			() =>
				removeUser(account.id, {
					onSuccess: () => {
						if (users.length === 1 && currentPage !== 1) {
							setCurrentPage((previousValue) => previousValue - 1);
						}
					},
				}),
			{
				icon: (
					<div className="p-3 bg-red-100 rounded-full">
						<TrashIcon className="h-6 w-6 text-red-500" />{" "}
					</div>
				),
				description: (
					<div>
						<div className="mb-4">
							Are you sure you want to remove this<strong> {account.name} </strong> account?
						</div>
						<div className="mb-14">
							<Alerts type="error">
								<p className="font-normal">
									By removing this account you will remove also all opportunities related to account.
								</p>
							</Alerts>
						</div>
					</div>
				),
				confirmText: "Confirm",
				cancelText: "Close",
			}
		);
	};

	// const editHandler = (account: AccountsProps) => {
	// 	setAccount(account);
	// 	setIsOpenEditUser(true);
	// };

	const columns: ColumnProps<AccountsProps, keyof AccountsProps>[] = [
		{
			key: "name",
			title: "Account Name",
			render: (_, { name }) => (
				<div className="flex items-center gap-2">
					<Avatar userName={name} />
					{name}
				</div>
			),
		},
		{
			key: "industry",
			title: "Industry",
			render: (_, { industry }) => <span>{industry ? industry : ""}</span>,
		},
		// {
		// 	key: "oportunities",
		// 	title: "Number of oportunities",
		// 	render: (_, { oportunities }) => <div>  </div>,
		// },
	];

	const data: AccountsProps[] = users;

	let actions: Action<AccountsProps>[] = [
		// {
		// 	label: "Edit",
		// 	condition: (row) => {
		// 		return row.accountManagers.some((item) => item.managerId === user?.id);
		// 	},
		// 	icon: <PencilIcon className="h-5, w-5 text-gray-600" />,
		// 	callback: (row) => {
		// 		editHandler(row);
		// 	},
		// },
		{
			label: "Delete",
			icon: <TrashIcon className="h-5, w-5 text-gray-600" />,
			condition: () => {
				return isAdmin!;
			},
			callback: (row) => {
				removeHandler(row);
			},
		},
	];

	if (isLoading)
		return (
			<div>
				<Spinner />
			</div>
		);

	if (error)
		return (
			<div>
				<ErrorState action={() => handleRetry()} />
			</div>
		);
		
	if (users && users.length < 1 && !hasFilters)
		return (
			<div>
				<EmptyState title="accounts" />
			</div>
		);

	return (
		<>
			<div className="mb-4">
				<AccountsOverviewFilters
					results={accountsOverviewData?.count!}
					filters={searchParams}
					setFilters={setSearchParams}
					setCurrentPage={setCurrentPage}
				/>
			</div>
			{users && users.length < 1 && hasFilters ? (
				<div className="py-24">
					<EmptyFilters
						action={() => {
							setSearchParams({});
							setCurrentPage(1);
						}}
					/>
				</div>
			) : (
				<div>
					<HrTable isLoading={isFetching} data={data} columns={columns} actions={actions} handleNavigate={handleNavigate} />
					{accountsOverviewData?.count! > 10 && (
						<Pager
							current={currentPage}
							pageSize={10}
							total={accountsOverviewData?.count}
							onChange={(p) => handlePage(p)}
							isLoading={isFetching}
						/>
					)}
				</div>
			)}

			{/* {isOpenEditUser && <EditAccount account={account!} setIsOpen={setIsOpenEditUser} open={isOpenEditUser} />} */}
		</>
	);
};
