export const Unlocked = () => {
    return(
        <div className="w-[28px] h-[28px] flex items-center justify-center border rounded-full text-xs bg-[#F0F0FF] border-[#6B67FE] text-[#413F9B]">
            <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M13.6 10.2227C13.6 13.0268 11.3167 15.3001 8.50002 15.3001C5.68337 15.3001 3.40002 13.0268 3.40002 10.2227C3.40002 7.4185 5.68337 5.14527 8.50002 5.14527C11.3167 5.14527 13.6 7.4185 13.6 10.2227Z" fill="#6B67FE"/>
                <path d="M4.67502 6.41462V5.56845C4.67502 3.42542 6.3811 1.69995 8.50002 1.69995C9.63061 1.69995 10.6437 2.19118 11.3427 2.97495M8.50002 11.1105V9.41055M13.6 10.2227C13.6 13.0268 11.3167 15.3001 8.50002 15.3001C5.68337 15.3001 3.40002 13.0268 3.40002 10.2227C3.40002 7.4185 5.68337 5.14527 8.50002 5.14527C11.3167 5.14527 13.6 7.4185 13.6 10.2227Z" stroke="#413F9B" strokeWidth="1.33333" strokeLinecap="round"/>
            </svg>
        </div>
    )
}

export const Locked = () => {
    return(
        <div className="bg-[#ECECEC] text-[#81828D] border-[#81828D] w-[28px] h-[28px] flex items-center justify-center border rounded-full text-xs">
            <svg width="18" height="17" viewBox="0 0 18 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M14.3125 9.94033C14.3125 12.8613 11.934 15.2293 9 15.2293C6.06599 15.2293 3.6875 12.8613 3.6875 9.94033C3.6875 7.01932 6.06599 4.65137 9 4.65137C11.934 4.65137 14.3125 7.01932 14.3125 9.94033Z" fill="#E1E1E4"/>
                <path d="M5.01563 5.97361V5.09218C5.01563 2.85987 6.79278 1.0625 9 1.0625C11.2072 1.0625 12.9844 2.85987 12.9844 5.09218V6.41436M9 10.8652V9.09437M14.3125 9.94033C14.3125 12.8613 11.934 15.2293 9 15.2293C6.06599 15.2293 3.6875 12.8613 3.6875 9.94033C3.6875 7.01932 6.06599 4.65137 9 4.65137C11.934 4.65137 14.3125 7.01932 14.3125 9.94033Z" stroke="#9D9EA6" strokeWidth="1.33333" strokeLinecap="round"/>
            </svg>
        </div>
    )
}

export const CompletedStep = () => {
    return (
        <svg width="29" height="28" viewBox="0 0 29 28" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="14.4004" cy="14" r="13.4167" fill="#B8EB4B" stroke="#5A5A69" strokeWidth="1.16667"/>
            <path d="M8.56738 15.166L11.9007 18.4993L20.234 10.166" stroke="#3F3F46" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    )
}