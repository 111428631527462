import { useQuery } from "@tanstack/react-query";
import { getOpportunityScore } from "api/opportunity";


export const useOpportunityScore = (id: string) => {
	return useQuery({
        queryKey: ['opportunityScore', id],
		queryFn: () =>  getOpportunityScore(id),
		keepPreviousData: true
	});
};