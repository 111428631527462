import { Menu, Transition } from "@headlessui/react";
import { FunnelIcon } from "@heroicons/react/24/outline";
import { useRef, Fragment, useState } from "react";
import { useMeetingCountStore } from "store/meetingCountStore";

interface Props {
	meetingFilter: (items: string[]) => void;
}

export const FilterMeetings = ({ meetingFilter }: Props) => {
    const { meetingCount } = useMeetingCountStore();
	const [hasFilters, setFilters] = useState(false);
	let menuItems = useRef([
		{ name: "Ongoing", key: "ongoing", isChecked: false },
		{ name: "Upcoming", key: "upcoming", isChecked: false },
		{ name: "Finished", key: "finished", isChecked: false },
		{ name: "Canceled", key: "canceled", isChecked: false },
	]);

	const handleCloseMenu = (e: React.MouseEvent) => {
		e.stopPropagation();
	};

	const handleOnChange = (position: number) => {
		menuItems.current = menuItems.current.map((item, index) => {
			if (index === position) {
				const newItem = {
					...item,
					isChecked: !item.isChecked,
				};
				return newItem;
			}
			return item;
		});
		const checkedItems = menuItems.current.filter(({ isChecked }) => isChecked).map((item) => item.key);
		checkedItems.length > 0 ? setFilters(true) : setFilters(false);
		meetingFilter(checkedItems);
	};

	return (
		<>
			<Menu as="div" className="relative inline-block text-left">
				{(hasFilters || meetingCount !== 0) && <div>
					<Menu.Button className="flex items-center p-2 bg-primary-200 rounded-sm text-gray-400 hover:text-gray-600">
						<span className="sr-only">Open options</span>
						<FunnelIcon className="h-6 w-6 text-primary stroke-2" aria-hidden="true" />
						{hasFilters && <div className="absolute -top-1 -left-2 rounded-full flex items-center justify-center bg-primary-500 text-white text-xs w-4 h-4">{meetingCount}</div> }
					</Menu.Button>
				</div> }

				<Transition
					as={Fragment}
					enter="transition ease-out duration-100"
					enterFrom="transform opacity-0 scale-95"
					enterTo="transform opacity-100 scale-100"
					leave="transition ease-in duration-75"
					leaveFrom="transform opacity-100 scale-100"
					leaveTo="transform opacity-0 scale-95"
				>
					<Menu.Items
						static
						className="absolute right-0 z-10 mt-2 w-max origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
					>
						<div className="py-4 flex flex-col items-start px-6 min-w-[13rem]">
							{menuItems.current.map((menuItem, index) => (
								<Menu.Item as="button" onClick={handleCloseMenu} key={menuItem.key}>
									<div className="flex items-center pb-3" onClick={handleCloseMenu}>
										<input
											className="cursor-pointer accent-primary-500 w-4 h-4"
											type="checkbox"
											id={`custom-checkbox-${index}`}
											name={menuItem.key}
											value={menuItem.key}
											checked={menuItems.current[index].isChecked}
											onChange={() => handleOnChange(index)}
										/>
										<label className="ml-2 cursor-pointer" htmlFor={`custom-checkbox-${index}`}>
											{menuItem.name}
										</label>
									</div>
								</Menu.Item>
							))}
						</div>
					</Menu.Items>
				</Transition>
			</Menu>
		</>
	);
};
