import { Fragment } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { CheckIcon } from "@heroicons/react/24/outline";
import useConfirmModalStore from "./confirmModalStore";
import { Button } from "components/UI/buttons";

export const ConfirmModal = () => {
	const { title, onConfirm, options, isVisible, hideModal, reset } = useConfirmModalStore();

	return (
		<Transition.Root show={isVisible} as={Fragment}>
			<Dialog as="div" className="relative z-50" onClose={hideModal}>
				<Transition.Child
					as={Fragment}
					enter="ease-out duration-300"
					enterFrom="opacity-0"
					enterTo="opacity-100"
					leave="ease-in duration-200"
					leaveFrom="opacity-100"
					leaveTo="opacity-0"
				>
					<div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
				</Transition.Child>

				<div className="fixed inset-0 z-10 overflow-y-auto">
					<div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
						<Transition.Child
							as={Fragment}
							enter="ease-out duration-300"
							enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
							enterTo="opacity-100 translate-y-0 sm:scale-100"
							leave="ease-in duration-200"
							leaveFrom="opacity-100 translate-y-0 sm:scale-100"
							leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
						>
							<Dialog.Panel as="div" className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
								<div>
									<div className="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-green-100">
										{options && options.icon ? (
											options.icon
										) : (
											<CheckIcon className="h-6 w-6 text-green-600" aria-hidden="true" />
										)}
									</div>
									<div className="mt-4 text-center">
										<Dialog.Title as="h3" className="text-xl font-semibold leading-6 text-gray-900">
											{title}
										</Dialog.Title>
										<div className="mt-4 px-6">
											<p className="text-sm text-gray-500">{options.description}</p>
										</div>
									</div>
								</div>
								<div className="mt-5 flex gap-3">
									<Button type="button" variant={options.cancelBtnColor ? options.cancelBtnColor : "secondary"} className="grow-2" onClick={() =>{ hideModal(); setTimeout(() => reset(), 200)}}>
									{options.cancelText ? options.cancelText : 'Cancel'}
									</Button>
									<Button
										type="button"
										variant={options.confirmBtnColor ? options.confirmBtnColor : "primary"}
										className="grow-2"
										onClick={() => {
											onConfirm();
											hideModal();
                                       setTimeout(() => reset(), 200);
										}}
									>
										{options.confirmText ? options.confirmText : 'Confirm'}
									</Button>
								</div>
							</Dialog.Panel>
						</Transition.Child>
					</div>
				</div>
			</Dialog>
		</Transition.Root>
	);
};
