import { useMutation, useQueryClient } from "@tanstack/react-query";
import { removeAccount } from "api/account";
import useToastStore from "components/shared/toast/toastStore";

export const useRemoveAccount = () => {
	const queryClient = useQueryClient();
	const { showToast } = useToastStore();
	return useMutation({
		mutationFn: removeAccount,
		onSuccess: (data) => {
			showToast('Account successfully removed', {type: 'success', position: 'top'});
			queryClient.invalidateQueries(['accounts-overview']);
		},
	});
}
