import { useField } from "formik";
import { FormSelectInput, SelectOptionsData } from "models/forms";
import { FC, Fragment, useState } from "react";
import { ChevronDownIcon } from "@heroicons/react/24/outline";
import { Listbox, Transition } from "@headlessui/react";
import classNames from "classnames";
import exlamation from "assets/img/exclamation.png";

export const HrSelect: FC<FormSelectInput> = ({ placeholder, label, disabled, options, width, onChange, ...props }) => {
	const [field, meta, helpers] = useField(props);
	const [selectedPerson, setSelectedPerson] = useState<SelectOptionsData>({ name: "", id: "" });

	const handleOnChange = (option: any) => {
		helpers.setValue(option.id);
		setSelectedPerson(option);
		onChange && onChange(option)
	};
	return (
		<>
			<Listbox value={selectedPerson} onChange={handleOnChange} disabled={disabled}>
				{({ open }) => (
					<>
						<Listbox.Label
							className={`block text-sm font-normal leading-6 text-black-darker ${
								meta.touched && meta.error ? "text-red-600" : ""
							}`}
							htmlFor={props.id || props.name}
						>
							{label}
						</Listbox.Label>
						<div className="relative">
							<Listbox.Button
								style={{ minWidth: width }}
								className={classNames(
									"block min-h-[46px] disabled:bg-gray-100 w-full rounded-md p-2 focus:outline-none  placeholder:text-gray-300  border-solid border sm:text-sm sm:leading-[1.875rem] bg-no-repeat bg-[right_10px_bottom_11px]",
									{ "border-red-600": meta.touched && meta.error, "border-gray-300": !meta.touched && !meta.error },
									{ "min-w-[15rem]": !width },
								)}
							>
								<span className="flex truncate">
									{field.value ? (
										options.find(({ id }) => id === field.value)?.name
									) : (
										<span className="text-gray-300"> {placeholder} </span>
									)}
								</span>
								{!disabled && <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2.5">
									{meta.touched && meta.error ? (
										<div className="h-5 w-5" style={{ backgroundImage: `url(${exlamation})` }}></div>
									) : (
										<ChevronDownIcon className="h-5 w-5" aria-hidden="true" />
									)}
								</span> }
							</Listbox.Button>

							<Transition
								show={open}
								as={Fragment}
								leave="transition ease-in duration-100"
								leaveFrom="opacity-100"
								leaveTo="opacity-0"
							>
								<Listbox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
									{options.map((option) => (
										<Listbox.Option
											key={option.id}
											className={({ active }) =>
												classNames(
													active ? "bg-gray-100 text-gray-900" : "text-gray-900",
													"relative cursor-default select-none py-2 pl-8 pr-4"
												)
											}
											value={option}
										>
											{({ selected, active }) => (
												<>
													<span className={classNames(selected ? "font-semibold" : "font-normal", "block truncate")}>
														{option.name}
													</span>

													{selected ? (
														<span
															className={classNames(
																active ? "text-white" : "text-indigo-600",
																"absolute inset-y-0 left-0 flex items-center pl-1.5"
															)}
														></span>
													) : null}
												</>
											)}
										</Listbox.Option>
									))}
								</Listbox.Options>
							</Transition>
						</div>
					</>
				)}
			</Listbox>
			{meta.touched && meta.error ? <div className="error text-red-600 text-xs">{meta.error}</div> : null}
		</>
	);
};
