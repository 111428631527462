import { FC, useRef } from "react";
import { Form, Formik, FormikProps } from "formik";
import { HrSelect } from "components/forms/select";
import { Button } from "components/UI/buttons";
import { FilterProps } from "models/filter";
import { useRoles } from "hooks/users/useRoles";
import { Filter } from "components/shared/filter";
import { SetURLSearchParams } from "react-router-dom";
import { MagnifyingGlassIcon, ArrowPathIcon } from "@heroicons/react/24/outline";
import { HrSearchInput } from "components/forms/searchInput";

export const ActiveUsersFilter: FC<{
	filters: URLSearchParams;
	results: number;
	setCurrentPage: React.Dispatch<React.SetStateAction<number>>
	setFilters: SetURLSearchParams;
}> = ({ filters, setFilters, results, setCurrentPage }) => {
	//create custom hook for filter params;
	const allParams = Object.fromEntries(filters.entries());
	const {userStatus, ...params} = allParams;
	const hasFilters = params && Object.keys(params).length > 0;
	const values = hasFilters ? params : { firstName: "", lastName: "", role_id: "" };
	const initialValues: FilterProps = values;
	const formikRef = useRef<FormikProps<FilterProps>>(null);
	const { data } = useRoles({select: (data) => data.rows});

	const onSubmit = (values: FilterProps) => {
		if (values.firstName !== "" || values.lastName !== "" || values["role_id"] !== "") {
			setFilters(values);
			setCurrentPage(1);
		} else {
			handleReset();
		}
	};

	const handleReset = () => {
		setFilters({userStatus: 'active'});
		setCurrentPage(1);
		formikRef.current?.resetForm();
	};

	return (
		<div>
			<Filter results={results} action={handleReset} hasFilters={hasFilters}>
				<Formik innerRef={formikRef} initialValues={initialValues} onSubmit={onSubmit} enableReinitialize>
					{() => (
						<Form className="flex gap-4 xs:flex-row mt-4 flex-wrap">
							<div className="flex gap-4 2xl:gap-14 flex-wrap items-center">
								<div>
									<HrSearchInput name="firstName" type="text" placeholder="Enter first name" />
								</div>
								<div>
									<HrSearchInput name="lastName" type="text" placeholder="Enter last name" />
								</div>
								{data && (
									<div className="flex flex-col xs:flex-row xs:items-center">
										<HrSelect placeholder="Select role" name="role_id" options={data} />
									</div>
								)}
							</div>
							<div className="flex gap-4 xs:ml-auto items-center flex-col xs:flex-row flex-wrap">
								<Button
									type="button"
									key="notsubmit"
									variant="transparent"
									onClick={() => handleReset()}
									className="flex-1"
								>
									<div className="flex items-center gap-2 w-max">
									<ArrowPathIcon className="h-4 w-4 text-primary-500" />
										Clear Filter</div>
								</Button>
								<Button type="submit" variant="primary" key="submit" className="flex-1">
									<div className="flex items-center gap-2 w-max">
										<MagnifyingGlassIcon className="h-4 w-4 text-white" />
										Filter
									</div>
								</Button>
							</div>
						</Form>
					)}
				</Formik>
			</Filter>
		</div>
	);
};
