import clsx from "clsx";
import { OpportunityFieldProps } from "components/opportunities/opportunityFieldProps";
import { FC, useEffect, useState } from "react";

const Textarea: FC<OpportunityFieldProps> = ({ value, onChange, readonly, definition }) => {
    const { name, caption, validation } = definition;
    const [isValid, setIsValid] = useState(true);
    const [length, setLength] = useState(0);

    useEffect(() => {
      const validate = validation.minLength <= value?.length || value === "" || value === undefined;
      setIsValid(validate)
     }, [value, setIsValid, validation])

  
    const handler = (e: any) => {
      const newValue = e.target.value;
      setLength(validation.minLength - newValue.length);
      const validate = validation.minLength <= newValue.length || newValue === "";
      setIsValid(validate);
      onChange?.(name, newValue, validate);
    };
  
    return readonly ? (
      <div>
        <label className="block text-sm font-medium leading-6 text-black-darker">{caption}</label>
        <div className="font-light text-sm" dangerouslySetInnerHTML={{ __html: value || "N/A" }}></div>
      </div>
    ) : (
      <div>
        <label className="block text-sm font-normal leading-6 text-black-darker">{caption}</label>
        <textarea
          value={Boolean(value) ? value : ""}
          onChange={handler}
          readOnly={readonly}
          name={name}
          className={clsx(
            "block w-full min-h-[50px] max-h-[200px] rounded border-0 px-3.5 py-2 text-gray-900 ring-1 ring-inset ring-gray-300", 
            "placeholder:text-gray-400 focus:ring-2 focus:ring-inset sm:text-sm sm:leading-6 outline-none",
            isValid ? "focus:ring-[#6c6ad3]" : "focus:ring-[#f87171]")}>
        </textarea>
        <div className="text-xs text-red mt-1 h-2 text-[#f87171]">
        {!isValid && <span>At least {validation.minLength} characters long({length} more missing).</span>}
          </div>
      </div>
    );
  };
  
  export default Textarea;