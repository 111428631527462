import { TrashIcon, UserIcon } from "@heroicons/react/24/outline";
import CloseIcon from "assets/icons/close";
import useConfirmModalStore from "components/shared/confirm-modal/confirmModalStore";
import { Modal } from "components/shared/modal";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { SearchInput } from "components/forms/mySearchInput";
import { ContactDetails } from "./contact-details/contactDetails";
import { ContactListItem } from "./contactsListItem";
import { ContactsProps } from "./contactsModel";
import { useDeleteContact } from "./useDeleteContact";
import { useParams } from "react-router-dom";
import { useFavoriteContact } from "./useToggleFavorite";
// import { checkIsAdmin } from "utilis/helpers";

interface Props {
	open: boolean;
	setIsOpen: Dispatch<SetStateAction<boolean>>;
	contacts: ContactsProps[];
	isMyOpportunity: boolean;
}

const ContactsList = ({ open, setIsOpen, contacts, isMyOpportunity }: Props) => {
	const [isEditOpen, setIsEditOpen] = useState(false);
	const [contact, setContact] = useState<ContactsProps>();
	const { mutate: removeContact } = useDeleteContact();
	const { mutate: toggleFavorite } = useFavoriteContact();
	const { opportunityId } = useParams();
	const { showModal } = useConfirmModalStore();
	const [searchTerm, setSearchTerm] = useState('');
	const [searchResults, setSearchResults] = useState<ContactsProps[]>([]);
	
	
	const handleClose = () => {
		setIsOpen(false);
		setSearchTerm('');
	};


	useEffect(() => {
		const searchContacts = (term: string) => {
			const filteredContacts = contacts.filter((contact) =>
			  contact.email.toLowerCase().includes(term.toLowerCase())
			);
		
			setSearchResults(filteredContacts);
		  };
		searchContacts(searchTerm);
	  }, [searchTerm, contacts]);


	const deleteContact = (id: string) => {
		showModal("Delete contact", () => removeContact({ opportunityId: opportunityId!, contactId: id }), {
			icon: (
				<div className="p-3 bg-red-100 rounded-full">
					<TrashIcon className="h-6 w-6 text-red-500" />
				</div>
			),
			description: "Are you sure you want to delete this Contact?",
			confirmText: "Delete contact",
			cancelText: "Close",
			confirmBtnColor: "warning",
		});
	};

	const handleItemClick = (actionType: string, contact: ContactsProps) => {
		switch (actionType) {
			case "deleteContact":
				deleteContact(contact.id);
				break;
			case "editContact":
				setContact(contact);
				setIsEditOpen(true);
				break;
			case "toggleFavorite":
                const data = {
					name: contact.name,
					email: contact.email,
					role: contact.role,
					isFavorite: contact.isFavorite ? false : true,
					contactId: contact.id,
					opportunityId: opportunityId!
				}
				toggleFavorite(data);
				break;
		}
	};

	const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		setSearchTerm(e.currentTarget.value);
	};

	return (
		<>
			<Modal open={open} setIsOpen={setIsOpen} staticModal size="2xl">
				<div className="flex justify-between stify-between items-center mb-12">
					<div className="flex items-center gap-3">
						<div className="rounded-full p-3 bg-primary-100">
							<UserIcon className="h-4 w-4 text-primary-500"> </UserIcon>
						</div>
						<h2 className="text-xl font-medium"> Contacts </h2>
					</div>
					<button onClick={handleClose} className="h-full">
						<CloseIcon />
					</button>
				</div>
				<div>
					<div className="mb-6">
						<SearchInput onChange={handleOnChange} placeholder="Search for contacts" type="text" name="searchTerm" />
					</div>
					{searchResults.length > 0 ?
					searchResults.map((contact, index) => (
						<ContactListItem key={contact.id} isMyOpportunity={isMyOpportunity} contact={contact} handleItemClick={handleItemClick} />
					)) : "No filters for current result" }
				</div>
				{isEditOpen && <ContactDetails open={isEditOpen} setIsOpen={setIsEditOpen} contact={contact} />}
			</Modal>
		</>
	);
};

export default ContactsList;
