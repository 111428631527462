import React from "react";
import { useField } from "formik";
import { FormTextInput } from "models/forms";
import exlamation from "assets/img/exclamation.png";
import classNames from "classnames";


export const HrTextInput: React.FC<FormTextInput> = ({ label, field: inputField, form, unfreezeinput, ...props }) => {
	const [field, meta] = useField(props);
	//this resolve input freeze after logout. This is just temporarly until we find better way
	const unfreezeInput = (el: React.MouseEvent<HTMLInputElement>) => {
		el.currentTarget.value = el.currentTarget.value;
	}
	return (
		<>
			{label ? (
				<label
					className={`block text-sm font-normal leading-6 text-black-darker ${
						meta.touched && meta.error ? "text-red-600" : ""
					}`}
				>
					{label}
				</label>
			) : (
				""
			)}
			<input
				className={classNames(`block w-full min-w-[15rem] rounded-md p-2 focus:outline-none disabled:bg-gray-100  placeholder:text-gray-300  border-solid border sm:text-sm sm:leading-[1.875rem] bg-no-repeat bg-[right_10px_bottom_11px]`,
				props.className,
                {"border-red-600": meta.touched && meta.error, "border-gray-300": !meta.touched && !meta.error})}
				style={{ backgroundImage: meta.touched && meta.error ? `url(${exlamation})` : "" }}
				{...props}
				{...field}
				onClick={unfreezeinput ? unfreezeInput : () => {}}
				autoComplete={props.autoComplete || 'on'}
			/>
			{meta.touched && meta.error ? <div className="error text-red-600 text-xs">{meta.error}</div> : null}
		</>
	);
};
