import { FC } from "react"

export const InputDisabledOnly: FC<any> = ({ placeholder, value, label, disabled, options, width, onChange, ...props }) => {
	return(
		<div>
			<label
				className={`block text-sm font-normal leading-6 text-black-darker`}
			>
				{label}
			</label>
			<input value={value} placeholder={placeholder} disabled className="block w-full min-w-[15rem] rounded-md p-2 focus:outline-none disabled:bg-gray-100  placeholder:text-gray-300  border-solid border sm:text-sm sm:leading-[1.875rem] bg-no-repeat bg-[right_10px_bottom_11px] border-gray-300"></input>
		</div>
	)
}