import useToastStore from "components/shared/toast/toastStore";
import { checkIsAE } from "utilis/helpers";
import classNames from "classnames";
import { CompletedStep, Locked, Unlocked } from "./svgs";

interface StepProps {
    active: boolean;
    stepNumber: string | number;
    title: string;
    completed: boolean;
    unlocked: boolean,
    points: number;
    phase: string;
}

interface Props {
    step: StepProps;
    isLast: boolean;
    setPhase: (s: string) => void;
    activeTab: number;
}
const Step = ({step, setPhase, activeTab}: Props) => {
    const { showToast } =  useToastStore();
    const isAE = checkIsAE();
    const isOverviewTab = activeTab === 0;
    const { active, title, completed, points, unlocked, phase } = step;

    const changePhase = () => {
        if(isOverviewTab) return;
        if (!isAE) {
          return;
        }

        if (!active && !completed && !unlocked) {
            return showToast('Stage is not unlocked.', {type: 'warning', position: 'top'})
        }
        setPhase(phase);
    }

    const maxPoints = () => {
        if(phase === 'pretrial'){
            return 50
        }

        if(phase === 'trial') {
            return 30
        }

        return 50
    }

    return(
        <>
        <div 
            className={classNames(
            active ? "bg-[#F0F0FF]" : "", 
            "flex gap-[10px] w-full md:w-[160px]  rounded items-center justify-center h-10", 
             isAE && activeTab === 2 ? "cursor-pointer" : "cursor-not-allowed", 
            unlocked && isAE && activeTab === 2 ? "hover:bg-[#F0F0FF]" : "" ,
            )} 
            onClick={changePhase}>
            {completed ? <CompletedStep /> : unlocked ? <Unlocked /> : <Locked />}
            <div className={active || unlocked ? "text-[#413F9B]" : "text-[#81828D]"}>
                <div className="text-sm font-medium">{title}</div>
                <div className="text-xs"> {Math.round(points)}pt / {maxPoints()}pt</div>
            </div>
        </div>
        </>
    )
}

export default Step;