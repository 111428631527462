import Score from "../score";
import GeneralDetails from "./general-details/generalDetails";
import { OpportunityProps, ScoreProps } from "../../opportunitiesModel";
import { AccountsProps } from "pages/accounts-overview/accountsOverviewModel";

interface Props {
    opportunity: OpportunityProps;
    account: AccountsProps;
    score: ScoreProps;
    activeTab: number;
    isMyOpportunity: boolean;
}

const MainDetailsWidget = ({opportunity, account, score, activeTab, isMyOpportunity}: Props) => {
    return(
        <div className="bg-white w-full flex flex-col gap-3 p-2 rounded">
            <div className="w-full flex flex-col md:flex-row gap-3 items-stretch">
                <GeneralDetails opportunity={opportunity} account={account} isMyOpportunity={isMyOpportunity} />
                <Score score={score} activeTab={activeTab} />
            </div>
        </div>
    )
};

export default MainDetailsWidget;