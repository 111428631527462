import CloseIcon from "assets/icons/close";
import { Button } from "components/UI/buttons";
import { 
	// ArrayHelpers, 
	// FieldArray, 
	Form, 
	Formik, 
	FormikHelpers } from "formik";
import { Dispatch, FC, SetStateAction } from "react";
import * as Yup from "yup";
import { HrSelect } from "components/forms/select";
import { Modal } from "components/shared/modal";
import { 
	// ChevronUpIcon, 
	UserIcon } from "@heroicons/react/24/outline";
import { Spinner } from "components/shared/spinner";
// import classNames from "classnames";
import { useActiveUsers } from "pages/admin/active-users-list/useActiveUsers";
import { useUserStore } from "store/userStore";
import { SelectOptionsData } from "models/forms";
import { checkIsSDR } from "utilis/helpers";
import { ActiveUsersData } from "pages/admin/active-users-list/activeUsersModel";
import { OpportunityEditProps } from "./opportunityEditModel";
import { AccountsProps } from "pages/accounts-overview/accountsOverviewModel";
import { OpportunityProps } from "../opportunitiesModel";
import { useOpportunityEdit } from "./useOpportunityEdit";
import { useGeogeaphies } from "hooks/accounts/useGeographies";
// import { Disclosure } from "@headlessui/react";
// import Avatar from "components/shared/avatar";
// import { ContactForm } from "../opportunityContactForm";
// import { regexValidation } from "utilis/regex";

interface Props {
	account: AccountsProps;
	open: boolean;
	setIsOpen: Dispatch<SetStateAction<boolean>>;
	opportunity: OpportunityProps;
}

export const OpportunityEdit: FC<Props> = ({ open, setIsOpen, account, opportunity }) => {
	// const EMAIL_REGX = regexValidation.EMAIL_REGX;
	const geographies = useGeogeaphies();
	const { data: users } = useActiveUsers({}, { limit: 100, currentPage: 1 });
	const { user } = useUserStore();
	const isSDR = checkIsSDR()!;
	const opportunityValues = {
		// name: opportunity.name,
		industry: account.industry,
		sdrId: opportunity.sdrId,
		aeId: opportunity.aeId,
		contacts: opportunity.contacts.map(({name, email, role}) => {const contact = {name, email, role}; return contact }),
		regionId: opportunity.regionId
	};
	// let contactIndex = opportunity.contacts.length;
	let sdrOptions: SelectOptionsData[] | undefined = [];
	let aeOptions: SelectOptionsData[] | undefined = [];
	const { mutate: editOpportunity } = useOpportunityEdit(isSDR);
	const initialValues: OpportunityEditProps = opportunityValues || {
		// name: "",
		sdrId: isSDR ? user?.id : "",
		aeId: !isSDR ? user?.id : "",
		industryId: account.industry,
		contacts: [],
		regionId: opportunity.regionId
	};

	const filterOptions = () => {

		let sdrUsers: ActiveUsersData[] | undefined;
		let aeUsers: ActiveUsersData[] | undefined;
		aeUsers = users?.rows.filter((user) => {
			return user.assignedRoles.some(({ role }) => role.name === "AE");
		});

		sdrUsers = users?.rows.filter((user) => {
			return user.assignedRoles.some(({ role }) => role.name === "SDR");
		});
		sdrOptions = sdrUsers?.map((user) => {
			const data = {
				id: user.id,
				name: user.firstName,
			};
			return data;
		});
		aeOptions = aeUsers?.map((user) => {
			const data = {
				id: user.id,
				name: user.firstName,
			};
			return data;
		});
	};

	if (users) {
		filterOptions();
	}

	const onSubmit = (values: OpportunityEditProps, { setSubmitting }: FormikHelpers<OpportunityEditProps>) => {
		if (!values.aeId) delete values.aeId;

		const data = {
			...values,
			id: opportunity.id,
		};

		editOpportunity(data, { onSuccess: () => handleClose(), onError: () => setSubmitting(false) });
	};

	const AEValidation = Yup.object({
		// name: Yup.string().required("Name can not be empty"),
		sdrId: Yup.string().required("Assignee can not be empty"),
		// contacts: Yup.array().of(
		// 	Yup.object().shape({
		// 		name: Yup.string().required("Name can not be empty"),
		// 		email: Yup.string().matches(EMAIL_REGX, "Invalid email address"),
		// 		role: Yup.string().required("Role can not be empty"),
		// 	})
		// ),
	});

	const SDRValidation = Yup.object({
		// name: Yup.string().required("Name can not be empty"),
		// contacts: Yup.array().of(
		// 	Yup.object().shape({
		// 		name: Yup.string().required("Name can not be empty"),
		// 		email: Yup.string().matches(EMAIL_REGX, 'Invalid email address'),
		// 		role: Yup.string().required("Role can not be empty"),
		// 	})
		// ),
	});

	const formValidation = isSDR ? SDRValidation : AEValidation;

	const handleClose = () => {
		setIsOpen(false);
	};

	// const handleRemove = () => {
	// 	contactIndex--
	// };

	return (
		<>
			{sdrOptions && aeOptions && (
				<Modal open={open} setIsOpen={setIsOpen}>
					<div className="flex justify-between items-center mb-3">
						<div className="flex items-center gap-3">
							<div className="rounded-full p-3 bg-primary-100">
								<UserIcon className="h-4 w-4 text-primary-500"> </UserIcon>
							</div>
							<h2 className="text-xl font-medium"> Edit opportunity </h2>
						</div>

						<button onClick={handleClose} className="h-full">
							<CloseIcon />
						</button>
					</div>
					<Formik initialValues={initialValues} validationSchema={formValidation} onSubmit={onSubmit}>
						{({ isSubmitting, values }) => (
							<Form>
								<div>
									{/* <div className="mb-5">
										<HrTextInput label="Opportunity name" name="name" type="text" placeholder="Opportunity name" />
									</div> */}
									{/* <div className="mb-5">
										<HrSelect
											placeholder={"Choose Industry"}
											label="Industry"
											name='industry'
											// disabled={!selectedOpportunity}
											options={industries?.data?.rows.map(industry => ({id: industry.id, name: industry.name})) || []}
										/>
									</div> */}
									<div className="mb-5">
										<HrSelect
											placeholder={"Choose Geography"}
											label="Geography"
											name='regionId'
											// disabled={!selectedOpportunity}
											options={geographies.data?.map(geography => ({id: geography.id, name: geography.name})) ||  []}
										/>
									</div>
									<div className="mb-5">
										<HrSelect
											placeholder="Choose SDR"
											label="Sales Development Representative"
											name="sdrId"
											disabled={true}
											options={sdrOptions!}
										/>
									</div>
									<div className="mb-5">
										<HrSelect
											placeholder="Choose AE"
											label="Account Executive"
											name="aeId"
											disabled={true}
											options={aeOptions!}
										/>
									</div>
									{/* <div className="mb-6">
										<FieldArray name="contacts">
											{({ push, remove }: ArrayHelpers) => (
												<div className="relative">
													<h3 className="text-xl font-medium mb-3"> Contacts </h3>
													{values.contacts.map((contact, index) => (
														<div key={index}>
															{index > contactIndex - 1 && (
																<div className="p-3 bg-gray-50 mt-4 border border-gray-100 rounded-md">
																	<ContactForm index={index} values={values.contacts} remove={remove} />
																</div>
															)}

															{index <= contactIndex - 1 && (
																<Disclosure>
																	{({ close, open }) => (
																		<div
																			className={classNames({
																				"p-3 bg-gray-50 mb-5 border border-gray-100 rounded-md": open,
																			})}
																		>
																			<Disclosure.Button className="py-2 flex justify-between w-full items-center">
																				<div className="flex items-center gap-3">
																					<Avatar userName={contact.name} />
																					{contact.name || "Open to add contact"}
																				</div>
																				<ChevronUpIcon className="ui-open:rotate-180 h-5 w-5 mr-4 text-primary" />
																			</Disclosure.Button>

																			<Disclosure.Panel className="text-gray-500">
																				<ContactForm handleRemove={handleRemove} values={values.contacts} index={index} remove={remove} />
																			</Disclosure.Panel>
																		</div>
																	)}
																</Disclosure>
															)}
														</div>
													))}
													<div className="absolute top-0 right-0">
														<Button
															variant="transparent"
															className="ml-auto !py-1"
															type="button"
															onClick={() => { push({ name: "", email: "", role: "" });}}
														>
															Add contacts
														</Button>
													</div>
												</div>
											)}
										</FieldArray>
									</div> */}
								</div>
								<div className="flex gap-4 flex-wrap">
									<Button type="button" variant="secondary" onClick={handleClose} className="flex-1">
										Cancel
									</Button>
									<Button type="submit" variant="primary" className="flex-1">
										{isSubmitting ? <Spinner className="h-5 w-5 fill-gray-100" /> : "Save changes"}
									</Button>
								</div>
							</Form>
						)}
					</Formik>
				</Modal>
			)}
		</>
	);
};
