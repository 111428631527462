import { create } from "zustand";


interface MeetingCountStoreProps {
    meetingCount: number;
	setMeetingsCount: (count: number) => void;
}

export const useMeetingCountStore = create<MeetingCountStoreProps>((set) => ({
	meetingCount: 0,
	setMeetingsCount: (count: number) => set(() => ({meetingCount: count })),
}));