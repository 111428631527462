
import { useQuery } from "@tanstack/react-query";
import { getOpportunity } from "api/opportunity";


export const useOpportunityById = (id: string) => {
	return useQuery({
        queryKey: ['opportunityById', id],
		queryFn: () =>  getOpportunity(id),
		keepPreviousData: true
	});
};