import { ToastStoreProps } from "models/toast";
import { create } from "zustand";


const useToastStore = create<ToastStoreProps>((set) => ({
	message: "",
	options: {type: 'default', position: 'top-right', description: ''},
	isVisible: false,
	showToast: (message, options) =>
		set((state) => ({
			options: {
				...state.options,
				...options,
			},
			message,
			isVisible: true,
		})),
	hideToast: () => set({ isVisible: false }),
	reset: () => set({options: {type: 'default', position: 'top-right', description: ''}})
}));

export default useToastStore;
