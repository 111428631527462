import { useMutation, useQueryClient } from "@tanstack/react-query";
import { editMeeting } from "api/meetings";
import { AxiosError } from "axios";
import useToastStore from "components/shared/toast/toastStore";

type error = {
	field: string;
};

export const useEditMeeting = () => {
	const queryClient = useQueryClient();
	const { showToast } = useToastStore();
	return useMutation({
		mutationFn: editMeeting,
		onSuccess: () => {
			showToast("Meeting successfully updated", { type: "success", position: "top" });
			queryClient.invalidateQueries(["meetings"]);
		},
		onError: (errorData: AxiosError<{ errors: error[] }>) => {
			if (errorData.response?.data.errors) {
				errorData.response?.data.errors.forEach((error) => {
					if (errorData.response?.status === 400 && error.field === "startsAt") {
					 return	showToast("Start time must be greater then current time", { type: "error", position: "top-right" });
					}
					if (errorData.response?.status === 400 && error.field === "endsAt") {
					return showToast("End time must be greater then start time", { type: "error", position: "top-right" });
					}
					if (errorData.response?.status === 400 && error.field === "email") {
						return showToast("All participants must have valid email address", { type: "error", position: "top-right" });
					}
				});
			}
		},
	});
};
