import emptyFilters from "assets/img/emptyFilters.png";
import { Button } from "components/UI/buttons";

interface Props {
	action?: () => void
	title?: string;
	content?: string;
}

export const EmptyFilters = ({ action, title, content }: Props) => {
	return (
		<div className="flex items-center text-center flex-col">
			<div className="max-w-sm">
				<img src={emptyFilters} alt="empty-filter" className="mb-6 mx-auto" />
				<p className="text-2xl font-medium mb-3"> {title ? title : 'No results found' } </p>
				<p className="text-base font-regular mb-3">
					{ content ? content : 'No results match the filter search. You can try another filter or' }
				</p>
			    {action && <Button variant="secondary" type="button" onClick={action} className="mx-auto">
					Clear all filters
				</Button> }
			</div>
		</div>
	);
};
