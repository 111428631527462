import CloseIcon from "assets/icons/close";
import { Button } from "components/UI/buttons";
import { Form, Formik, FormikHelpers } from "formik";
import { Dispatch, FC, SetStateAction } from "react";
import * as Yup from "yup";
import { Modal } from "components/shared/modal";
import { DocumentTextIcon, PencilIcon } from "@heroicons/react/24/outline";
import { Spinner } from "components/shared/spinner";
import { HrTextArea } from "components/forms/textArea";
import { MeetingNoteProps, MeetingProps } from "../meetingsModel";
import { useAddMeetingNote } from "./useAddMeetingNote";

interface Props {
	open: boolean;
	setIsOpen: Dispatch<SetStateAction<boolean>>;
	meeting: MeetingProps;
}

export const MeetingNote: FC<Props> = ({ open, setIsOpen, meeting }) => {
	const initialValues: MeetingNoteProps = { id: meeting.id, notes: "" };
	const { mutate: addMeetingNote } = useAddMeetingNote();

	const onSubmit = (values: MeetingNoteProps, { setSubmitting }: FormikHelpers<MeetingNoteProps>) => {
		addMeetingNote(values, { onSuccess: () => handleClose(), onError: () => setSubmitting(false) });
	};

	const formValidation = Yup.object({
		notes: Yup.string().required("Note can not be empty"),
	});

	const handleClose = () => {
		setIsOpen(false);
	};

	return (
		<>
			<Modal open={open} setIsOpen={setIsOpen}>
				<div className="flex justify-between items-center mb-3">
					<div className="flex items-center gap-3">
						<div className="rounded-full p-3 bg-primary-100">
							{meeting.status !== "finished" ? (
								<PencilIcon className="h-4 w-4 text-primary-500"> </PencilIcon>
							) : (
								<DocumentTextIcon className="h-4 w-4 text-primary-500" />
							)}
						</div>
						<h2 className="text-xl font-medium">
							{meeting.status !== "finished" ? "Finish meeting" : "Meeting Notes"}{" "}
						</h2>
					</div>

					<button onClick={handleClose} className="h-full">
						<CloseIcon />
					</button>
				</div>
				<Formik initialValues={initialValues} validationSchema={formValidation} onSubmit={onSubmit}>
					{({ isSubmitting }) => (
						<Form>
							<div>
								<div className="mb-4">
									{meeting.status !== "finished" ? (
										<HrTextArea label="Meeting note" name="notes" type="text" placeholder="Add meeting note..." />
									) : (
										<p className="text-sm font-regular"> {meeting.notes}</p>
									)}
								</div>
							</div>
							{meeting.status !== "finished" && (
								<div className="flex gap-4">
									<Button type="button" variant="secondary" onClick={handleClose} className="flex-1">
										Cancel
									</Button>
									<Button type="submit" variant="primary" className="flex-1" disabled={isSubmitting}>
										{isSubmitting ? <Spinner className="h-5 w-5 fill-gray-100" /> : "Finish meeting"}
									</Button>
								</div>
							)}
						</Form>
					)}
				</Formik>
			</Modal>
		</>
	);
};
