import axios, { AxiosError, AxiosResponse } from "axios";
import { getLocalStorage, setLocalStorage } from "./storage";
import { getRefreshToken } from "api/auth";

export const axiosInstance = axios.create({
	baseURL: process.env.REACT_APP_BE_URL,
});

axiosInstance.interceptors.request.use(
	(config) => {
		const token = getLocalStorage<string>('accessToken');
		if (config.url !== "v1/auth/refresh") {
			const auth = token ? `Bearer ${token}` : "";
			config.headers.Authorization = auth;
		}

		return config;
	},
	(error) => Promise.reject(error)
);

let isRefreshing = false;
let failedQueue: {resolve: (value: unknown) => void, reject: (reason?: any) => void}[]= [];

const processQueue = (error: AxiosError | null, token: string | undefined = undefined) => {
	failedQueue.forEach((prom) => {
		if (error) {
			prom.reject(error);
		} else {
			prom.resolve(token);
		}
	});

	failedQueue = [];
};

axiosInstance.interceptors.response.use(
	(response: AxiosResponse) => {
		return response;
	},
	(error) => {
		const originalRequest = error.config;
		if (error?.response?.status === 401 && !originalRequest?._retry && originalRequest.url !== "/v1/auth/refresh") {
			if (isRefreshing) {
				return new Promise((resolve, reject) => {
					failedQueue.push({ resolve, reject });
				})
					.then((token) => {
						originalRequest.headers["Authorization"] = "Bearer " + token;
						return axiosInstance(originalRequest);
					})
					.catch((err) => {
						return Promise.reject(err);
					});
			}

			originalRequest._retry = true;
			isRefreshing = true;

			return new Promise(function (resolve, reject) {
				getRefreshToken()
					.then((data) => {
						setLocalStorage('accessToken', data?.accessToken);
						setLocalStorage<string>('refreshToken', data?.refreshToken);
						axiosInstance.defaults.headers.common["Authorization"] = "Bearer " + data?.accessToken;
						originalRequest.headers["Authorization"] = "Bearer " + data?.accessToken;
						processQueue(null, data?.accessToken);
						resolve(axiosInstance(originalRequest));
					})
					.catch((err: AxiosError) => {
						processQueue(err, undefined);
						window.location.href = "/login";
			            localStorage.clear();
						reject(err);
					})
					.finally(() => {
						isRefreshing = false;
					});
			})
		}

		return Promise.reject(error);
	}
);
