import HomerunLogoIcon from "assets/icons/homerun-logo";
import {HomerunTextIcon } from "assets/icons/homerun-text";
import RegisterExpiredImg from "assets/img/register-expired.png";
import { Button } from "components/UI/buttons";

export const ExpiredRegisterLink = () => {
	return (
		<div className="p-6 h-full">
			<div className="flex gap-1 items-center">
				<HomerunLogoIcon />
				<HomerunTextIcon color="black" />
			</div>
            <div className="h-full flex flex-col justify-center items-center ">
                <div className="max-w-prose flex flex-col items-center justify-center text-center">
                <img src={RegisterExpiredImg} alt="expired-link" className="mb-6"></img>
                <h3 className="text-2xl font-medium mb-2"> Register link has expired! </h3>
                <p className="text-base mb-2">
                Hi there magic link has expired, because you haven't used it yet! Magic link expires every 24 hours and can only be used once.
                </p>
                <p className="text-lg font-medium mb-6"> 
                You can create one by clicking on Request another link button. 
                </p>
                <Button type="button" variant="primary" className="w-full"> Request another link </Button>
                </div>
            </div>
		</div>
	);
};
