import React from 'react';
import { useField } from 'formik';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import classNames from 'classnames';
import { FormDatePickerInput } from 'models/forms';

export const HrDateTimePicker: React.FC<FormDatePickerInput> = ({ label, startDate = undefined, endDate = undefined, field: inputField, form, ...props }) => {

  const [field, meta, helpers] = useField(props);

  const filterPassedTime = (time: Date) => {
    const currentDate = new Date();
    const selectedDate = new Date(time);


    // if (endDate) {
    //   return endDate.getTime() > selectedDate.getTime();
    // }

    if (startDate) {
     return startDate.getTime() < selectedDate.getTime();
    }

    return currentDate.getTime() < selectedDate.getTime();
  };

   const handleDateChange = (newDate: Date) => {
    const minutes = newDate.getMinutes();
    const hours = newDate.getHours();

    if (hours === 0 && minutes === 0) {
      const currentTime = new Date();
      const minutes = startDate ? startDate.getMinutes() : currentTime.getMinutes();
      const roundedMinutes = Math.ceil(minutes / 30) * 30;
      newDate.setHours(startDate ? startDate.getHours() : currentTime.getHours(), roundedMinutes);
      helpers.setValue(newDate);
      return;
    }

    if (startDate && newDate < startDate) {
      return helpers.setValue(startDate);
    }

    helpers.setValue(newDate);
  };
  
  return (
    <> 
    			{label ? (
				<label
					className={`block text-sm font-normal leading-6 text-black-darker ${
						meta.touched && meta.error ? "text-red-600" : ""
					}`}
					htmlFor={props.id || props.name}
				>
					{label}
				</label>
			) : (
				""
			)}
    <DatePicker
      wrapperClassName="w-full"
      {...field}
      {...props}
      maxDate={endDate}
      minDate={startDate}
      showTimeSelect
      filterTime = {filterPassedTime}
      autoComplete='off'
      customTimeInput={<input />}
      placeholderText={props.placeholder}
      dateFormat="MMMM d, yyyy h:mm aa"
      selected={(field.value && new Date(field.value)) || null}
      onChange={handleDateChange}
      className={classNames(props.className,
        `block w-full rounded-md p-2 focus:outline-none disabled:bg-gray-100  placeholder:text-gray-300  border-solid border sm:text-sm sm:leading-[1.875rem] bg-no-repeat bg-[right_10px_bottom_11px]`,
      {"border-red-600": meta.touched && meta.error, "border-gray-300": !meta.touched && !meta.error})}
    />
    <div> {meta.touched && meta.error ? <div className="error text-red-600 text-xs">{meta.error}</div> : null}
    </div>
    </>
  );
};