import { Form, Formik, FormikHelpers } from "formik";
import * as Yup from "yup";
import { RegisterFormData } from "./registerModel";
import { Button } from "components/UI/buttons";
import { HrTextInput } from "components/forms/textInput";
import { useState } from "react";
import { PasswordValidation } from "components/shared/passwordValidation";
import { ShowPassword } from "components/shared/showPassword";
import { useSearchParams } from "react-router-dom";
import { useRegister } from "./useRegister";
import { hasLowerCase, hasSpecialChars, hasUpperCase } from "utilis/helpers";

const Register = () => {
	const [searchParams] = useSearchParams();
	const inviteToken = searchParams.get('token');
	const [showPassword, setShowPassword] = useState(false);
	const initialValues: RegisterFormData = { firstname: "", lastname: "", password: "" };
	const {mutate: register} = useRegister();

	const onSubmit = (values: RegisterFormData, helpers: FormikHelpers<RegisterFormData>) => {
		const registerData = {
			...values,
			inviteToken
		}
		register(registerData);
	};

	const formValidation = Yup.object({
		firstname: Yup.string().required("First name can not be empty").max(40, 'First name length must be less than or equal to 40 characters long'),
		lastname: Yup.string().required("Last name can not be empty").max(40, 'Last name length must be less than or equal to 40 characters long'),
		password: Yup.string()
			.required("Password can not be empty")
			.min(10, "")
			.test("hasLowerCase", "", (val) => hasLowerCase(val))
			.test("hasUpperCase", "", (val) => hasUpperCase(val))
			.test("hasSpecChar", "", (val) => hasSpecialChars(val)),
	});

	return (
		<>
			<div className="mb-4">
				<h3 className="text-2xl text-black font-medium"> Register </h3>
			</div>
			<div className="mt-0">
				<Formik initialValues={initialValues} validationSchema={formValidation} onSubmit={onSubmit}>
					{({ isSubmitting, errors, values }) => (
						<Form>
							<div className="mb-6">
								<HrTextInput label="First name" name="firstname" type="text" placeholder="Enter first name" />
							</div>
							<div className="mb-6">
								<HrTextInput label="Last name" name="lastname" type="text" placeholder="Enter last name" />
							</div>
							<div className="relative mb-2.5">
								<HrTextInput
									label="Password"
									name="password"
									type={showPassword ? "text" : "password"}
									placeholder="Enter password"
								/>
                             <ShowPassword hasError = {errors.password} showPassword={showPassword} togglePassword = {setShowPassword} />
							</div>
							<div className="mb-6">
							   <PasswordValidation value={values.password} />
							</div>
							<div className="flex sm:flex-row flex-col">
								<Button type="submit" variant="primary" className="grow-2">
									Register
								</Button>
							</div>
						</Form>
					)}
				</Formik>
			</div>
		</>
	);
}

export default Register;
