import { HrMenu } from "components/UI/hrMenu";
import { FC } from "react";
import { ActiveUsersData } from "../activeUsersModel";
import { NoSymbolIcon, TrashIcon } from "@heroicons/react/24/outline";
import Avatar from "components/shared/avatar";

export const ActiveUsersListItem: FC<{
	isActiveUser?: boolean;
	user: ActiveUsersData;
	handleMenuClick: (actionType: string, user: ActiveUsersData) => void;
}> = ({ handleMenuClick, user, isActiveUser }) => {
	const menuItems = [
		{
			title: "Delete User",
			icon: <TrashIcon className="text-red-500" width={20} height={20} />,
			actionType: "deleteUser",
		},
		{
			title: !user.blockedAt ? "Block user" : "Unblock user",
			icon: <NoSymbolIcon className="text-red-500" width={20} height={20} />,
			actionType: !user.blockedAt ? "blockUser" : "unblockUser",
		},
	];
	const handleItemClick = (actionType: string) => {
		handleMenuClick(actionType, user);
	};

	return (
		<>
			<div className="py-3 flex justify-between items-center">
				<div className="flex gap-3 items-center">
				<Avatar userName={`${user.firstName} ${user.lastName}`} />
					<div>
						<div className="flex gap-2">
							<p className="font-base font-medium">
								{user.firstName} {user.lastName}
							</p>
						</div>
						<p className="text-xs font-regular">
							<span className="text-gray-400 mr-1"> Email:</span>
							{user.emailData.email}
						</p>
					</div>
	
				</div>
				<div className="flex items-center gap-4">
					{user.blockedAt && <span className="inline-flex items-center rounded-full bg-red-100 text-red-500 px-3.5 py-1 text-xs font-regular ring-1 ring-inset ring-red-600/10">
								Blocked
							</span> }
					<HrMenu handleMenuClick={handleItemClick} menuItems={menuItems} />
				</div>
			</div>
		</>
	);
};
