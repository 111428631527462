import { getPendingUsers } from "api/user";
import { useQuery } from "@tanstack/react-query";
import { FilterProps } from "models/filter";
import { PaginationApiProps } from "models/pager";


export const usePendingUsers = (filters: FilterProps = {}, paginationData: PaginationApiProps = {limit: 10, currentPage: 1}) => {

	return useQuery({
        queryKey: ['pending-users', filters, paginationData],
		queryFn: () =>  getPendingUsers(filters, paginationData),
		onSuccess: (data) => {
		},
        onError: (err) => {
        },
		keepPreviousData: true
	});
};