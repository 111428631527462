import Avatar from "components/shared/avatar";
import { HRPopover } from "components/shared/popover";
import { OpportunityRoleProps } from "pages/account-details/opportunities/opportunitiesModel";

interface Props {
    title: string;
    list: OpportunityRoleProps[];
}

const Team = ({title, list}: Props) => {
    return(
        <div className="">
            <div className="text-base font-bold mb-3">{title}</div>
            <div className="flex justify-between items-center">
                <div className="flex gap-2">
                    {list.length > 0 ? 
                    list.map((item, index) => (
                        <HRPopover 
                        content={
                            <div>
                                <div>{item.firstName + " " + item.lastName}</div>
                                <div>{item.emailData.email}</div>
                                <div>{item.role}</div>
                            </div>} 
                        key={item.emailData.email}
                        >
                            <Avatar userName={item.firstName + " " + item.lastName}  width={32} height={32} />
                        </HRPopover>
                    ))
                    :
                    "No team members added." 
                    }
                </div>
            </div>
        </div>
    )
}

export default Team;