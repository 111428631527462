import { Sidebar } from "components/shared/sidebar";
import { useEffect } from "react";
import { Navigate, Outlet, useSearchParams } from "react-router-dom";
import { getLocalStorage } from "utilis/storage";

export const Homepage = () => {
	let [searchParams, setSearchParams] = useSearchParams();
	const token = getLocalStorage('accessToken');


	useEffect(() => {
		if (searchParams.get('azureLogin')) {
			searchParams.delete('azureLogin');
			setSearchParams(searchParams)
		}
	}, [searchParams, setSearchParams])
	
    if(!token) {
		return <Navigate to="/login" />
	}

    return (
		<>
			<Sidebar />
			<main className="p-3 lg:ml-72 bg-gray-50 h-full">
			    <div className="pb-5">
                   <Outlet />
				</div>
			</main>
		</>
	);
}