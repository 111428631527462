import { PlusIcon } from "@heroicons/react/24/outline"
import { Button } from "components/UI/buttons"
import { AddMeeting } from "./meetings-add/addMeeting"
import { useState } from "react"
import { useParams } from "react-router-dom"
import { MeetingsList } from "./meetings-list/meetingsList"
import { useUserStore } from "store/userStore"
import { FilterMeetings } from "./meetings-filter/filterMettings"
import classNames from "classnames"

interface Props {
    readonly?: boolean;
    hideFilters?: boolean
}

export const Meetings = ({readonly = false, hideFilters}: Props) => {
    const [open, setIsOpen] = useState(false);
    const [meetingStatus, setMeetingStatus] = useState<string[]>([]);
    const {opportunityId} = useParams();
    const { user } = useUserStore();



   const handleCreateMeeting = () => {
     if (user?.azureAuthorized) {
       setIsOpen(true);
       return;
     }
     
     const redirectUrl = `${window.location.href}/?azureLogin=true`;
     const azureUrl = `${process.env.REACT_APP_AZURE_LOGIN}/?redirectUrl=${redirectUrl}`;
     window.location.href = azureUrl;
        
    }

    const handleMeetingFilter = (items: string[]) => {
        setMeetingStatus(items);
    }

    return <> 
    <div className="p-4 bg-white flex-1">
        <div className="mb-4">
             <h3 className="text-xl font-medium"> All meetings</h3>
             <p className="text-xs font-regular mb-3"> All upcoming meetings for this opportunity </p>
            
             <div className={classNames("flex items-center", {'justify-end': readonly, 'justify-between': !readonly})}> 
             {!readonly && <Button variant="secondary" type="button" className="!px-3 !py-2" onClick={handleCreateMeeting}>
                <PlusIcon className="h-4 w-w-4 mr-1" />
                Create meeting
             </Button> }
             {!hideFilters && <FilterMeetings meetingFilter = {handleMeetingFilter}  /> }
             </div> 
        </div>
        <div>
            <MeetingsList readonly={readonly} opportunityId={opportunityId!} status={meetingStatus} />
        </div>
    </div>
    {open && <AddMeeting open={open} opportunityId={opportunityId!} setIsOpen={setIsOpen}/> }
    </>
}