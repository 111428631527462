function CopyToClipboardIcon() {
	return (
		<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
			<g id="clipboard-copy">
				<path
					id="Vector"
					d="M6.66665 4.16673H4.99998C4.55795 4.16673 4.13403 4.34232 3.82147 4.65488C3.50891 4.96744 3.33331 5.39137 3.33331 5.83339V15.8334C3.33331 16.2754 3.50891 16.6993 3.82147 17.0119C4.13403 17.3245 4.55795 17.5001 4.99998 17.5001H13.3333C13.7753 17.5001 14.1993 17.3245 14.5118 17.0119C14.8244 16.6993 15 16.2754 15 15.8334V15.0001M6.66665 4.16673C6.66665 4.60876 6.84224 5.03268 7.1548 5.34524C7.46736 5.6578 7.89129 5.83339 8.33331 5.83339H9.99998C10.442 5.83339 10.8659 5.6578 11.1785 5.34524C11.4911 5.03268 11.6666 4.60876 11.6666 4.16673M6.66665 4.16673C6.66665 3.7247 6.84224 3.30078 7.1548 2.98822C7.46736 2.67566 7.89129 2.50006 8.33331 2.50006H9.99998C10.442 2.50006 10.8659 2.67566 11.1785 2.98822C11.4911 3.30078 11.6666 3.7247 11.6666 4.16673M11.6666 4.16673H13.3333C13.7753 4.16673 14.1993 4.34232 14.5118 4.65488C14.8244 4.96744 15 5.39137 15 5.83339V8.33339M16.6666 11.6667H8.33331M8.33331 11.6667L10.8333 9.16673M8.33331 11.6667L10.8333 14.1667"
					stroke="#6A67FF"
					strokeWidth="2"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
			</g>
		</svg>
	);
}

export default CopyToClipboardIcon;
