import { Tab } from "@headlessui/react";
import { TabsProps } from "models/tabs";
import { FC, useState } from "react";

export const HrTabs: FC<TabsProps> = ({ tabs, initialTab, onTabChange }) => {
	const [selectedTab, setSelectedTab] = useState(initialTab || 0);

	const onTabClick = (tab: number) => {
		setSelectedTab(tab);
		onTabChange(tab);
	}

	return (
		<Tab.Group>
			<Tab.List className='bg-white'>
				{tabs.map((tab, index) => (
				   tab.condition && <Tab className='py-2.5 px-8 font-medium cursor-pointer ui-selected:bg-primary-100 ui-selected:text-primary-500 ui-selected:border-primary-500 ui-selected:border-b-2 ui-not-selected:text-gray-500 focus-visible:outline-none' key={index} onClick={() => onTabClick(index)}>
						{tab.label}
					</Tab>
				))}
			</Tab.List>
			<Tab.Panels>
				{tabs.map((tab, index) => (
					 tab.condition && <Tab.Panel key={index}> {selectedTab === index && <div>{tab.content}</div>}</Tab.Panel>
				))}
			</Tab.Panels>
		</Tab.Group>
	);
};
