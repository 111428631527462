import { FilterProps } from "models/filter";
import { ContactSaveProps, ContactsResponseProps } from "pages/account-details/opportunities/opportunity-details-components/main-details-widget/general-details/contacts-and-team/contacts/contactsModel";
import { axiosInstance } from "utilis/axiosConfig";
import { mapFilters } from "utilis/helpers";

export const getAllContacts = async (filter: FilterProps | undefined): Promise<ContactsResponseProps | undefined> => {
    const filters = mapFilters(filter);
    const params = {
        ...filters,
    }
    try {
        const response = await axiosInstance.get<ContactsResponseProps>('/v1/opportunity/contact', {params});
        return response.data;
    } catch(error) {
        throw error;
    }
}

export const addNewContact = async (data: ContactSaveProps): Promise<void | undefined> => {
    const {opportunityId, contactId, ...body} = data;
    try {
        const response = await axiosInstance.post<void>(`/v1/opportunity/${opportunityId}/contact`, body);
        return response.data;
    } catch(error) {
        throw error
    }
}

export const editContact = async (data: ContactSaveProps): Promise<void | undefined> => {
    const {opportunityId, contactId, ...body} = data;
    try {
        const response = await axiosInstance.put<void>(`/v1/opportunity/${opportunityId}/contact/${contactId}`, body);
        return response.data;
    } catch(error) {
        throw error
    }
}

export const removeContact = async ({opportunityId, contactId}: {opportunityId: string, contactId: string}): Promise<void | undefined> => {
    try {
        const response = await axiosInstance.delete<void>(`/v1/opportunity/${opportunityId}/contact/${contactId}`);
        return response.data;
    } catch(error) {
        throw error;
    }
}