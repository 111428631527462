import StatusErroIcon from "assets/icons/status-error";
import StatusSuccessIcon from "assets/icons/status-success";
import { FC } from "react";
import { hasLowerCase, hasSpecialChars, hasUpperCase } from "utilis/helpers";
export const PasswordValidation: FC<{value: string}> = ({value}) => {

	return (
		<>
			<span className="text-xs font-medium text-gray-200">Your password must have:</span>
			<ul className="mt-1">
				<li className="text-xs flex items-center text-gray-200">
					{value.length > 9 ? <StatusSuccessIcon /> : <StatusErroIcon />}
					<span className="ml-1">at least 10 characters long </span>
				</li>
				<li className="text-xs flex items-center text-gray-200 mt-1">
					{hasUpperCase(value) ? <StatusSuccessIcon /> : <StatusErroIcon />}
					<span className="ml-1"> one uppercase letter</span>
				</li>
				<li className="text-xs flex items-center text-gray-200 mt-1">
					{hasLowerCase(value) ? <StatusSuccessIcon /> : <StatusErroIcon />}
					<span className="ml-1"> one lowercase letter</span>
				</li>
				<li className="text-xs flex items-center text-gray-200 mt-1">
					{hasSpecialChars(value) ? <StatusSuccessIcon /> : <StatusErroIcon />}
					<span className="ml-1"> at least one spec character</span>
				</li>
			</ul>
		</>
	);
};
