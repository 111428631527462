import { useQuery } from "@tanstack/react-query";
import { getAllMeetings } from "api/meetings";
import { FilterProps } from "models/filter";

export const useMeetings = (filters: FilterProps = {}) => {

	return useQuery({
        queryKey: ['meetings', filters],
		queryFn: () => getAllMeetings(filters),
	});
};