import { FC, useRef } from "react";
import { Form, Formik, FormikProps } from "formik";
import { Button } from "components/UI/buttons";
import { Filter } from "components/shared/filter";
import { SetURLSearchParams } from "react-router-dom";
import { HrSearchInput } from "components/forms/searchInput";
import { MagnifyingGlassIcon, ArrowPathIcon } from "@heroicons/react/24/outline";

export const PendingUsersFilter: FC<{
	results: number;
	filters: URLSearchParams;
	setFilters: SetURLSearchParams;
	setCurrentPage: React.Dispatch<React.SetStateAction<number>>;
}> = ({ filters, setFilters, results, setCurrentPage }) => {
	//create custom hook for filter params;
	const allParams = Object.fromEntries(filters.entries());
	const {userStatus, ...params} = allParams;
	const hasFilters = params && Object.keys(params).length > 0;
	const values = hasFilters ? params : { inviteeEmail: "" };
	const initialValues: {[k: string]: string} = values;
	const formikRef = useRef<FormikProps<{[k: string]: string}>>(null);

	const onSubmit = (values: {[k: string]: string}) => {
		if (!!values.inviteeEmail) {
			// setFilters(values);
			setFilters((prevParams) => {
				return new URLSearchParams({
				  ...Object.fromEntries(prevParams.entries()),
				  ...values,
				});
			  });
			setCurrentPage(1);
		} else {
			handleReset();
		}
	};

	const handleReset = () => {
		setFilters({userStatus: 'pending'});
		setCurrentPage(1);
		formikRef.current?.resetForm();
	};

	return (
		<Filter results={results} action={handleReset} hasFilters={hasFilters}>
			<Formik innerRef={formikRef} initialValues={initialValues} onSubmit={onSubmit} enableReinitialize>
				{({ resetForm }) => (
					<Form className="flex flex-wrap gap-5 mt-4">
						<div>
							<HrSearchInput name="inviteeEmail" type="text" placeholder="Enter invitee email" />
						</div>
						<div className="sm:ml-auto flex-wrap gap-4 flex-col xs:flex-row flex items-center">
							<Button
								type="button"
								key="notsubmit"
								variant="transparent"
								onClick={() => handleReset()}
								className="flex-1"
							>
								<div className="flex items-center gap-2 w-max">
									<ArrowPathIcon className="h-4 w-4 text-primary-500" />
									Clear Filter
								</div>
							</Button>
							<Button type="submit" variant="primary" className="flex-1 w-max">
								<div className="flex items-center gap-2 w-max">
									<MagnifyingGlassIcon className="h-4 w-4 text-white" />
									Filter
								</div>
							</Button>
						</div>
					</Form>
				)}
			</Formik>
		</Filter>
	);
};
