import { useMutation, useQueryClient } from "@tanstack/react-query";
import { updateFields } from "api/opportunity";

export const useEditFields = () => {
	const queryClient = useQueryClient();
	return useMutation({
		mutationFn: updateFields,
		onSuccess: () => {
			queryClient.invalidateQueries({queryKey: ['opportunity'], type: "active"});
			queryClient.invalidateQueries({ queryKey: ['opportunityById'], type: "active"});
            queryClient.invalidateQueries({ queryKey: ['opportunityScore'], type: "active"});
			queryClient.removeQueries({queryKey: ['opportunity'], type: "inactive"});

		},
	});
}