import Avatar from "components/shared/avatar";
import { Breadscrumb } from "components/shared/breadscrumb";
import { EmptyState } from "components/shared/emptyState";
import { ErrorState } from "components/shared/errorState";
import { Pager } from "components/shared/pager";
import { Spinner } from "components/shared/spinner";
import { HrTable } from "components/shared/table";
import { format } from "date-fns";
import { ColumnProps } from "models/table";
import { OpportunityFilters } from "pages/account-details/opportunities/opportunity-filter/opportunityFilters";
import { OpportunityStatus } from "pages/account-details/opportunities/opportunity-status/opportunityStatus";
import { useOpportunity } from "pages/account-details/opportunities/useOpportunity";
import { useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useUserStore } from "store/userStore";
import { checkIsAE, checkIsSDR } from "utilis/helpers";

interface DefaultFilters {
  accountId?: string;
  sdrId?: string;
  aeId?: string;
}

const Opportunities = () => {
  const navigate = useNavigate();
  const { user } = useUserStore();
  const isSdr = checkIsSDR();
  const isAe = checkIsAE();

  const [searchParams, setSearchParams] = useSearchParams();
  const params = Object.fromEntries(searchParams.entries());
  const [currentPage, setCurrentPage] = useState(1);
  const hasFilters = params && Object.keys(params).length > 0;

  const handleRetry = () => {
    setSearchParams({});
    setCurrentPage(1);
    refetch();
  };

  let defaultFilters: DefaultFilters = {};
  if (isSdr) {
    defaultFilters = {
      ...defaultFilters,
      sdrId: user?.id!,
    };
  }
  if (isAe) {
    defaultFilters = {
      ...defaultFilters,
      aeId: user?.id!,
    };
  }

  const {
    data: opportunitiesResponse,
    isLoading,
    error,
    refetch,
  } = useOpportunity(
    { ...defaultFilters, ...params },
    { limit: 10, currentPage }
  );
  const opportunities = opportunitiesResponse?.rows.length
    ? opportunitiesResponse.rows
    : [];

  const columns: ColumnProps<any, keyof any>[] = [
    {
      key: "name",
      title: "Opportunity Name",
      render: (_, { name }) => (
        <div className="flex items-center gap-2">
          <Avatar userName={name} />
          {name}
        </div>
      ),
    },
    {
      key: "meetings",
      title: "Last meeting",
      render: (_, { meetings }) => (
        <div>
          {meetings.length
            ? meetings[0].createdAt
              ? format(new Date(meetings[0].createdAt), "MMMM d, yyyy h:mm a")
              : "-"
            : "-"}
        </div>
      ),
    },
    {
      key: "status",
      title: "",
      render: (_, { status }) => (
        <div className="flex justify-around">
          {" "}
          <OpportunityStatus type={status} />{" "}
        </div>
      ),
    },
  ];

  const handleNavigate = (opp: any) => {
    navigate(`${opp.accountId}/${opp.id}`);
  };

  const handlePage = (p: number) => {
    setCurrentPage(p);
  };

  if (isLoading)
    return (
      <div>
        <Spinner />
      </div>
    );

  if (error)
    return (
      <div>
        <ErrorState action={() => handleRetry()} />
      </div>
    );

  if (opportunities && opportunities.length < 1 && !hasFilters)
    return (
      <div>
        <EmptyState title="opportunities" />
      </div>
    );

  return (
    <>
      <Breadscrumb className="h-[88px]" title="Opportunities" />
      <div className="flex flex-col gap-2 bg-white p-4 my-3">
        <div className="">
          <OpportunityFilters
            results={opportunitiesResponse?.count!}
            filters={searchParams}
            setFilters={setSearchParams}
            setCurrentPage={setCurrentPage}
          />
        </div>
        <div className="pt-2">
          <HrTable
            data={opportunities}
            columns={columns}
            handleNavigate={handleNavigate}
          />
          {opportunitiesResponse?.count! > 10 && (
            <Pager
              current={currentPage}
              pageSize={10}
              total={opportunitiesResponse?.count}
              onChange={(p) => handlePage(p)}
            />
          )}
        </div>
      </div>
    </>
  );
};

export default Opportunities;
